import {
    InformationCircleIcon
} from "@heroicons/react/24/outline"
import {
    FlagIcon,
    NewspaperIcon,
    CalendarIcon,
    Cog6ToothIcon,
    XMarkIcon,
    MicrophoneIcon
} from "@heroicons/react/24/solid"
import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import YachtImg from "styles/images/yacht_b.png";

export default function AdminMenuLeft() {
    const location = useLocation();
    const [path, setPath] = useState('');
    const selected = <span className="absolute mt-1.5 mb-1.5 inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-primary " aria-hidden="true"></span>

    useEffect(() => {
        if(location.pathname)
            setPath(location.pathname);
    }, [location.pathname])

    const close = (e) => {
        document.getElementById('left-sidebar-drawer').click()
    }

    return (
      <>
          <div className="drawer-side z-10">
              <label htmlFor="left-sidebar-drawer" className="drawer-overlay"></label>
              <ul className="menu lg:menu-lg bg-white w-64 text-gray-700 space-y-2 h-full">
                  <button className="btn btn-ghost btn-circle z-40 top-0 right-0 mt-2 mr-2 absolute xl:hidden"
                          onClick={() => close()}>
                      <XMarkIcon className="inline-block w-4 h-4"/>
                  </button>
                  <li className="menu-title"><a href="/" className="menu-logo-link">
                      <img src={YachtImg} className="inline-block mr-2 mb-2 App-logo" />
                      <span className="normal-case text-indigo-700 text-2xl">승선관리시스템</span>
                  </a></li>
                  <li><a href="/manage/boarding/sail">
                      <CalendarIcon className="h-5 w-5" strokeWidth="2"/>
                      <span className="">승선캘린더관리</span>
                      {path === "/manage/boarding/sail" || path.indexOf("/manage/boarding/reserve") > -1 ? selected : null}
                  </a></li>
                  <li><a href="/manage/boarding/pass">
                      <NewspaperIcon className="h-5 w-5" strokeWidth="2"/>
                      <span className="">승선신고서관리</span>
                      {path === "/manage/boarding/pass" ? selected : null}
                  </a></li>
                  <li><a href="/manage/boarding/port">
                      <FlagIcon className="h-5 w-5" strokeWidth="2"/>
                      <span className="">출입항기록관리</span>
                      {path === "/manage/boarding/port" ? selected : null}
                  </a></li>
                  <li>
                      <details open>
                          <summary>
                              <Cog6ToothIcon className="h-5 w-5" strokeWidth="2"/>
                              <span className="">기본설정관리</span>
                          </summary>
                          <ul className="md:!menu-md">
                              <li><a href="/manage/preference/company/info">
                                  <InformationCircleIcon className="h-5 w-5" strokeWidth="1"/>
                                  업체정보
                                  {path === "/manage/preference/company/info" ? selected : null}
                              </a></li>
                              {/*<li><a href="/manage/preference/yacht">*/}
                              {/*    <InformationCircleIcon className="h-5 w-5" strokeWidth="1"/>*/}
                              {/*    요트정보*/}
                              {/*    {path === "/manage/preference/yacht" ? selected : null}*/}
                              {/*</a></li>*/}
                              <li><a href="/manage/preference/cruise">
                                  <InformationCircleIcon className="h-5 w-5" strokeWidth="1"/>
                                  크루즈정보
                                  {path === "/manage/preference/yacht" ? selected : null}
                              </a></li>
                              {/*<li><a href="/manage/preference/sailor">*/}
                              {/*    <InformationCircleIcon className="h-5 w-5" strokeWidth="1"/>*/}
                              {/*    항해사정보*/}
                              {/*    {path === "/manage/preference/sailor" ? selected : null}*/}
                              {/*</a></li>*/}
                          </ul>
                      </details>
                  </li>
                  <li><a href="/manage/record/list">
                      <MicrophoneIcon className="h-5 w-5" strokeWidth="2"/>
                      <span className="">녹음파일관리</span>
                      {path === "/manage/record/list" ? selected : null}
                  </a></li>
              </ul>
          </div>
      </>
    )
}
