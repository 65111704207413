import { Outlet } from 'react-router-dom';
import AdminHeader from "components/AdminHeader"
import AdminFooter from "components/AdminFooter";
import AdminMenuLeft from "components/AdminMenuLeft";

export default function LayoutAdmin() {
    return (
        <>
            <div className="drawer xl:drawer-open">
                <input id="left-sidebar-drawer" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content flex flex-col">
                    <AdminHeader />
                    <Outlet />
                    <AdminFooter />
                </div>
                <AdminMenuLeft />
            </div>
            {/*<div className="w-full flex flex-row">*/}
            {/*    <div className="flex-none">*/}
            {/*        <AdminMenuLeft />*/}
            {/*    </div>*/}
            {/*    <div className="flex-1">*/}
            {/*        <AdminHeader />*/}
            {/*        <Outlet />*/}
            {/*        <AdminFooter />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
}
