import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import Api from "utils/Api";
import {loginUser} from "store/userSlice";
import * as Auth from "components/Auth";
import * as G from "utils/utils";


function UserLoginForm() {
    const dispatch = useDispatch();
    const user = Auth.AuthCheck();
    const navigate = useNavigate();
    const [isRemember, setIsRemember] = useState(false); //#아이디 저장 체크박스 체크 유무

    const onSubmit = async (data) => {
        if(isRemember === true)
            localStorage.setItem('rememberUserId', data.id);
        else
            localStorage.removeItem('rememberUserId');

        ApiLogin(data);
        await G.sleep(1000);
    };

    const {
        register,
        setValue,
        handleSubmit,
        formState: { isSubmitting, errors }
    } = useForm();

    const goManage = async () => {
        navigate('/manage/boarding/sail');
    }

    const handleRememberChange = (e) => {
        setIsRemember(e.target.checked);
    };

    useEffect(() => {
        const _userId = localStorage.getItem('rememberUserId');
        if (_userId) {
            setValue('id', _userId);
            setIsRemember(true);
        }
    }, []);

    const ApiLogin = (body) => {
        const url = "login/login_check";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                let userInfo = response.data;
                dispatch(loginUser(userInfo));
                console.log("로그인성공");
                goManage();
            }
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control w-full">
                <label htmlFor="member_id" className="block text-sm font-medium leading-6 text-gray-900">
                    아이디
                </label>
                <div className="mt-2">
                    <input
                        type="text"
                        placeholder="회원아이디"
                        name="id"
                        {...register("id", {
                            required: "회원아이디는 필수 입력입니다.",
                            minLength: {
                                value: 5,
                                message: "5자리 이상 아이디를 사용하세요."
                            }
                        })}
                        className={`input input-bordered w-full ${G.errorStyle(errors.id)}`}
                    />
                    {G.errorDesc(errors.id)}
                </div>
            </div>
            <div className="form-control w-full mt-6">
                <div className="flex justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    비밀번호
                </label>
                <div className="tooltip tooltip-warning" data-tip="등록된 전화번호로 관리자에게 연락하셔서 비밀번호 초기화를 요청해주세요.">
                    <span className="text-xs text-purple-700">비밀번호를 잊으셨나요?</span>
                </div>
                </div>
                <div className="mt-2">
                    <input
                        name="password"
                        placeholder="**********"
                        type="password"
                        {...register("password", {
                            required: "비밀번호는 필수 입력입니다.",
                            minLength: {
                                value: 6,
                                message: "6자리 이상 비밀번호를 사용하세요."
                            }
                        })}
                        className={`input input-bordered w-full ${G.errorStyle(errors.password)}`}
                    />
                    {G.errorDesc(errors.password)}
                </div>
            </div>
            <div className="form-control w-full mt-1">
                <label className="label cursor-pointer justify-start">
                    <input type="checkbox"
                           id="saveId"
                           name="saveId"
                           onChange={(e) => {
                               handleRememberChange(e);
                           }}
                           checked={isRemember}
                           className="checkbox " />
                    <span className="label-text mx-2">ID 저장하기</span>
                </label>
            </div>
            <div className="mt-6">
                <button type="submit" disabled={isSubmitting}
                        className="btn btn-primary w-full">
                    로그인
                </button>
            </div>
            <p className="mt-3 text-purple-700 text-sm italic text-point">
                신규 가입을 원하시면 관리자에게 문의주세요.
            </p>
        </form>
    );
}

export default UserLoginForm;
