import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Datepicker from "react-tailwindcss-datepicker";
import {
    ChatBubbleBottomCenterTextIcon,
    DocumentChartBarIcon, PrinterIcon,
} from "@heroicons/react/24/outline"

import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import SmsForm from "components/SmsForm";
import PassReportForm from "components/PassReportForm";
import {clearSail, setSail} from "store/sailSlice";
import {toast} from "store/toastSlice";
import dayjs from "dayjs";
import {LiaClock, LiaMinusSquareSolid, LiaUserFriendsSolid} from "react-icons/lia";
import {TbShip} from "react-icons/tb";
import {GrUserPolice} from "react-icons/gr";

export default function Pass() {
    const user = Auth.AuthCheck();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sailInfo = useSelector(state => state.Sail);

    const today = dayjs();
    const [dateValue, setDateValue] = useState({
        startDate: today.add(-30, "d").format('YYYY/MM/DD'),
        endDate: today.format('YYYY/MM/DD')
    });
    const handleDatePickerValueChange = (newValue) => {
        setDateValue(newValue);
    }
    const [yachtList, setYachtList] = useState([]);
    const [yachtNo, setYachtNo] = useState('');
    const handleChangeYacht = (e) => {
        setYachtNo(e.target.value);
    };

    const [mateList, setMateList] = useState([]);
    const [mateNo, setMateNo] = useState('');
    const handleChangeMate = (e) => {
        setMateNo(e.target.value);
    };
    const [boardingList, setBoardingList] = useState([]);
    const ApiListPass = (body) => {
        const url = "boarding/boarding_pass_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                const dataCount = response.data.info.count;
                const dataList = response.data.list;
                setBoardingList(dataList);
            }
        );
    };
    const handleSearchBoarding = () => {
        const body = {
            yacht_no: yachtNo,
            mate_no: mateNo,
            start_at: dateValue.startDate,
            end_at: dateValue.endDate,
        }
        ApiListPass(body);
    };
    const ApiListYacht = (body) => {
        const url = "setting/setting_cruises_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                const yachtCount = response.data.info.count;
                const yachtList = response.data.list;
                // console.log("yachtList",yachtList);
                setYachtList(yachtList);
            }
        );
    };

    const ApiListMate = (body) => {
        const url = "setting/setting_mate_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                let mateList = response.data.list;
                // console.log("MateList",mateList);
                setMateList(mateList);
            }
        );
    };

    useEffect(() => {
        dispatch(clearSail(sailInfo))
        ApiListMate();
        ApiListYacht();
        // ApiListPass();
    }, []);

    const getSailInfo = (no) => {
        const selected = boardingList[no];
        return selected;
    }

    const handleSmsFinish = () => {
        setSmsUserList([]);
        handleSmsClose();
    }
    const [smsCode, setSmsCode] = useState(null);
    const [drawerReport, setDrawerReport] = useState(false);
    const [drawerSms, setDrawerSms] = useState(false);
    const [smsUserList, setSmsUserList] = useState([]);
    const handleChangeReport = (e) => {
        console.log('e.target.checked',e.target.checked);
    }
    const handleChangeSms = (e) => {
        console.log('e.target.checked',e.target.checked);
    }
    const handleReportClose = () => {
        setDrawerReport(false);
    }
    const handleSmsClose = () => {
        setDrawerSms(false);
    }
    const handleSendSmsSailAll = (no) => {
        const selected =  getSailInfo(no);
        dispatch(setSail(selected));
        // ApiListReserveUserSmsUser({sailing_no: selected.sailing_no, reserve_no: 0});
        ApiListOnBoardUserSmsUser({sailing_no: selected.sailing_no, reserve_no: 0});
    }

    const handleReport = (no) => {
        const selected =  getSailInfo(no);
        dispatch(setSail(selected));
        setDrawerReport(true);
    }
    const ApiListOnBoardUserSmsUser = (body) => {
        const url = "boarding/boarding_pass_user_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                if(response.data.user_list) {
                    const dataList = response.data.user_list.filter(user => user.gubun === 'user');
                    if (dataList.length <= 0) {
                        dispatch(toast({message: "송신 대상자가 없습니다.", kind: "error"}));
                        return;
                    }
                    setSmsUserList(dataList);
                    setDrawerSms(true);
                }
            }
        );
    }

    const ApiListReserveUserSmsUser = (body) => {
        const url = "boarding/boarding_reserve_user_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                // const dataCount = response.data.info.count;
                const dataList = response.data.list;
                if(dataList.length <= 0) {
                    // alert('송신 대상자가 없습니다.');
                    dispatch(toast({message : "송신 대상자가 없습니다.", kind : "error"}));
                    return;
                }
                setSmsUserList(dataList);
                setDrawerSms(true);
            }
        );
    }

    const openWindow = () => {
        const url = `/manage/boarding/print/pass/${sailInfo.sailing_no}`;
        window.open(url, "_blank", "width=620, height=700, noopener, noreferrer");
    }

    return (
        <>
            <main className="content">
                <div className="paper">
                    <div className="sub-title">
                        <span className="text-gradient">승선신고서관리</span>
                    </div>
                </div>

                <div className="paper">
                    <div className="form-row">
                        <div className="md:basis-1/2">
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">요트선택</span>
                                </label>
                                <select
                                    id="yacht_no"
                                    value={yachtNo || ''}
                                    onChange={handleChangeYacht}
                                    className="select select-bordered w-full">
                                    <option value="">전체</option>
                                    {yachtList.map(({cruise_no, cruise_name}) =>
                                        <option key={cruise_no} value={cruise_no}>
                                            {cruise_name}
                                        </option>
                                    )}
                                </select>
                            </div>
                        </div>
                        {/*<div className="md:basis-1/2">*/}
                        {/*    <div className="form-control w-full">*/}
                        {/*        <label className="label">*/}
                        {/*            <span className="label-text">항해사</span>*/}
                        {/*        </label>*/}
                        {/*        <select*/}
                        {/*            id="mate_no"*/}
                        {/*            value={mateNo || ''}*/}
                        {/*            onChange={handleChangeMate}*/}
                        {/*            className="select select-bordered w-full">*/}
                        {/*            <option value="">전체</option>*/}
                        {/*            {mateList.map(({mate_no, mate_name}) =>*/}
                        {/*                <option key={mate_no} value={mate_no}>*/}
                        {/*                    {mate_name}*/}
                        {/*                </option>*/}
                        {/*            )}*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="form-row">
                        <div className="md:basis-full">
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">조회기간</span>
                                </label>
                                <div className="form-row">
                                    <div className="md:basis-4/5">
                                        <Datepicker
                                            i18n={"ko"}
                                            primaryColor={"purple"}
                                            inputClassName="input input-bordered w-full"
                                            containerClassName="relative"
                                            toggleClassName="absolute rounded-r-lg right-0 h-full px-3"
                                            value={dateValue}
                                            displayFormat={"YYYY/MM/DD"}
                                            onChange={handleDatePickerValueChange}
                                            showShortcuts={false}
                                        />
                                    </div>
                                    <div className="md:basis-1/5 right-button">
                                        <button type="button"
                                                onClick={handleSearchBoarding}
                                                className="btn btn-outline w-full">
                                            검색
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="paper">
                    <div className="table-wrapper">
                        <div className="div-table w-full">
                            <div className="div-table-row div-table-header">
                                <div className="div-table-cell">항해회차ID</div>
                                <div className="div-table-cell">요트명</div>
                                {/*<div className="div-table-cell">항해사</div>*/}
                                <div className="div-table-cell">출항일시</div>
                                <div className="div-table-cell">입항일시</div>
                                <div className="div-table-cell">승객/승무원수</div>
                                <div className="div-table-cell">기능</div>
                            </div>
                            {boardingList.map(({
                                                   sailing_no,sailing_id,cruise_name,yacht_no,mate_name,mate_no,
                                                   start_at, sailing_start_at,sailing_finish_at,boarding_user_count,mate_count,created_at
                                               }, k) =>
                                <div key={k} className="div-table-row">
                                    <div className="div-table-cell">
                                        <LiaMinusSquareSolid className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                        {sailing_id}
                                    </div>
                                    <div className="div-table-cell">
                                        <TbShip className="inline-block h-5 w-5 mobile-show" strokeWidth="1" />
                                        {G.getStyleYacht(yacht_no,cruise_name,yachtList)}
                                        <GrUserPolice className="inline-block h-4 w-4 ml-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                        <span className="mobile-show ml-2">{mate_name} 항해사</span>
                                    </div>
                                    {/*<div className="div-table-cell cell-mobile-hide">{mate_name}</div>*/}
                                    <div className="div-table-cell">
                                        <LiaClock className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                        {start_at} <span className="mobile-show">출항</span>
                                    </div>
                                    <div className="div-table-cell">
                                        <LiaClock className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                        {sailing_finish_at} <span className="mobile-show">입항</span>
                                    </div>
                                    <div className="div-table-cell">
                                        <LiaUserFriendsSolid className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                        <span className="mobile-show">승객</span> {boarding_user_count} / <span className="mobile-show">승무원</span> {mate_count}
                                    </div>
                                    <div className="div-table-cell text-center">
                                        <button type="button" className="btn btn-primary btn-sm w-2/3 mx-auto mobile-show "
                                                onClick={() => handleSendSmsSailAll(k)}>
                                            문자발송
                                        </button>
                                        <div className="join mobile-hide">
                                            <button type="button"
                                                    className="btn btn-sm btn-circle btn-ghost p-0"
                                                    onClick={() => handleSendSmsSailAll(k)}>
                                                <ChatBubbleBottomCenterTextIcon className="w-5 h-5 mx-1" />
                                            </button>
                                            <button type="button"
                                                    className="btn btn-sm btn-circle btn-ghost p-0"
                                                    onClick={() => handleReport(k)}>
                                                <DocumentChartBarIcon className="w-5 h-5 mx-1" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {boardingList.length < 1 ?
                            <div className="div-no-record w-full">
                                검색된 정보가 없습니다.
                            </div>
                            : null
                        }
                    </div>
                </div>

                <div className="drawer drawer-end">
                    <input id="drawer-report" type="checkbox" onChange={handleChangeReport} checked={drawerReport} className="drawer-toggle" />
                    <div className="drawer-side z-20">
                        <label onClick={handleReportClose} className="drawer-overlay"></label>
                        <div className="w-11/12 md:w-1/2 min-h-full py-0 px-1 rounded-lg shadow-md bg-base-100">
                            <div className="paper-modal overscroll-y-auto">
                                <PassReportForm sail={sailInfo} openWindow={openWindow} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="drawer drawer-end">
                    <input id="drawer-sms" type="checkbox" onChange={handleChangeSms} checked={drawerSms} className="drawer-toggle" />
                    <div className="drawer-side z-20">
                        <label onClick={handleSmsClose} className="drawer-overlay"></label>
                        <div className="w-full md:w-1/3 min-h-full py-0 px-1 rounded-lg shadow-md bg-base-100">
                            <div className="paper-modal overscroll-y-auto">
                                <SmsForm SMSCODE={G.PASS_GROUP} show={drawerSms}
                                         users={smsUserList} onFinish={handleSmsFinish} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
