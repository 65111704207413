import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useForm,useFieldArray} from "react-hook-form";
import DaumPostcode from "react-daum-postcode";

import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import {toast} from "store/toastSlice";

const defaultValues = {
    company_name: '',
    biz_no: '',
    represent_name: '',
    contact: '',
    post_code: '',
    address1: '',
    address2: '',
    manage_no: '',
    sms: [{receive_name: '', phone: ''}],
    company_id: '',
    created_at: '',
    kakao_business: '',
    updated_at: '',
}

export default function CompanyForm() {
    const user = Auth.AuthCheck();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [managerList, setManagerList] = useState([]);
    const [selectedManage, setSelectedManage] = useState({
        created_at: '',
        email: '',
        manage_id: '',
        manage_name: '',
        manage_no: '',
        phone: '',
    });
    const [managerNo, setManagerNo] = useState();

    const onSubmit = async (data) => {
        await G.sleep(1000);
        ApiModifyCompany(data);
        // console.log('data',data);
        await G.sleep(1000);
    };

    const [isOpenPost, setIsOpenPost] = useState(false);
    const onCompletePost = (data) => {
        reset(formValues => ({
            ...formValues,
            post_code: data.zonecode,
            address1: data.address,
        }))
        setIsOpenPost(false);
    };

    const handleChangeManager = (e) => {
        setManagerNo(e.target.value);
    };

    const updateManagerInfo = (no) => {
        if(no) {
            const selected = managerList.find(manage => manage.manage_no === no);
            if (selected)
                setSelectedManage(selected);
        }
    }

    const {
        register,
        reset,
        control,
        handleSubmit,
        formState: { isSubmitting, errors }
    } = useForm((defaultValues));
    const { fields } = useFieldArray({
        control,
        name: "sms"
    });

    const handleFormChange = (e) => {
        setSelectedManage({
            ...selectedManage,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        ApiReadCompany();
    }, []);

    useEffect(() => {
        if(managerNo) {
            updateManagerInfo(managerNo);
            // console.log('managerNo',managerNo);
        }
    }, [managerNo]);

    const ApiReadCompany = (body) => {
        const url = "setting/setting_company_info";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                //console.log('result : ', result);
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }

                // manager_name,manager_id,manager_no,phone,email
                let companyManager = response.data.managers;
                setManagerList(companyManager);

                // created_at,no,phone,receive_name
                let managerSms = response.data.sms;
                if(managerSms.length <= 0) {
                    managerSms = [{receive_name: '', phone: ''},{receive_name: '', phone: ''}]
                }

                let companyInfo = response.data.info;
                const {company_name,biz_no,represent_name,contact,post_code,address1,address2,company_no,created_at,kakao_business,updated_at,manage_no} = companyInfo;
                reset({
                    company_name: company_name,
                    biz_no: biz_no,
                    represent_name: represent_name,
                    contact: contact,
                    post_code: post_code,
                    address1: address1,
                    address2: address2,
                    manage_no: manage_no,
                    sms: managerSms,
                    company_id: company_no,
                    created_at: created_at,
                    kakao_business: kakao_business,
                    updated_at: updated_at,
                });
                setManagerNo(manage_no);
            }
        );
    };

    const ApiModifyCompany = (body) => {
        const url = "setting/setting_company_update";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                //console.log('result : ', result);
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                let companyInfo = response.data;
                // dispatch(loginUser(userInfo));
                console.log("회사정보업데이트성공",companyInfo);
                dispatch(toast({message : "저장되었습니다", kind : "success"}));
            }
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="paper">
                <h4 className="text-lg">
                    회사정보
                    <div className="divider my-1"></div>
                </h4>

                <div className="form-row">
                    <div className="mobile-row md:basis-1/4 text-center">
                            <label className="label">
                                <span className="label-text">회사명</span>
                            </label>
                    </div>
                    <div className="mobile-row md:basis-full">
                            <input type="text"
                                   placeholder="회사명"
                                   name="company_name"
                                   readOnly={true}
                                   {...register("company_name", {})}
                                   className={`input input-bordered input-readonly w-full ${G.errorStyle(errors.company_name)}`}
                            />
                            {G.errorDesc(errors.company_name)}

                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/4 text-center">
                            <label className="label">
                                <span className="label-text">사업자번호</span>
                            </label>
                    </div>
                    <div className="mobile-row md:basis-full">
                            <input type="text"
                                   placeholder="사업자번호"
                                   name="biz_no"
                                   readOnly={true}
                                   {...register("biz_no", {})}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.biz_no)}`}
                            />
                            {G.errorDesc(errors.biz_no)}
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/4 text-center">
                            <label className="label">
                                <span className="label-text">대표자명</span>
                            </label>
                    </div>
                    <div className="mobile-row md:basis-full">
                            <input type="text"
                                   placeholder="대표자명"
                                   name="represent_name"
                                   {...register("represent_name", {
                                       required: "대표자명은 필수 입력입니다.",
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.represent_name)}`}
                            />
                            {G.errorDesc(errors.represent_name)}

                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/4 text-center">
                            <label className="label">
                                <span className="label-text">회사전화번호</span>
                            </label>
                    </div>
                    <div className="mobile-row md:basis-full">
                            <input type="text"
                                   placeholder="회사전화번호"
                                   name="contact"
                                   {...register("contact", {
                                       required: "회사전화번호는 필수 입력입니다.",
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.contact)}`}
                            />
                            {G.errorDesc(errors.contact)}
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/4 text-center">
                            <label className="label">
                                <span className="label-text">회사주소</span>
                            </label>
                    </div>
                    <div className="mobile-row md:basis-full">
                            <div className="join">
                                <input type="text"
                                       placeholder="우편번호"
                                       id="post_code"
                                       readOnly={true}
                                       {...register("post_code", {
                                           required: "우편번호는 필수 입력입니다.",
                                       })}
                                       className="input input-bordered join-item"
                                />
                                <button type="button" className="btn btn-outline join-item"
                                        onClick={()=>setIsOpenPost(!isOpenPost)}>우편번호찾기</button>
                            </div>
                            {G.errorDesc(errors.post_code)}
                        </div>
                        {isOpenPost  ? (
                            <div className="relative z-30">
                                <div className="absolute w-full p-1 bg-base-300">
                                    <DaumPostcode autoClose={false} onComplete={onCompletePost} style={{width: '100%', minHeight: '300px'}} />
                                </div>
                            </div>
                        ) : null}
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/4 text-center">
                    </div>
                    <div className="mobile-row md:basis-full">
                        <div className="form-control w-full">
                            <input type="text"
                                   placeholder="우편번호찾기를 통해 입력해주세요."
                                   id="address1"
                                   readOnly={true}
                                   {...register("address1", {
                                       required: "회사주소는 필수 입력입니다.",
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.address1)}`}
                            />
                            {G.errorDesc(errors.address1)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/4 text-center">
                    </div>
                    <div className="mobile-row md:basis-full">
                            <input type="text"
                                   placeholder="세부주소를 입력해주세요."
                                   id="address2"
                                   {...register("address2", {
                                       required: "세부주소는 필수 입력입니다.",
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.address2)}`}
                            />
                            {G.errorDesc(errors.address2)}
                    </div>
                </div>
                <p className="my-2 text-purple-700 text-sm italic text-point">회사명, 사업자번호 변경은 관리자에게 문의해주세요.</p>
            </div>
            <div className="paper">
                <h4 className="text-lg">
                    관리자정보
                    <div className="divider my-1"></div>
                </h4>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/4 text-center">
                        <label className="label">
                            <span className="label-text">담당자성명/아이디</span>
                        </label>
                    </div>
                    <div className="mobile-row md:basis-full">
                        <select
                            name="manage_no"
                            {...register("manage_no", {
                                required: "담당자는 필수 입력입니다.",
                            })}
                            onChange={handleChangeManager}
                            className={`select select-bordered w-full  ${G.errorStyle(errors.manage_no)}`}>
                            <option value="">매니저선택</option>
                            {managerList.map(({manage_no, manage_id, manage_name}) =>
                                <option key={manage_no} value={manage_no}>
                                    {manage_name} {' '} {manage_id}
                                </option>
                            )}
                        </select>
                        {G.errorDesc(errors.manage_no)}
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/4 text-center">
                            <label className="label">
                                <span className="label-text">담당자휴대폰번호</span>
                            </label>
                    </div>
                    <div className="mobile-row md:basis-full">
                    <input type="text"
                                   placeholder="담당자휴대폰번호"
                                   name="phone"
                                   maxLength="11"
                                   onChange={handleFormChange}
                                   value={selectedManage.phone || ''}
                                   className="input input-bordered w-full"
                            />
                    </div>
                </div>
                <div className="form-row">
                        <div className="mobile-row md:basis-1/4 text-center">
                            <label className="label">
                                <span className="label-text">담당자이메일주소</span>
                            </label>
                        </div>
                        <div className="mobile-row md:basis-full">
                            <input type="text"
                                   placeholder="담당자이메일주소"
                                   name="email"
                                   onChange={handleFormChange}
                                   value={selectedManage.email || ''}
                                   className="input input-bordered w-full"
                            />
                        </div>
                </div>
                <p className="my-2 text-purple-700 text-sm italic text-point">담당자는 승선관리시스템 회사 관리자가 되며, 담당자 아이디/성명을 선택하시면 정보가 노출됩니다.</p>
            </div>
            {/*<div className="paper">*/}
            {/*    <h4 className="text-lg">*/}
            {/*        문자 수신자 정보*/}
            {/*        <div className="divider my-1"></div>*/}
            {/*    </h4>*/}
            {/*    {fields.map(({ no, receive_name, phone }, k) => (*/}
            {/*    <div className="form-row" key={k}>*/}
            {/*        <div className="mobile-row md:basis-1/2">*/}
            {/*            <div className="form-control w-full">*/}
            {/*                <label className="label">*/}
            {/*                    <span className="label-text">{k+1}번 수신자명</span>*/}
            {/*                </label>*/}
            {/*                <input type="text"*/}
            {/*                       placeholder={`${k + 1}번 수신자명`}*/}
            {/*                       name={`sms[${k}].receive_name`}*/}
            {/*                       control={control}*/}
            {/*                       defaultValue={receive_name}*/}
            {/*                       {...register(`sms[${k}].receive_name`)}*/}
            {/*                       className={`input input-bordered w-full ${G.errorStyle(errors.sms?.[k]?.['receive_name']?.['message'])}`}*/}
            {/*                />*/}
            {/*                {G.errorDesc(errors.sms?.[k]?.['receive_name']?.['message'])}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="mobile-row md:basis-1/2">*/}
            {/*            <div className="form-control w-full">*/}
            {/*                <label className="label">*/}
            {/*                    <span className="label-text">{k+1}번 휴대폰번호</span>*/}
            {/*                </label>*/}
            {/*                <input type="number"*/}
            {/*                       placeholder={`${k + 1}번 휴대폰번호 (숫자만 입력해주세요.)`}*/}
            {/*                       name={`sms[${k}].phone`}*/}
            {/*                       control={control}*/}
            {/*                       defaultValue={phone}*/}
            {/*                       {...register(`sms[${k}].phone`,{*/}
            {/*                           validate: (value) => {*/}
            {/*                               if(value) {*/}
            {/*                                   let regExp = /01[016789][0-9]{3,4}[0-9]{4}/*/}
            {/*                                   if (regExp.test(value) === false) {*/}
            {/*                                       return "휴대폰번호 형식에 맞지 않습니다.(숫자만 입력해주세요.)";*/}
            {/*                                   }*/}
            {/*                               }*/}
            {/*                           },*/}
            {/*                           maxLength: {*/}
            {/*                               value: 11,*/}
            {/*                               message: "숫자 최대 11자 입니다."*/}
            {/*                           },*/}
            {/*                       })}*/}
            {/*                       className={`input input-bordered w-full ${G.errorStyle(errors.sms?.[k]?.['phone'])}`}*/}
            {/*                />*/}
            {/*                {G.errorDesc(errors.sms?.[k]?.['phone'])}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    ))}*/}
            {/*</div>*/}

            <div className="paper">
                <h4 className="text-lg">
                    부가정보
                    <div className="divider my-1"></div>
                </h4>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">회사아이디</span>
                            </label>
                            <input type="text"
                                   placeholder="회사아이디"
                                   name="company_id"
                                   readOnly={true}
                                   {...register("company_id", {
                                       required: "회사아이디는 필수 입력입니다.",
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.company_id)}`}
                            />
                            {G.errorDesc(errors.company_id)}
                        </div>
                    </div>
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">가입일</span>
                            </label>
                            <input type="text"
                                   placeholder="가입일"
                                   name="created_at"
                                   readOnly={true}
                                   {...register("created_at")}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.created_at)}`}
                            />
                            {G.errorDesc(errors.created_at)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">카카오톡비지니스계정</span>
                            </label>
                            <input type="text"
                                   placeholder="카카오톡비지니스계정"
                                   name="kakao_business"
                                   {...register("kakao_business")}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.kakao_business)}`}
                            />
                            {G.errorDesc(errors.kakao_business)}
                        </div>
                    </div>
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">최종업데이트일</span>
                            </label>
                            <input type="text"
                                   placeholder="최종업데이트일"
                                   name="updated_at"
                                   readOnly={true}
                                   {...register("updated_at")}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.updated_at)}`}
                            />
                            {G.errorDesc(errors.updated_at)}
                        </div>
                    </div>
                </div>
                <p className="my-2 text-purple-700 text-sm italic text-point">회사아이디, 가입일, 최종업데이트일은 자동 저장되며, 수정할 수 없습니다.</p>
            </div>
            <div className="mb-6 text-center px-6 md:px-1">
                <button type="submit" disabled={isSubmitting}
                        className="btn btn-primary w-full sm:max-w-sm">
                    정보수정
                </button>
            </div>
        </form>
    )
}
