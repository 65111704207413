import RoutesSetup from "./routes/RoutesSetup";

function App() {
  return (
      <div className="App">
        <RoutesSetup />
      </div>
  );
}

export default App;
