import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import jsPdf from "jspdf";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";
import dayjs from "dayjs";
import {ArrowDownTrayIcon, PrinterIcon} from "@heroicons/react/24/outline";

import Api from "utils/Api";
import * as G from "utils/utils";
import 'styles/print.scss';

export default function PortPrint() {

    const { yachtNo } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const ref = useRef();

    const startDate = searchParams.get('start_at');
    const endDate = searchParams.get('end_at');

    const navigate = useNavigate();
    const _token = localStorage.getItem('accessToken');

    const [yachtInfo, setYachtInfo] = useState({
        yacht_name: '', yacht_weight: ''
    });
    const [userCount, setUserCount] = useState('');
    const [mateCount, setMateCount] = useState('');
    const [boardingList, setBoardingList] = useState([]);
    const ApiListPort = (body) => {
        const url = "boarding/boarding_port_entry_list";
        Api(body, url, _token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                const dataCount = response.data.info.count;
                const dataList = response.data.list;
                setBoardingList(dataList);
                setYachtInfo(response.data.yacht_info);

                setUserCount(dataList.map(item => item.boarding_user_count).reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0));
                setMateCount(dataList.map(item => item.mate_count).reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0));
            }
        );
    };

    const printPDF = () => {
        const domElement = document.getElementById("print-area-port");
        html2canvas(domElement
            , (
                {
                    useCORS: true,
                    scrollY: 0,
                    width: 595,
                    scale: 2.5,
                    dpi: 300,
                    letterRendering: true,
                    allowTaint: false,
                }
            )
        ).then(canvas => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPdf("p", "mm", "a4");

            const imageWidth = pdf.internal.pageSize.getWidth(); //210;
            const pageHeight = pdf.internal.pageSize.getHeight(); //295;
            let imageHeight = canvas.height * imageWidth / canvas.width;
            // console.log('pageHeight',pageHeight);
            // console.log('imageHeight',imageHeight);
            let heightLeft = imageHeight;
            let position = 0;
            pdf.addImage(imgData, 'PNG', 0, position, imageWidth, imageHeight);
            heightLeft -= pageHeight;
            while (heightLeft >= 1) {
                position = heightLeft - imageHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imageWidth, imageHeight);
                heightLeft -= pageHeight;
            }
            // const width = pdf.internal.pageSize.getWidth();
            // const height = pdf.internal.pageSize.getHeight();
            // pdf.addImage(imgData, "JPEG", 1, 1, width, height);

            const fdate = dayjs().format("YYYY-MM-DD");
            pdf.save(`port-${fdate}.pdf`);
        });
    };

    useEffect(() => {
        const body = {
            yacht_no: yachtNo,
            start_at: startDate,
            end_at: endDate,
        }
        ApiListPort(body);
    }, []);


    return (
        <>
            <div ref={ref} id="print-area-port">
                <div className="print-page">
                    <div className="text-9">
                        ■ 마리나항만의 조성 및 관리 등에 관한 법률 시행규칙 [별지 제23호의2서식] {'<'}신설 2020. 8. 19.{'>'}
                    </div>
                    <div className="print-title mt-20 mb-10 text-center">
                        마리나선박 출항ᆞ입항 기록ᆞ관리대장
                    </div>

                    <div className="print-table-wrap port">
                        <table cellSpacing="0" cellPadding="0">
                            <tbody>
                            <tr>
                                <th colSpan={4} className="text-left text-bold">
                                    1. 선박 제원 및 승선 인원 현황
                                </th>
                            </tr>
                            <tr>
                                <th className="pw-20">선 명</th>
                                <td className="pw-30">{yachtInfo.yacht_name}</td>
                                <th className="pw-20">톤 수</th>
                                <td className="text-right">{yachtInfo.yacht_weight}톤</td>
                            </tr>
                            <tr>
                                <th>승선 인원</th>
                                <td>승객: {userCount}</td>
                                <td colSpan={2} className="left-no">선원: {mateCount}</td>
                            </tr>
                            </tbody>
                        </table>
                        <table cellSpacing="0" cellPadding="0" className="top-no">
                            <tbody>
                            <tr>
                                <th colSpan={7} className="text-left text-bold">
                                    2. 출항ᆞ입항 사항
                                </th>
                            </tr>
                            <tr>
                                <th colSpan={3}>출항</th>
                                <th colSpan={3}>입항</th>
                                <th className="bot-no">&nbsp;</th>
                            </tr>

                            <tr>
                                <th>일시</th>
                                <th>출항지</th>
                                <th>승선인원</th>
                                <th>일시</th>
                                <th>입항지</th>
                                <th>하선인원</th>
                                <th>기록자</th>
                            </tr>
                            {boardingList.map(({
                                                   mate_name,boarding_user_count,mate_count,
                                                   sailing_start_at,sailing_finish_at,yacht_name,created_at
                                               }, k) =>
                                <tr key={k}>
                                    <td>{sailing_start_at}</td>
                                    <td>{yachtInfo.location_address}</td>
                                    <td className="text-right">{parseInt(boarding_user_count) + parseInt(mate_count)}</td>

                                    <td>{sailing_finish_at}</td>
                                    <td>{yachtInfo.location_address}</td>
                                    <td className="text-right">{parseInt(boarding_user_count) + parseInt(mate_count)}</td>
                                    <td>{mate_name}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            <div className="float-wrap">
                <ReactToPrint
                    trigger={() => <button type="button" className="btn btn-primary btn-wide">
                        <PrinterIcon className="w-6 h-6" />  Print
                    </button>}
                    content={() => ref.current}
                />
                <div className="divider divider-vertical my-1"></div>
                <button type="button" className="btn btn-info btn-wide"
                        onClick={printPDF}>
                    <ArrowDownTrayIcon className="w-6 h-6" /> PDF
                </button>
            </div>
        </>
    );
}