import dayjs from "dayjs";
import {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";

export default function AdminHome() {

    return <Navigate to="/manage/boarding/sail"></Navigate>;

    /*
    const today = dayjs().format('YYYY년 MM월 DD일');
    const [date, setDate] = useState(new Date())
    useEffect(() => {
        const timeId = setInterval(() => tick(), 1000)
        return () => {
            clearInterval(timeId)
            // console.log('clearInterval')
        }
    },[]);
    const tick = () => {
        setDate(new Date())
    }

    return (
        <>
            <main className="content">
                <div className="paper">
                    <div className="flex min-h-full bg-transparent flex-row justify-center px-6 py-12 lg:px-8">
                    <div className="stats shadow">
                        <div className="stat">
                            <div className="stat-title">TODAY</div>
                            <div className="stat-value">{today}</div>
                            <div className="stat-desc mt-2 text-center">{dayjs(date).format('YYYY-MM-DD HH:mm:ss')}</div>
                        </div>
                    </div>
                    </div>
                </div>
            </main>
        </>
    )
    */
}