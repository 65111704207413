import { createSlice } from "@reduxjs/toolkit";

export const recordSlice = createSlice({
    name: "Record",
    initialState: {
        resultId: '',
        txt: '',
        mobile: '',
        result: '',
        filename: '',
        createdTime: ''
    },
    reducers: {
        setRecord: (state, action) => {
            // state.resultId = acticon.payload.resultId;
            // state.txt = action.payload.txt;
            // state.mobile = action.payload.mobile;
            // state.filename = action.payload.filename;
            // state.createdTime = action.payload.createdTime;
            // state.result = action.payload.result;
            return state;
        },
        clearRecord: (state) => {
            state.resultId = '';
            state.txt = '';
            state.mobile = '';
            state.filename = '';
            state.createdTime = '';
            state.result = '';
            return state;
        },
    },
});

export const { setRecord, clearRecord } = recordSlice.actions;
export default recordSlice.reducer;
