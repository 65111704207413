import React, {useEffect, useState} from "react";
import Api from "utils/Api";
import * as Auth from "components/Auth";
import {useNavigate} from "react-router-dom";
import * as G from "utils/utils";

export function SailingListSelect({sail_no, setChangedSailingNo}) {
    const user = Auth.AuthCheck();
    const navigate = useNavigate();

    const [sailingNo, setSailingNo] = useState();

    const handleChangeSailNo = (e) => {
        setSailingNo(e.target.value);
        setChangedSailingNo(e.target.value);
    }

    const [sailingList, setSailingList] = useState([]);
    const ApiListSailing = (body) => {
        const url = "boarding/boarding_able_sailing_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");

                    alert(result.message);
                    return;
                }
                const dataCount = response.data.info.count;
                const dataList = response.data.list;
                setSailingList(dataList);
            }
        );
    };

    const getSailStatus = (k) => {
        return G.getSailStatusStyleText(sailingList[k]);
    }

    useEffect(() => {
        if(sail_no)
            setSailingNo(sail_no);

    }, [sail_no]);

    useEffect(() => {
        ApiListSailing();
    }, []);

    return (
        <>
            <table className="table w-full h-full text-center">
                <thead>
                <tr>
                    <th>선택</th>
                    <th>항해회차ID</th>
                    <th>요트명</th>
                    <th>항해예정일시</th>
                    <th>항해사</th>
                    <th>예약건수</th>
                    <th>승선작성/예약자수</th>
                    <th>항해완료여부</th>
                </tr>
                </thead>
                <tbody>
                {sailingList.map(({created_at,mate_name,mate_no,reserve_count,reserve_total_user_count,
                                      boarding_writer_count,sailing_no,start_at,status_cd,status_name,cruise_name,yacht_no}, k) =>
                    <tr key={k}>
                        <td>
                            <input type="radio"
                                   name="sailing_no"
                                   value={sailing_no}
                                   onChange={handleChangeSailNo}
                                   checked={sailingNo === sailing_no ? true : false}
                                   className="radio" />
                        </td>
                        <td>{sailing_no}</td>
                        <td>{cruise_name}</td>
                        <td>{start_at}</td>
                        <td>{mate_name}</td>
                        <td>{reserve_count}</td>
                        <td>{boarding_writer_count}/{reserve_total_user_count}</td>
                        <td>{getSailStatus(k)}</td>
                    </tr>
                )}
                {sailingList.length < 1 ?
                    <tr>
                        <td colSpan="8" className="td-no-record">
                            선택 가능한 회차 정보가 없습니다.<br />
                        </td>
                    </tr>
                    : null
                }
                </tbody>
            </table>
        </>
    )
}
