import {useNavigate} from "react-router-dom";
import YachtImg from "styles/images/yacht_b.png";
import React from "react";
import {HomeIcon} from "@heroicons/react/24/outline";

export default function NoMatch() {
    const navigate = useNavigate();
    return (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                <h1 className="menu-title">
                    <a href="/" className="menu-logo-link">
                    <img src={YachtImg} className="inline-block mr-2 mb-2 App-logo" />
                    <span className="normal-case text-indigo-700 text-2xl">승선관리시스템</span>
                </a>
                </h1>
                <p className="text-base font-semibold text-indigo-600">404</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">페이지를 찾을 수 없습니다</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">죄송합니다. 원하시는 페이지를 찾을 수 없습니다..</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <button
                        onClick={() => navigate(-1)}
                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        뒤로 돌아가기
                    </button>
                </div>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <a href="/" className="text-sm font-semibold text-gray-900">
                        <HomeIcon className="w-5 h-5 inline-block align-bottom" /> 홈으로
                    </a>
                    <a href="mailto:contact@reigntalk.co.kr" className="text-sm font-semibold text-gray-900">지원팀에 문의<span
                        aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </div>
        </main>
    );
}