import {ExclamationCircleIcon} from "@heroicons/react/24/outline";
import React from "react";

export default function ModalBox({title=null, children, action="확인", callBack}) {
    return (
        <>
            <form method="dialog" className="modal-box">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                <h3 className="font-bold text-lg md:text-xl text-center">
                    <ExclamationCircleIcon className="text-warning inline-block w-7 h-7 mr-2" />
                    {title}
                </h3>
                <p className="py-4 text-center whitespace-nowrap text-sm md:text-base">{children}</p>
                <div className="flex justify-center items-center mt-1.5">
                    <button className="btn btn-sm btn-primary w-1/5" onClick={callBack}>{action}</button>
                    <button className="btn btn-sm w-1/5 ml-2">취소</button>
                </div>
            </form>
            <form method="dialog" className="modal-backdrop">
                <button>close</button>
            </form>
        </>
    );
}
