import { createSlice } from "@reduxjs/toolkit";

export const sailSlice = createSlice({
    name: "Sail",
    initialState: {
        boarding_writer_count: '',
        created_at: '',
        mate_name: '',
        mate_no: '',
        reserve_count: '',
        reserve_total_user_count: '',
        sailing_id: '',
        sailing_no: '',
        start_at: '',
        start_at_format: '',
        status_cd: '',
        status_name: '',
        cruise_name: '',
        yacht_no: '',
        embark_max_count: '',
        max_crew_members: '',
        location_address: '',
        location_name: '',
        location_link: '',
        boarding_user_count: '',
        sailing_start_at: '',
        sailing_finish_at: '',
    },
    reducers: {
        setSail: (state, action) => {
            state.boarding_writer_count = action.payload.boarding_writer_count;
            state.created_at = action.payload.created_at;
            state.mate_name = action.payload.mate_name;
            state.mate_no = action.payload.mate_no;
            state.reserve_count = action.payload.reserve_count;
            state.reserve_total_user_count = action.payload.reserve_total_user_count;
            state.sailing_id = action.payload.sailing_id;
            state.sailing_no = action.payload.sailing_no;
            state.start_at = action.payload.start_at;
            state.start_at_format = action.payload.start_at_format;
            state.status_cd = action.payload.status_cd;
            state.status_name = action.payload.status_name;
            state.cruise_name = action.payload.cruise_name;
            state.yacht_no = action.payload.yacht_no;
            state.embark_max_count = action.payload.embark_max_count;
            state.max_crew_members = action.payload.max_crew_members;
            state.location_address = action.payload.location_address;
            state.location_name = action.payload.location_name;
            state.location_link = action.payload.location_link;
            state.boarding_user_count = action.payload.boarding_user_count;
            state.sailing_start_at = action.payload.sailing_start_at;
            state.sailing_finish_at = action.payload.sailing_finish_at;

            return state;
        },
        clearSail: (state) => {
            state.boarding_writer_count = '';
            state.created_at = '';
            state.mate_name = '';
            state.mate_no = '';
            state.reserve_count = '';
            state.reserve_total_user_count = '';
            state.sailing_id = '';
            state.sailing_no = '';
            state.start_at = '';
            state.start_at_format = '';
            state.status_cd = '';
            state.status_name = '';
            state.cruise_name = '';
            state.yacht_no = '';
            state.embark_max_count = '';
            state.max_crew_members = '';
            state.location_address = '';
            state.location_name = '';
            state.location_link = '';
            state.boarding_user_count = '';
            state.sailing_start_at = '';
            state.sailing_finish_at = '';

            return state;
        },
    },
});

export const { setSail, clearSail } = sailSlice.actions;
export default sailSlice.reducer;
