import Select, { StylesConfig } from 'react-select';
import chroma from 'chroma-js';



const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : color.alpha(0.3).css()
                    : undefined,
            },
        };
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot('#ccc'), color: '#000', fontWeight: '600' }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

export default function SelectGender({ genderoption,onChange=null,defaultValue=null,placeholder='성별' }) {
    return (
        <Select
            defaultValue={defaultValue}
            name={'gender'}
            classNames={{
                control: () => "text-sm h-12 w-full sm:max-w-sm font-semibold mr-2 ml-2",
            }}
            // value={yachtoption.find(c => c.value === value)}
            options={genderoption}
            onChange={val => onChange(val.value)}
            placeholder={placeholder}
            isSearchable={false}
        />
    );
}
