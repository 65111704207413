import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import {toast} from "store/toastSlice";
import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";

export default function AccountInfo() {
    const user = Auth.AuthCheck();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        await G.sleep(1000);
        // console.log('data',data);
        ApiModifyMyInfo(data);
        await G.sleep(1000);
    };

    const {
        register,
        reset,
        getValues,
        handleSubmit,
        formState: { isSubmitting, errors }
    } = useForm();

    useEffect(() => {
        ApiReadMyInfo();
    }, []);

    const ApiReadMyInfo = (body) => {
        const url = "setting/setting_my_info";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                let myInfo = response.data.info;
                const {created_at,email,manage_id,manage_name,manage_no,phone} = myInfo;
                reset({
                    manage_id: manage_id,
                    phone: phone,
                    manage_name: manage_name,
                    email: email,
                    new_password1: '',
                    new_password2: '',
                });
            }
        );
    };

    const ApiModifyMyInfo = (body) => {
        const url = "setting/setting_my_update";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                //console.log('result : ', result);
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                console.log("정보업데이트성공",response);
                dispatch(toast({message : "저장되었습니다", kind : "success"}));
            }
        );
    };

    return (
        <>
            <main className="content">

                <div className="paper">
                    <div className="sub-title">
                        <span className="text-gradient">내정보</span>
                    </div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="paper">
                        <div className="form-row">
                            <div className="mobile-row md:basis-1/4 text-center">
                                <label className="label">
                                    <span className="label-text">아이디</span>
                                </label>
                            </div>
                            <div className="mobile-row md:basis-full">
                                    <input type="text"
                                           placeholder="아이디"
                                           id="manage_id"
                                           readOnly={true}
                                           {...register("manage_id")}
                                           className={`input input-bordered w-full ${G.errorStyle(errors.phone)}`}
                                    />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="mobile-row md:basis-1/4 text-center">
                                    <label className="label">
                                        <span className="label-text">이름</span>
                                    </label>
                                </div>
                            <div className="mobile-row md:basis-full">
                                    <input type="text"
                                           placeholder="이름"
                                           name="manage_name"
                                           {...register("manage_name", {
                                               required: "필수 입력입니다.",
                                           })}
                                           className={`input input-bordered w-full ${G.errorStyle(errors.manage_name)}`}
                                    />
                                    {G.errorDesc(errors.manage_name)}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="mobile-row md:basis-1/4 text-center">
                                    <label className="label">
                                        <span className="label-text">휴대폰번호</span>
                                    </label>
                            </div>
                            <div className="mobile-row md:basis-full">
                                    <input type="text"
                                           placeholder="-를 제외하고 숫자만 입력해주세요. ex)01012341234"
                                           name="phone"
                                           maxLength="11"
                                           {...register("phone", {
                                               required: "필수 입력입니다.",
                                               pattern: {
                                                   value: /01[016789][0-9]{3,4}[0-9]{4}/,
                                                   message: "휴대폰번호 형식에 맞지 않습니다.",
                                               },
                                               maxLength: {
                                                   value: 11,
                                                   message: "숫자 최대 11자 입니다."
                                               },
                                           })}
                                           className={`input input-bordered w-full ${G.errorStyle(errors.phone)}`}
                                    />
                                    {G.errorDesc(errors.phone)}

                            </div>
                        </div>
                        <div className="form-row">
                            <div className="mobile-row md:basis-1/4 text-center">

                                    <label className="label">
                                        <span className="label-text">이메일주소</span>
                                    </label>
                            </div>
                            <div className="mobile-row md:basis-full">
                                    <input type="text"
                                           placeholder="이메일주소"
                                           name="email"
                                           {...register("email", {
                                               required: "필수 입력입니다.",
                                               pattern: {
                                                   value:
                                                       /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                                                   message: "이메일 형식에 맞지 않습니다.",
                                               },
                                           })}
                                           className={`input input-bordered w-full ${G.errorStyle(errors.email)}`}
                                    />
                                    {G.errorDesc(errors.email)}
                                </div>
                        </div>

                        <div className="form-row">
                            <div className="mobile-row md:basis-1/4 text-center">

                                    <label className="label">
                                        <span className="label-text">비밀번호</span>
                                    </label>
                            </div>
                            <div className="mobile-row md:basis-full">
                                    <input type="password"
                                           placeholder="변경 시 영문,특문,숫자 중 2개 이상의 조합으로 8자리 이상의 비밀번호를 생성해주세요."
                                           name="new_password1"
                                           {...register("new_password1", {
                                               required: false,
                                               validate: (value) => {
                                                   if(value) {
                                                       const regExp1 = (/^(?=.*[a-zA-Z]).{8,25}$/).test(value) ? 1 : 0;
                                                       const regExp2 = (/^(?=.*[!@#$%^*+=-])/).test(value) ? 1 : 0;
                                                       const regExp3 = (/^(?=.*[0-9])/).test(value) ? 1 : 0;
                                                       if ((regExp1 + regExp2 + regExp3) < 2) {
                                                           return "영문,특문,숫자 중 2개 이상의 조합으로 8자리 이상의 비밀번호를 생성해주세요.";
                                                       }
                                                   }
                                               },
                                           })}
                                           className={`input input-bordered w-full ${G.errorStyle(errors.new_password1)}`}
                                    />
                                    {G.errorDesc(errors.new_password1)}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="mobile-row md:basis-1/4 text-center">

                                    <label className="label">
                                        <span className="label-text">비밀번호확인</span>
                                    </label>
                            </div>
                            <div className="mobile-row md:basis-full">
                                    <input type="password"
                                           placeholder="비밀번호확인"
                                           name="new_password2"
                                           {...register("new_password2", {
                                               required: false,
                                               validate: (value) => {
                                                       const { new_password1 } = getValues();
                                                       return new_password1 === value || "비밀번호가 일치하지 않습니다.";
                                                   },
                                           })}
                                           className={`input input-bordered w-full ${G.errorStyle(errors.new_password2)}`}
                                    />
                                    {G.errorDesc(errors.new_password2)}
                            </div>
                        </div>
                    </div>
                    <div className="mb-6 text-center px-6 md:px-1">
                        <button type="submit" disabled={isSubmitting}
                                className="btn btn-primary w-full sm:max-w-sm">
                            정보수정
                        </button>
                    </div>
                </form>

            </main>
        </>
    )
}
