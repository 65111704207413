import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";

import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import DatePicker from "react-datepicker";
import InputDatePicker from "components/InputDate";
import SelectGender from "components/SelectGender";

const defaultValues = {
    cruise_name: '',
    location: '',
    cruise_size: '',
    embark_max_count: '',
    max_crew_members: '',
    cruise_weight: '',
    mates: [],
    open_yn: 1,
    cruise_id: '',
    login_id: '',
    password: '',
    created_at: '',
    updated_at: '',
    mate_name: '',
}

export default function CruiseForm({cruise_no, onFinish}) {
    const user = Auth.AuthCheck();
    const [cruiseNo, setCruiseNo] = useState();
    const [actionText, setActionText] = useState();
    const [cruiseLocationList, setCruiseLocationList] = useState([]);
    const [mateList, setMateList] = useState([]);
    const [cruiseMateList, setCruiseMateList] = useState([]);
    const [genderOptionList, setGenderOptionList] = useState([]);
    const [gender, setGender] = useState();
    const [mate_birth, setMate_birth] = useState();
    const [mate_name, setMate_name] = useState();
    const [selectedDate, setSelectedDate] = useState(new Date());


    const [items, setItems] = useState([]);

    const colourOptions = [
        { value: 'M', label: '남자'},
        { value: 'F', label: '여자'},
    ];

    const onSubmit = async (data) => {
        await G.sleep(1000);
        ApiModifyCruise(data);
        await G.sleep(1000);
    };

    const {
        register,
        reset,
        watch,
        control,
        setError,
        handleSubmit,
        formState: { isSubmitting, errors }
    } = useForm({defaultValues});

    const validatePassword = (val) => {
        if(cruiseNo === 'create') {
            if (val.length <= 0)
                return "크루즈비밀번호는 필수 입력입니다."
            if (val.length > 10)
                return "크루즈비밀번호는 최대 10글자 입니다."
        } else {
            if (val.length > 0) {
                if (val.length > 10)
                    return "크루즈비밀번호는 최대 10글자 입니다."
            }
        }
    };
    const validateId = (val) => {
        if(cruiseNo === 'create') {
            if (val.length < 2)
                return "크루즈ID는 특수문자 제외, 최소 2글자 입니다."
            if (val.length > 6)
                return "크루즈ID는 특수문자 제외, 최대 6글자 입니다.."

            let regExp = /^[0-9a-zA-Z]{2,6}$/i
            if (regExp.test(val) === false) {
                return "크루즈ID 형식에 맞지 않습니다.(특수문자 제외, 최대 6자)";
            }
        }
    };

    const watchId = watch("login_id");
    const watchPass = watch("password");
    useEffect(() => {
        const err = validateId(watchId);
        // console.log('watchId',watchId);
        if(err) {
            setError('login_id', { type: "custom", message: err });
        } else {
            setError('login_id', { type: "custom", message: "" });
        }
    }, [watchId]);
    useEffect(() => {
        const err = validatePassword(watchPass);
        // console.log('watchPass',watchPass);
        if(err) {
            setError('password', { type: "custom", message: err });
        } else {
            setError('password', { type: "custom", message: "" });
        }
    }, [watchPass]);

    const handleSelectGender = (val) => {
        setGender(val);
    };
    useEffect(() => {
        setCruiseNo(cruise_no);
        // console.log(cruise_no);
    }, [cruise_no]);

    useEffect(() => {
        // console.log(cruiseMateList);
    }, [cruiseMateList]);

    useEffect(() => {
        if(cruiseNo === 'create') {
            setActionText('신규등록');
            reset(defaultValues);
        } else if(cruiseNo) {
            setActionText('정보수정');
            ApiReadCruise(cruiseNo);
        }
    }, [cruiseNo]);

    useEffect(() => {
        ApiCodeInfo();
        ApiMateList();
    }, []);

    const ApiCodeInfo = () => {
        let body = {
            p_code : 'cruise_location'
        }
        const url = "code/code_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                setCruiseLocationList(response.data);
            }
        );
    };

    const ApiMateList = () => {
        const url = "setting/setting_mate_list";
        Api('', url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                setMateList(response.data.list);
            }
        );
    };

    const handleProductRemove = (index) => {
        const filteredPd = [...items];
        filteredPd[index].isDelete = 'Y';
        setItems(filteredPd);
    };

    const handleFormChange = (e) => {
        setSelectedMate({
            ...selectedMate,
            [e.target.name]: e.target.value,
        });
    };

    const handleProductAdd = () => {
        console.log('===================');
        console.log(selectedMate)
        console.log(selectedDate);
        console.log(gender);
        if (gender == undefined) {
            alert('성별을 선택해주세요.'); return;
        }
        if (selectedMate.mate_name == "") {
            alert('이름을 입력해주세요.'); return;
        }
        if (selectedMate.mate_phone == "") {
            alert('생일을 입력해주세요.'); return;
        }
        if (selectedDate == "") {
            alert('생일을 입력해주세요.'); return;
        }
        setItems([
            ...items,
            { mate_name: selectedMate.mate_name, gender: gender, mate_phone: selectedMate.mate_phone, mate_birth: selectedDate },
        ]);
        selectedMate.mate_name = "";  setGender(undefined); selectedMate.mate_phone = ""; setSelectedDate(new Date());
    };

    const [selectedMate, setSelectedMate] = useState({
        mate_name: '',
        mate_gender: '',
        mate_phone: '',
        mate_birth: '',
    });

    const ApiReadCruise = (cruise_no) => {
        let body = {
            cruise_no : cruise_no
        }
        const url = "setting/setting_cruises_info";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                const cruiseInfo = response.data.info;
                const {embark_max_count, created_at, updated_at, location,open_yn,cruise_name,cruise_no,cruise_id,cruise_size,login_id, mates,max_crew_members,cruise_weight} = cruiseInfo;
                setItems(mates.map((x)=> {
                    console.log(x);
                    return {id: x.id,mate_id: x.mate_id, gender: x.gender, mate_no: x.mate_no,mate_name: x.mate_name,mate_birth: new  Date(x.birth) ,mate_phone: x.phone,  isDelete: 'N'};
                }));
                const mateLists = mates.map((x)=> {
                    return x.mate_no;
                });
                reset({
                    cruise_name: cruise_name,
                    location: location,
                    cruise_size: cruise_size,
                    embark_max_count: embark_max_count,
                    max_crew_members: max_crew_members,
                    cruise_weight: cruise_weight,
                    mates: mateLists,
                    open_yn: open_yn,
                    cruise_id: cruise_id,
                    login_id: login_id,
                    password: '',
                    created_at: created_at,
                    updated_at: updated_at,
                });
            }
        );
    };

    const ApiModifyCruise = (body) => {
        let url = "";
        if(cruiseNo === 'create')
            url = "setting/setting_cruises_save";
        else
            url = "setting/setting_cruises_update";

        body = {...body, mates: items}
        if(cruiseNo)
            body = {...body, cruise_no: cruiseNo}
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                console.log("정보업데이트성공");
                onFinish();
            }
        );
    };
    const handleDateChange = (
      index: number,
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const list = [...items];
        console.log(index, e);
        list[index].mate_birth = e;
        console.log(list)
        setItems(list);
    };

    const handleChange = (
      index: number,
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const list = [...items];
        console.log(index, e);
        list[index][e.target.id] = e.target.value;
        setItems(list);
    };

    const handleCruiseMates = (e, no) => {
        let cloneList = [...cruiseMateList];
        const selectedItem = mateList[no];
        const findIndex = cruiseMateList.findIndex(data => data.mate_no === selectedItem.mate_no);
        if(findIndex >= 0) { // 리스트 있다
            cloneList[findIndex] = {...cloneList[findIndex], isDelete: !(e.target.checked)};
        } else { // 리스트 없다
            cloneList = [...cloneList, {
                id: '0',
                mate_no: selectedItem.mate_no,
                isDelete: !(e.target.checked)
            }];
        }
        setCruiseMateList(cloneList);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="paper">
                <h4 className="text-lg">
                    크루즈정보
                    <div className="divider my-1"></div>
                </h4>

                <div className="form-row">
                    <div className="w-full">
                        <div className="form-control w-full mobile-row">
                            <label className="label">
                                <span className="label-text">크루즈명</span>
                            </label>
                            <input type="text"
                                   placeholder="크루즈명"
                                   name="cruise_name"
                                   {...register("cruise_name", {
                                       required: "크루즈명은 필수 입력입니다.",
                                       minLength: {
                                           value: 2,
                                           message: "2자리 이상 크루즈명을 사용하세요."
                                       }
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.cruise_name)}`}
                            />
                            {G.errorDesc(errors.cruise_name)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-full">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">선박위치</span>
                            </label>
                            <div className="md:join">
                                {cruiseLocationList.map(({code, code_name}) =>
                                    <label key={code} className="label inline-flex cursor-pointer justify-start md:justify-between">
                                        <input type="radio"
                                               name="location"
                                               {...register('location')}
                                               value={code}
                                               className="radio " />
                                        <span className="label-text mx-2">{code_name}</span>
                                    </label>
                                )}
                            </div>
                            {G.errorDesc(errors.location)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">톤수</span>
                            </label>
                            <div className="join">
                            <input type="number"
                                   step="0.001"
                                   placeholder="톤(ton)단위로 숫자만 입력해주세요."
                                   name="cruise_weight"
                                   {...register("cruise_weight", {
                                       required: "크루즈톤수는 필수 입력입니다.",
                                   })}
                                   className={`input input-bordered join-item w-full ${G.errorStyle(errors.cruise_weight)}`}
                            />
                                <button type="button" className="btn btn-ghost join-item">T</button>
                            </div>
                            {G.errorDesc(errors.cruise_weight)}
                        </div>
                    </div>

                </div>

                <div className="form-row">
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">승무원수</span>
                            </label>
                            <input type="number"
                                   placeholder="해당 크루즈에 탑승하는 승무원수를 입력해주세요. (승선신고서, 출입 기록에서 사용)"
                                   name="max_crew_members"
                                   {...register("max_crew_members", {
                                       required: "최대승선승무원수는 필수 입력입니다.",
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.max_crew_members)}`}
                            />
                            {G.errorDesc(errors.max_crew_members)}
                        </div>
                    </div>
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">최대승선인원수</span>
                            </label>
                            <input type="number"
                                   placeholder="숫자만 입력하세요.(승무원 포함)"
                                   name="embark_max_count"
                                   {...register("embark_max_count", {
                                       required: "최대승선인원수는 필수 입력입니다.",
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.embark_max_count)}`}
                            />
                            {G.errorDesc(errors.embark_max_count)}
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">QR리더 ID</span>
                            </label>
                            <input type="text"
                                   placeholder="QR 리더기 로그인을 위한 아이디를 생성해주세요. (특수문자 제외, 최대 5자)"
                                   readOnly={cruiseNo === 'create' ? false : true}
                                   name="login_id"
                                   {...register("login_id", {
                                       required: "크루즈ID는 필수 입력입니다.",
                                       validate: (value) => validateId(value),
                                       // minLength: {
                                       //     value: 2,
                                       //     message: "크루즈ID는 특수문자 제외, 최소 2글자 입니다."
                                       // },
                                       // maxLength: {
                                       //     value: 6,
                                       //     message: "크루즈ID는 특수문자 제외, 최대 6글자 입니다."
                                       // },
                                       // pattern: {
                                       //     value:
                                       //         /^[0-9a-zA-Z]{2,5}$/i,
                                       //     message: "크루즈ID 형식에 맞지 않습니다.(특수문자 제외, 최대 6자)",
                                       // },
                                   })}
                                   className={`input input-bordered join-item w-full ${G.errorStyle(errors.login_id)}`}
                            />
                            {G.errorDesc(errors.login_id)}
                        </div>
                    </div>
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">QR리더 비밀번호</span>
                            </label>
                            <input type="text"
                                   placeholder="QR 리더기 로그인을 위한 비밀번호를 입력해주세요.(최대 10자)"
                                   name="password"
                                   {...register("password", {
                                       validate: (value) => validatePassword(value),
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.password)}`}
                            />
                            {G.errorDesc(errors.password)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-full">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">담당항해사</span>
                            </label>
                            {items.filter(item => item.isDelete !=='Y').map((item, index)  => (
                            <div className="md:join mt-2" key={index}>
                                <div className="mobile-row md:basis-1/5 mr-2">
                                    <div className="form-control w-full">
                                        <input type="text"
                                               placeholder="이름"
                                               name="mate_name"
                                               onChange={handleFormChange}
                                               value={item.mate_name || ''}
                                               className={`input input-bordered w-full ${G.errorStyle(errors.mate_name)}`}
                                        />
                                        {G.errorDesc(errors.mate_name)}
                                    </div>
                                </div>
                                <div className="mobile-row md:basis-1/5 mr-2" style={{ marginRight: "20px"}}>
                                    <SelectGender
                                      defaultValue={colourOptions.find(c => c.value === item.gender)}
                                      genderoption={colourOptions}
                                      onChange={handleSelectGender}
                                    />
                                </div>
                                <div className="mobile-row md:basis-1/5 mr-2">
                                    <div className="form-control w-full">
                                        <input type="text"
                                               placeholder="휴대폰번호."
                                               name="mate_phone"
                                               onChange={e => handleChange(index, e)}
                                               value={item.mate_phone || ''}
                                               className={`input input-bordered w-full ${G.errorStyle(errors.mate_phone)}`}
                                        />
                                        {G.errorDesc(errors.mate_phone)}
                                    </div>
                                </div>
                                <div className="mobile-row md:basis-1/5 mr-2">
                                    <DatePicker
                                      dateFormat="yyyy-MM-dd"
                                      // timeFormat="HH:mm"
                                      dateFormatCalendar="yyyy년 MM월"
                                      maxDate={new Date()}
                                      timeCaption="생년월일"
                                      placeholderText="생년월일"
                                      selected={item.mate_birth}
                                      onChange={e => handleDateChange(index, e)}
                                      className="start-date input input-bordered w-full placeholder-gray-500 "
                                    />
                                </div>
                                <div className="mobile-row md:basis-1/5">
                                    <div className="form-control w-full">
                                        <button type="button" className="btn btn-outline w-full md:max-w-xs"
                                          onClick={() => {
                                              console.log('wwww')
                                              handleProductRemove(index)
                                          }}
                                          >
                                            삭제
                                        </button>
                                    </div>
                                </div>
                                      {/*<input type="text"*/}
                                      {/*       name="mates"*/}
                                      {/*       {...register("mate_name", {*/}
                                      {/*           required: "항해사명은 필수 입력입니다.",*/}
                                      {/*           validate: (value) => validateId(value),*/}
                                      {/*       })}*/}
                                      {/*       value=""*/}
                                      {/*       className={`input input-bordered w-full ${G.errorStyle(errors.mate_name)}`} />*/}

                            </div>
                            ))}
                            <div className="md:join mt-2">
                                <div className="mobile-row md:basis-1/5 mr-2">
                                    <div className="form-control w-full">
                                        <input type="text"
                                               placeholder="이름"
                                               name="mate_name"
                                               onChange={handleFormChange}
                                               value={selectedMate.mate_name || ''}
                                               className={`input input-bordered w-full ${G.errorStyle(errors.mate_name)}`}
                                        />
                                        {G.errorDesc(errors.mate_name)}
                                    </div>
                                </div>
                                <div className="mobile-row md:basis-1/5 mr-2"  style={{ marginRight: "20px"}}>
                                    <div className="form-control w-full">
                                    {/*<SelectGender*/}
                                    {/*  defaultValue={'M' || ''}*/}
                                    {/*  onChange={handleSelectGender}*/}
                                    {/*  genderoption={genderOptionList}*/}
                                    {/*/>*/}
                                        <SelectGender
                                          defaultValue={'M' || ''}
                                          genderoption={colourOptions}
                                          onChange={handleSelectGender}
                                        />
                                    </div>
                                </div>
                                <div className="mobile-row md:basis-1/5 mr-2  mr-2">
                                    <div className="form-control w-full">
                                        <input type="text"
                                               placeholder="휴대폰번호"
                                               name="mate_phone"
                                               onChange={handleFormChange}
                                               value={selectedMate.mate_phone || ''}
                                               className={`input input-bordered w-full ${G.errorStyle(errors.mate_phone)}`}
                                        />
                                        {G.errorDesc(errors.mate_phone)}
                                    </div>
                                </div>
                                <div className="mobile-row md:basis-1/5 mr-2">
                                    <DatePicker
                                      dateFormat="yyyy-MM-dd"
                                      // timeFormat="HH:mm"
                                      dateFormatCalendar="yyyy년 MM월"
                                      maxDate={new Date()}
                                      timeCaption="생년월일"
                                      placeholderText="생년월일"
                                      selected={selectedDate}
                                      onChange={(date) => setSelectedDate(date)}
                                      className="start-date input input-bordered w-full placeholder-gray-500 "
                                    />

                                    {/*<input type="text"*/}
                                    {/*       placeholder="생년월일"*/}
                                    {/*       name="mate_birth"*/}
                                    {/*       onChange={handleFormChange}*/}
                                    {/*       value={selectedMate.mate_birth || ''}*/}
                                    {/*       className={`input input-bordered w-full ${G.errorStyle(errors.mate_birth)}`}*/}
                                    {/*/>*/}

                                </div>
                                <div className="mobile-row md:basis-1/5">
                                    <div className="form-control w-full">
                                        <button type="button" className="btn btn-outline w-full md:max-w-xs"
                                                onClick={handleProductAdd}>
                                            추가
                                        </button>
                                    </div>
                                </div>
                                {/*<input type="text"*/}
                                {/*       name="mates"*/}
                                {/*       {...register("mate_name", {*/}
                                {/*           required: "항해사명은 필수 입력입니다.",*/}
                                {/*           validate: (value) => validateId(value),*/}
                                {/*       })}*/}
                                {/*       value=""*/}
                                {/*       className={`input input-bordered w-full ${G.errorStyle(errors.mate_name)}`} />*/}

                            </div>
                            {/*<div className="md:join">*/}
                            {/*    {mateList.map(({mate_no, mate_name},k) =>*/}
                            {/*        <label key={k} className="label inline-flex cursor-pointer justify-start md:justify-between">*/}
                            {/*            <input type="checkbox"*/}
                            {/*                   name="mates"*/}
                            {/*                   {...register('mates')}*/}
                            {/*                   value={mate_no}*/}
                            {/*                   onChange={(e) => handleCruiseMates(e,k)}*/}
                            {/*                   className="checkbox " />*/}
                            {/*            <span className="label-text mx-2">{mate_name}</span>*/}
                            {/*        </label>*/}
                            {/*    )}*/}
                            {/*</div>*/}
                            {G.errorDesc(errors.mates)}
                        </div>
                    </div>
                </div>
                <p className="my-2 text-purple-700 text-sm italic text-point">담당항해사를 선택하시면 승선 캘린더 작성 시 상위에 노출됩니다.</p>
            </div>
            <div className="paper">
                <h4 className="text-lg">
                    부가정보
                    <div className="divider my-1"></div>
                </h4>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/4 text-center">
                            <label className="label">
                                <span className="label-text">노출여부</span>
                            </label>
                    </div>
                    <div className="mobile-row md:basis-full">
                            <div className="join">
                                <label className="label inline-flex cursor-pointer justify-start md:justify-between">
                                    <input type="radio"
                                           name="open_yn"
                                           {...register('open_yn')}
                                           value="1"
                                           className="radio " />
                                    <span className="label-text mx-2"> 노출</span>
                                </label>
                                <label className="label inline-flex cursor-pointer justify-start md:justify-between">
                                    <input type="radio"
                                           name="open_yn"
                                           {...register('open_yn')}
                                           value="0"
                                           className="radio " />
                                    <span className="label-text mx-2">노출하지 않음</span>
                                </label>
                            </div>
                            {G.errorDesc(errors.open_yn)}

                        <p className="my-2 text-purple-700 text-sm italic text-point">승선 캘린더 작성 시 노출 여부를 선택합니다.</p>
                    </div>
                </div>
                <div className="form-row">
                        <div className="mobile-row md:basis-1/4 text-center">
                            <label className="label">
                                <span className="label-text">크루즈CODE</span>
                            </label>
                        </div>
                        <div className="mobile-row md:basis-full">
                            <input type="text"
                                   placeholder="크루즈ID"
                                   name="cruise_id"
                                   readOnly={true}
                                   {...register("cruise_id")}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.cruise_id)}`}
                            />
                            {G.errorDesc(errors.cruise_id)}
                        </div>

                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/4 text-center">
                            <label className="label">
                                <span className="label-text">등록일</span>
                            </label>
                    </div>
                    <div className="mobile-row md:basis-full">
                            <input type="text"
                                   placeholder="가입일"
                                   name="created_at"
                                   readOnly={true}
                                   {...register("created_at")}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.created_at)}`}
                            />
                            {G.errorDesc(errors.created_at)}
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/4 text-center">
                            <label className="label">
                                <span className="label-text">최종업데이트일</span>
                            </label>
                    </div>
                    <div className="mobile-row md:basis-full">
                            <input type="text"
                                   placeholder="최종업데이트일"
                                   name="updated_at"
                                   readOnly={true}
                                   {...register("updated_at")}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.updated_at)}`}
                            />
                            {G.errorDesc(errors.updated_at)}

                    </div>
                </div>
            </div>
            <div className="mb-6 text-center px-6 md:px-1">
                <button type="submit" disabled={isSubmitting}
                        className="btn btn-primary w-full sm:max-w-sm">
                    {actionText}
                </button>
            </div>
        </form>
    )
}
