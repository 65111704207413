import { createSlice } from "@reduxjs/toolkit";

export const yachtListSlice = createSlice({
    name: "Yacht",
    initialState: {
        yacht_list: [
            // {
            //     embark_max_count: "50",
            //     location: "TNG_01",
            //     max_crew_members: "1",
            //     yacht_id: "B50000Y0001",
            //     yacht_name: "마린빅요트",
            //     yacht_no: "1",
            //     value: yacht_no
            //     label: yacht_name
            //     color: "#000dff"
            // }
        ]
    },
    reducers: {
        saveYachtList: (state, action) => {
            state.yacht_list = action.payload.yacht_list;
            return state;
        },
        clearYachtList: (state) => {
            state.yacht_list = [];
            return state;
        },
    },
});

export const { saveYachtList, clearYachtList } = yachtListSlice.actions;
export default yachtListSlice.reducer;