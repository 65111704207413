import React, {useEffect, useState} from 'react';

import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import {PrinterIcon} from "@heroicons/react/24/outline";

export default function RecDetail({sail=null, openWindow = openWindow}) {
    const user = Auth.AuthCheck();
    console.log(sail);
    const [sailNo, setSailNo] = useState();

    useEffect(() => {

        if(sail) {
            console.log(sail);
            if(sail.resultId) {
                setSailNo(sail.resultId);
            }
        }
    }, [sail]);

    return (
        <>
            <div className="form-row">
                <div className="w-full">
                    <table className="table table-xs md:table-md w-full bg-base-200 shadow-md">
                        <tbody>
                        <tr>
                            <td colSpan={2}>{sail.result}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>{sail.txt}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>{sail.mobile} </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>{sail.filename}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>{sail.createdTime}</td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td colSpan={4}>출항일시 : {sailInfo.sailing_start_at}</td>*/}
                        {/*    <td colSpan={4}>입항일시 : {sailInfo.sailing_finish_at}</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td colSpan={6}>탑승장소 : {sailInfo.location_address}</td>*/}
                        {/*    <td colSpan={1} className="text-right">*/}
                        {/*        <button type="button" className="btn btn-info btn-xs md:btn-sm w-full"*/}
                        {/*                onClick={openWindow}>*/}
                        {/*            <PrinterIcon className="w-5 h-5" /> 출력*/}
                        {/*        </button>*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="divider-vertical py-6"></div>
        </>
    )
}
