import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import {toast} from "store/toastSlice";
import YachtForm from "components/YachtForm";
import SelectYacht from "components/SelectYacht";
import {saveYachtList} from "store/yachtSlice";

export default function YachtInfo() {
    const user = Auth.AuthCheck();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [yachtList, setYachtList] = useState([]);
    const [yachtOptionList, setYachtOptionList] = useState([]);
    const [yachtNo, setYachtNo] = useState('');

    const handleChangeYacht = (e) => {
        setYachtNo(e.target.value);
    };
    const handleSelectYacht = (val) => {
        setYachtNo(val);
    };
    const handleCreateYacht = (e) => {
        setYachtNo('create');
    }

    const handleFinishYachtForm = () => {
        ApiListYacht();
        setYachtNo('');
        dispatch(toast({message : "저장되었습니다", kind : "success"}));
    }

    const ApiListYacht = (body) => {
        const url = "setting/setting_yachts_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                const yachtCount = response.data.info.count;
                const yachtList = response.data.list;
                // console.log("yachtList",yachtList);
                setYachtList(yachtList);
                setYachtOptionList(yachtList.map(({yacht_no, yacht_name},k) => {
                    return {value: yacht_no, label: yacht_name, color: G.colorYacht[k]};
                } ));

                const yachtListSliceItems = yachtList.map((item,k) => {
                    return {...item, value: item.yacht_no, label: item.yacht_name, color: G.colorYacht[k]};
                } );
                dispatch(saveYachtList({yacht_list: yachtListSliceItems}));
            }
        );
    };

    useEffect(() => {
        ApiListYacht();
    }, []);

    return (
        <>
            <main className="content">

                <div className="paper">
                    <div className="sub-title">
                        <span className="text-gradient">요트정보</span>
                    </div>
                </div>

                <div className="paper">
                    <h4 className="text-lg">
                        요트선택
                        <div className="divider my-1"></div>
                    </h4>

                    <div className="form-row">
                        <div className="md:basis-1/2">
                            <div className="form-control w-full">
                                <SelectYacht
                                    defaultValue={yachtNo || ''}
                                    onChange={handleSelectYacht}
                                    yachtoption={yachtOptionList}
                                />
                            </div>
                            {/*<div className="form-control w-full">*/}
                            {/*    <select*/}
                            {/*        id="yacht_no"*/}
                            {/*        value={yachtNo || ''}*/}
                            {/*        onChange={handleChangeYacht}*/}
                            {/*        className="select select-bordered w-full sm:max-w-sm">*/}
                            {/*        <option value="">요트선택</option>*/}
                            {/*        {yachtList.map(({yacht_no, yacht_name}) =>*/}
                            {/*            <option key={yacht_no} value={yacht_no}>*/}
                            {/*                {yacht_name}*/}
                            {/*            </option>*/}
                            {/*        )}*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                        </div>
                        <div className="md:basis-1/2 right-button">
                            <button type="button"
                                    onClick={handleCreateYacht}
                                    className="btn btn-primary w-full sm:max-w-sm">
                                요트등록
                            </button>
                        </div>
                    </div>
                </div>
                {yachtNo ?
                    <YachtForm yacht_no={yachtNo}
                               onFinish={handleFinishYachtForm} />
                :
                    <div className="px-6 py-12 lg:px-8 my-4 rounded-lg shadow-md bg-base-100 space-y-6 flex justify-center items-center text-center">
                        확인하실 요트를 선택하시거나,<br />
                        새로운 요트를 등록해주세요.
                    </div>
                }
            </main>
        </>
    )
}