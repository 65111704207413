import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import {toast} from "store/toastSlice";
import {ArrowLongLeftIcon} from "@heroicons/react/24/outline";

const defaultValues = {
    reserve_name: '',
    reserve_phone: '',
    reserve_user_count: '',
    emergency_contact: '',
    memo: '',
    send_type: '',
    sms_message: ''
}

export default function ReserveForm({mode, show, SMSCODE=null, onFinish}) {
    const user = Auth.AuthCheck();
    const dispatch = useDispatch();
    const sailInfo = useSelector(state => state.Sail)
    const reserveInfo = useSelector(state => state.Reserve)

    const [sailNo, setSailNo] = useState();
    const [reserveNo, setReserveNo] = useState();
    // const [sailInfo, setSailInfo] = useState(G.SailInfo);
    const [availReserveCount, setAvailReserveCount] = useState(0);
    const [actionText, setActionText] = useState();
    const [smsCodeList, setSmsCodeList] = useState([]);

    const onSubmit = async (data) => {
        await G.sleep(1000);
        // console.log('data',data);
        ApiModifyReserve(data);
        await G.sleep(1000);
    };

    const {
        register,
        setValue,
        reset,
        handleSubmit,
        formState: { isSubmitting, errors }
    } = useForm({defaultValues});

    useEffect(() => {
        if(sailInfo) {
            setSailNo(sailInfo.sailing_no);
        }
    }, [sailInfo]);

    const setSmsMessage = (no) => {
        const message = G.ReplaceTemplate(smsCodeList[no].code_desc, sailInfo, reserveInfo);
        setValue('sms_message', message);
    }

    const validateReserveCount = (val) => {
        if(mode === 'create') {
            return availReserveCount >= val || "예약 가능 인원수를 초과하였습니다.";
        } else {
            return parseInt(reserveInfo.boarding_user_count) <= val || "명부 작성 인원이 많습니다. 명부작성 인원 삭제 후 변경해주세요";
        }
    }

    useEffect(() => {
        if(show === true) {
            setReserveNo(reserveInfo.reserve_no);
            G.ApiSmsCodeInfo(SMSCODE)
                .then((codes) => {
                    setSmsCodeList(codes);
                });
            const count = parseInt(sailInfo.embark_max_count)-parseInt(sailInfo.reserve_total_user_count)-parseInt(sailInfo.max_crew_members);

            if(mode === 'create' && count <= 0) {
                onFinish();
                alert("예약 인원수가 초과 되였습니다.");
                return;
            }

            setAvailReserveCount(count);
            if(mode === 'create') {
                setActionText('생성');
                reset(defaultValues);
            } else {
                setActionText('수정');
                setReserveInfo();
            }
        } else {
            reset(defaultValues);
            // dispatch(clearReserve(reserveInfo))
        }
    }, [show]);

    const setReserveInfo = () => {
        if(reserveInfo) {
            reset({
                reserve_name: reserveInfo.reserve_name,
                reserve_phone: reserveInfo.reserve_phone,
                reserve_user_count: reserveInfo.reserve_user_count,
                emergency_contact: reserveInfo.emergency_contact,
                memo: reserveInfo.memo,
                send_type: '',
                sms_message: ''
            });
        }
    }

    const ApiModifyReserve = (body) => {
        let url = "";
        body = {...body, sailing_no: sailNo}

        if(mode === 'create') {
            url = "boarding/boarding_reserve_save";
        } else {
            body = {...body, reserve_no: reserveNo}
            url = "boarding/boarding_reserve_update";
        }

        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }

                // let reserve_no = (mode === 'create') ? response.data.reserve_no : reserveNo;
                // if(body.send_type !== 'Z099') {
                //     let tmp_msg = body.sms_message;
                //     if(tmp_msg) {
                //     tmp_msg = tmp_msg
                //         .replace('/boarding/view?v=','/boarding/view?v='+response.data.uuid)
                //         .replace('/boarding/view?v=undefined','/boarding/view?v='+response.data.uuid)
                //         .replace('##uuid##',response.data.uuid)
                //         ;
                //         G.ApiSendSms({
                //             ref_no: reserve_no,
                //             send_type: body.send_type,
                //             message: tmp_msg,
                //             phone: body.reserve_phone
                //         });
                //         dispatch(toast({message : "문자발송되었습니다", kind : "success"}));
                //     }
                // }
                console.log("정보업데이트성공");
                dispatch(toast({message : "저장되었습니다", kind : "success"}));
                onFinish();
            }
        );
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-3">

                <div className="app-bar-head">
                    <div className="bar-left">
                        <button type="button" className="btn btn-ghost btn-xs px-0 mx-auto mobile-show"
                                onClick={() => onFinish()}>
                            <ArrowLongLeftIcon className="w-5 h-5 mx-1" />
                        </button>
                    </div>
                    <h4 className="bar-center text-lg">
                        예약 {actionText}
                    </h4>
                    <div className="bar-right"></div>
                </div>

                <div className="divider my-1"></div>

                <div className="form-row">
                    <div className="w-full overflow-x-auto">
                        <table className="table w-full bg-base-200">
                            <tbody>
                            <tr>
                                <th>크루즈명</th>
                                <td>{sailInfo.cruise_name}</td>
                            </tr>
                            {/*<tr>*/}
                            {/*    <th>항해사</th>*/}
                            {/*    <td>{sailInfo.mate_name}</td>*/}
                            {/*</tr>*/}
                            <tr>
                                <th>항해일시</th>
                                <td>{sailInfo.start_at}</td>
                            </tr>
                            {/*<tr>*/}
                            {/*    <th>입항일시</th>*/}
                            {/*    <td>{sailInfo.status_name}</td>*/}
                            {/*</tr>*/}
                            <tr>
                                <th>예약가능</th>
                                <td>{availReserveCount}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="form-row">
                    <div className="md:basis-full">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">예약자명</span>
                            </label>
                            <input type="text"
                                   placeholder="예약자 성명을 입력해주세요."
                                   name="reserve_name"
                                   {...register("reserve_name", {
                                       required: "예약자명은 필수 입력입니다.",
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.reserve_name)}`}
                            />
                            {G.errorDesc(errors.reserve_name)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="md:basis-full">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">예약자 연락처</span>
                            </label>
                            <input type="text"
                                   placeholder="-를 제외하고 숫자만 입력해주세요. ex)01012341234"
                                   name="reserve_phone"
                                   maxLength="11"
                                   {...register("reserve_phone", {
                                       required: "예약자 연락처는 필수 입력입니다.",
                                       pattern: {
                                           value: /01[016789][0-9]{3,4}[0-9]{4}/,
                                           message: "휴대폰번호 형식에 맞지 않습니다.",
                                       },
                                       maxLength: {
                                           value: 11,
                                           message: "숫자 최대 11자 입니다."
                                       },
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.reserve_phone)}`}
                            />
                            {G.errorDesc(errors.reserve_phone)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="md:basis-full">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">승선인원수(예약자포함)</span>
                            </label>
                            <input type="number"
                                   placeholder="예약자를 포함하여 숫자만 입력해주세요."
                                   name="reserve_user_count"
                                   {...register("reserve_user_count", {
                                       required: "승선인원수는 필수 입력입니다.",
                                       validate: (value) => validateReserveCount(value),
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.reserve_user_count)}`}
                            />
                            {G.errorDesc(errors.reserve_user_count)}
                        </div>
                    </div>
                </div>
                {/*<div className="form-row">*/}
                {/*    <div className="md:basis-full">*/}
                {/*        <div className="form-control w-full">*/}
                {/*            <label className="label">*/}
                {/*                <span className="label-text">비상 연락처</span>*/}
                {/*            </label>*/}
                {/*            <input type="text"*/}
                {/*                   placeholder="-를 제외하고 숫자만 입력해주세요 ex)01012341234"*/}
                {/*                   name="emergency_contact"*/}
                {/*                   maxLength="11"*/}
                {/*                   {...register("emergency_contact", {*/}
                {/*                       required: "비상 연락처는 필수 입력입니다.",*/}
                {/*                       maxLength: {*/}
                {/*                           value: 11,*/}
                {/*                           message: "숫자 최대 11자 입니다."*/}
                {/*                       },*/}
                {/*                   })}*/}
                {/*                   className={`input input-bordered w-full ${G.errorStyle(errors.emergency_contact)}`}*/}
                {/*            />*/}
                {/*            {G.errorDesc(errors.emergency_contact)}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="form-row">
                    <div className="md:basis-full">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">메모</span>
                            </label>
                            <textarea type="text"
                                      placeholder="메모"
                                      name="memo"
                                      {...register("memo")}
                                      className={`textarea textarea-bordered w-full`}
                            ></textarea>
                        </div>
                    </div>
                </div>


                <div className="form-row">
                    <div className="md:basis-full">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">문자발송</span>
                            </label>

                            {smsCodeList.map(({code, code_name},k) =>
                                <label key={k} className="label cursor-pointer justify-start">
                                    <input type="radio"
                                           name="send_type"
                                           {...register('send_type', {
                                                   required: "문자발송 여부는 필수 입력입니다.",
                                           })}
                                           value={code}
                                           onClick={()=>setSmsMessage(k)}
                                           className="radio " />
                                    <span className="label-text mx-2">{code_name}</span>
                                </label>
                            )}
                            {G.errorDesc(errors.send_type)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="md:basis-full">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">발송 메세지</span>
                            </label>
                            <textarea type="text"
                                      placeholder="발송 메세지"
                                      name="sms_message"
                                      {...register("sms_message")}
                                      className={`textarea textarea-bordered w-full`}
                            ></textarea>
                            {G.errorDesc(errors.sms_message)}
                        </div>
                    </div>
                </div>

                <div className="mb-6 text-center px-6 md:px-1">
                    <button type="submit" disabled={isSubmitting}
                            className="btn btn-primary w-full sm:max-w-sm">
                        {actionText}
                    </button>
                </div>
            </form>
        </>
    )
}
