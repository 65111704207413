import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import {toast} from "store/toastSlice";
import CruiseForm from "components/CruiseForm";
import SelectCruise from "components/SelectCruise";
import {saveCruiseList} from "store/cruiseSlice";

export default function CruiseInfo() {
    const user = Auth.AuthCheck();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [cruiseList, setCruiseList] = useState([]);
    const [cruiseOptionList, setCruiseOptionList] = useState([]);
    const [cruiseNo, setCruiseNo] = useState('');

    const handleChangeCruise = (e) => {
        setCruiseNo(e.target.value);
    };
    const handleSelectCruise = (val) => {
        setCruiseNo(val);
    };
    const handleCreateCruise = (e) => {
        setCruiseNo('create');
    }

    const handleFinishCruiseForm = () => {
        ApiListCruise();
        setCruiseNo('');
        dispatch(toast({message : "저장되었습니다", kind : "success"}));
    }

    const ApiListCruise = (body) => {
        const url = "setting/setting_cruises_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                const cruiseCount = response.data.info.count;
                const cruiseList = response.data.list;
                // console.log("cruiseList",cruiseList);
                setCruiseList(cruiseList);
                setCruiseOptionList(cruiseList.map(({cruise_no, cruise_name},k) => {
                    return {value: cruise_no, label: cruise_name, color: G.colorCruise[k]};
                } ));

                const cruiseListSliceItems = cruiseList.map((item,k) => {
                    return {...item, value: item.cruise_no, label: item.cruise_name, color: G.colorCruise[k]};
                } );
                dispatch(saveCruiseList({cruise_list: cruiseListSliceItems}));
            }
        );
    };

    useEffect(() => {
        ApiListCruise();
    }, []);

    return (
        <>
            <main className="content">

                <div className="paper">
                    <div className="sub-title">
                        <span className="text-gradient">크루즈정보</span>
                    </div>
                </div>

                <div className="paper">
                    <h4 className="text-lg">
                        크루즈선택
                        <div className="divider my-1"></div>
                    </h4>

                    <div className="form-row">
                        <div className="md:basis-1/2">
                            <div className="form-control w-full">
                                <SelectCruise
                                    defaultValue={cruiseNo || ''}
                                    onChange={handleSelectCruise}
                                    cruiseoption={cruiseOptionList}
                                />
                            </div>
                            {/*<div className="form-control w-full">*/}
                            {/*    <select*/}
                            {/*        id="cruise_no"*/}
                            {/*        value={cruiseNo || ''}*/}
                            {/*        onChange={handleChangeCruise}*/}
                            {/*        className="select select-bordered w-full sm:max-w-sm">*/}
                            {/*        <option value="">크루즈선택</option>*/}
                            {/*        {cruiseList.map(({cruise_no, cruise_name}) =>*/}
                            {/*            <option key={cruise_no} value={cruise_no}>*/}
                            {/*                {cruise_name}*/}
                            {/*            </option>*/}
                            {/*        )}*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                        </div>
                        <div className="md:basis-1/2 right-button">
                            <button type="button"
                                    onClick={handleCreateCruise}
                                    className="btn btn-primary w-full sm:max-w-sm">
                                크루즈등록
                            </button>
                        </div>
                    </div>
                </div>
                {cruiseNo ?
                    <CruiseForm cruise_no={cruiseNo}
                               onFinish={handleFinishCruiseForm} />
                :
                    <div className="px-6 py-12 lg:px-8 my-4 rounded-lg shadow-md bg-base-100 space-y-6 flex justify-center items-center text-center">
                        확인하실 크루즈를 선택하시거나,<br />
                        새로운 크루즈를 등록해주세요.
                    </div>
                }
            </main>
        </>
    )
}
