import { Routes, Route } from 'react-router-dom';

import Layout from 'routes/Layout';
import NoMatch from 'routes/NoMatch';
import LayoutAdmin from 'routes/LayoutAdmin'
import LayoutPure from "routes/LayoutPure";

import Login from 'pages/user/login'
import Logout from "pages/user/logout";
import AdminHome from "pages/admin";
import Home from 'pages/home';
import CompanyInfo from "pages/preference/company";
import YachtInfo from "pages/preference/yacht";
import CruiseInfo from "pages/preference/cruise";
import MateInfo from "pages/preference/mate";
import AccountInfo from "pages/user/account";
import Sailing from "pages/boarding/sail";
import {RouteAuth} from "routes/RouteAuth";
import Reserve from "pages/boarding/reserve";
import Pass from "pages/boarding/pass";
import Port from "pages/boarding/port";
import Print from "pages/boarding/print";
import Record from "pages/record/list";

export default function RoutesSetup() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
      </Route>
      <Route path="/manage" element={<RouteAuth><LayoutAdmin /></RouteAuth>}>
        <Route index element={<AdminHome />} />
        <Route path="boarding">
          <Route path="sail" element={<Sailing />} />
          <Route path="reserve" element={<Reserve />}>
            <Route path=":sailno" element={<Reserve />} />
          </Route>
          <Route path="create" element={<AdminHome />} />
          <Route path="pass" element={<Pass />} />
          <Route path="port" element={<Port />} />
        </Route>
        <Route path="preference">
          <Route path="company">
            <Route index element={<CompanyInfo />} />
            <Route path="info" element={<CompanyInfo />} />
          </Route>
          <Route path="yacht" element={<YachtInfo />} />
          <Route path="cruise" element={<CruiseInfo />} />
          <Route path="sailor" element={<MateInfo />} />
          <Route path="member" element={<AdminHome />} />
        </Route>
        <Route path="record">
          <Route path="list" element={<Record />} />
        </Route>
      </Route>
      <Route path="/user" element={<RouteAuth><LayoutAdmin /></RouteAuth>}>
        <Route path="account" element={<AccountInfo />} />
      </Route>

      <Route path="/user" element={<LayoutPure />}>
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        {/*<Route path="register" element={<Register />} />*/}
        {/*<Route path="password" element={<Password />} />*/}
      </Route>
      <Route path="/manage/boarding/print/*" element={<Print />} />

      {/*<Route path="/manage/boarding/port/print" element={<LayoutPure />}>*/}
      {/*    <Route path=":yachtNo/*" element={<PortPrint />} />*/}
      {/*</Route>*/}
      {/*<Route path="/manage/boarding/pass/print" element={<LayoutPure />}>*/}
      {/*    <Route path=":sailNo/*" element={<PassPrint />} />*/}
      {/*</Route>*/}

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}
