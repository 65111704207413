import React from "react";
import {Route, Routes} from "react-router-dom";
import PortPrint from "components/PortPrint";
import PassPrint from "components/PassPrint";

export default function Print() {

    return (
        <>
            <Routes>
                <Route path="pass/:sailNo/*" element={<PassPrint />} />
                <Route path="port/:yachtNo/*" element={<PortPrint />} />
            </Routes>
        </>
    )
}