import UserLoginForm from "components/UserLogIn";
import React from "react";
import {useNavigate} from "react-router-dom";
import YachtImg from "styles/images/yacht_b.png";

export default function Login() {
    const navigate = useNavigate();
    return (
        <>
            <div className="flex min-h-full bg-transparent justify-center items-center">
                <div className="paper">
                    <div className="flex flex-col justify-center px-4 py-12 lg:px-6 md:min-w-[400px]">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md mb-10 mt-[-30px] text-center">
                            <div className="sub-title">
                                <a href="/" className="text-gradient">
                                    <img src={YachtImg} className="inline-block mr-2 mb-2 App-logo" />
                                    승선관리시스템
                                </a>
                            </div>
                            <p className="mt-6">
                                부여받은 아이디와 비밀번호를 입력해주세요. 👋
                            </p>
                        </div>
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                            <UserLoginForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}