import DatePicker, {registerLocale} from "react-datepicker";
import { Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import {
  CalendarDaysIcon,
} from "@heroicons/react/24/outline"

export default function InputMDateTimePicker({ control,register }) {
  registerLocale("ko", ko);
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() <= selectedDate.getTime();
  };
  return (
    <Controller
      control={control}
      name="start_at"
      rules={{ required: "항해시작일시은 필수 입력입니다." }}
      render={({ field: { onChange, value } }) => (
        <div className="relative w-full">
          <DatePicker
            dateFormat="yyyy-MM-dd HH:mm"
            // timeFormat="HH:mm"
            dateFormatCalendar="yyyy년 MM월"
            minDate={new Date()}
            locale="ko"
            showTimeSelect
            filterTime={filterPassedTime}
            timeIntervals={30}
            timeCaption="시작시간"
            placeholderText="항해시작일시"
            selected={value}
            onChange={(data) => onChange(data)}
            className="start-date input input-bordered w-full placeholder-gray-500 "
          />
          <div className="absolute inset-y-0 right-3 flex items-center pr-3 pointer-events-none">
            <CalendarDaysIcon className="h-5 w-5 cursor-pointer" />
          </div>
        </div>
      )}
    />
  );
};
