import { createSlice } from "@reduxjs/toolkit";

export const cruiseListSlice = createSlice({
    name: "Cruise",
    initialState: {
        cruise_list: [
            // {
            //     embark_max_count: "50",
            //     location: "TNG_01",
            //     max_crew_members: "1",
            //     cruise_id: "B50000Y0001",
            //     cruise_name: "마린빅요트",
            //     cruise_no: "1",
            //     value: cruise_no
            //     label: cruise_name
            //     color: "#000dff"
            // }
        ]
    },
    reducers: {
        saveCruiseList: (state, action) => {
            state.cruise_list = action.payload.cruise_list;
            return state;
        },
        clearCruiseList: (state) => {
            state.cruise_list = [];
            return state;
        },
    },
});

export const { saveCruiseList, clearCruiseList } = cruiseListSlice.actions;
export default cruiseListSlice.reducer;
