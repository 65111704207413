import {useDispatch, useSelector} from "react-redux";
import Api from "utils/Api";
import {setToken} from "store/userSlice";

export function AuthCheck() {
    const user = useSelector((state) => state.user);
    if(user !== null && user !== 'undefined' && user !== '')
        return user;
    else
        return {};
}
export function TokenCheck() {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    let token = null;
    let exp = null;
    if(user !== null && user !== 'undefined' && user !== '') {
        if (user.isLogin === true) {
            token = localStorage.getItem('accessToken');
            exp = localStorage.getItem('expiredTime');
            ///// exp 체크
            // let diffTime = this.$moment.duration(expiredTime.diff(this.$moment()))
            // if (diffTime < 10000){
            //     let body = { token: token, expiredTime: exp };
            //     reIssueToken(body).then(
            //         (response) => {
            //             const result = response.result;
            //             if (result.code > 0 ) {
            //                 console.log('Result Error',result.code);
            //                 return;
            //             }
            //             dispatch(setToken(response.data));
            //         }
            //     );
            // }
        }
    }
    if(token !== null && token !== 'undefined' && token !== '')
        return token;
    else
        return null;
}
const reIssueToken = async (body) => {
    const response = await Api.post("login/login_check", body)
        .then((res) => res.data)
        .catch((err) => {
            console.log('api error : ', err);
            const message = err.message;
            return (`${message}`);
        });
    return  response;
};