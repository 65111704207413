import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';
import storage from "redux-persist/lib/storage/session"; // session storage

import userReducer from 'store/userSlice';
import smsItemReducer from 'store/smsItemSlice';
import toastReducer from "store/toastSlice";
import sailReducer from "store/sailSlice";
import reserveReducer from "store/reserveSlice";
import yachtListReducer from "store/yachtSlice";
import RecordReducer from "store/recordSlice";

const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
}

const reducers = combineReducers({
    user: userReducer,
    Sail: sailReducer,
    Reserve: reserveReducer,
    smsItem: smsItemReducer,
    Toast: toastReducer,
    Yacht: yachtListReducer,
    Record: RecordReducer,
});
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user','smsItem','Toast','Sail','Reserve','Yacht','Record']
};
const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
    reducer: persistedReducer,
    middleware,
});
