import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    HomeIcon
} from "@heroicons/react/24/outline"

import {clearUser, loginUser} from "store/userSlice";
import {clearUserMode} from "store/initSlice"
import YachtImg from "styles/images/yacht_b.png";
import UserLoginForm from "components/UserLogIn";

export default function Logout() {
    const user = useSelector((state) => state.user);
    const [isLogin, setIsLogin] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearUser(user));
    }, []);

    useEffect(() => {
        setIsLogin(user.isLogin);
    }, [user]);

    return (
        <>
            <div className="flex min-h-full bg-transparent justify-center items-center">
                <div className="paper">
                    <div className="flex flex-col justify-center px-4 py-12 lg:px-6 md:min-w-[400px]">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md mb-10 mt-[-30px] text-center">
                            <div className="sub-title">
                                <a href="/" className="text-gradient">
                                    <img src={YachtImg} style={{maxHeight: '30px'}} className="inline-block mr-2 mb-2" />
                                    승선관리시스템
                                </a>
                            </div>
                            <p className="mt-6">
                                {isLogin ?
                                    <>
                                        {user.name} 님 로그아웃되지 않았습니다.
                                    </>
                                    :
                                    <>
                                        안전하게 로그아웃 되었습니다.
                                    </>
                                }
                            </p>
                        </div>
                        <div className="flex min-h-full justify-center items-center">
                            <a href="/">
                                <HomeIcon className="w-6 h-6 inline-block align-bottom" /> 홈으로
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}