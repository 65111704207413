import React, {useEffect, useState, useRef} from "react";
import Datepicker from "react-tailwindcss-datepicker";

import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import {useNavigate} from "react-router-dom";
import {PrinterIcon} from "@heroicons/react/24/outline";
import {GrUserPolice} from "react-icons/gr";
import {LiaClock, LiaUserFriendsSolid} from "react-icons/lia";

export default function Port() {
    const user = Auth.AuthCheck();
    const navigate = useNavigate();
    const [dateValue, setDateValue] = useState({
        startDate: null,
        endDate: null
    });
    const handleDatePickerValueChange = (newValue) => {
        setDateValue(newValue);
    }
    const [yachtList, setYachtList] = useState([]);
    const [yachtNo, setYachtNo] = useState('');
    const handleChangeYacht = (e) => {
        setYachtNo(e.target.value);
    };

    const [yachtInfo, setYachtInfo] = useState({
        yacht_name: '', location_address: ''
    });
    const [boardingList, setBoardingList] = useState([]);
    const ApiListPort = (body) => {
        const url = "boarding/boarding_port_entry_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                const dataCount = response.data.info.count;
                const dataList = response.data.list;
                setBoardingList(dataList);
                setYachtInfo(response.data.yacht_info);
            }
        );
    };
    const handleSearchBoarding = () => {
        const body = {
            yacht_no: yachtNo,
            start_at: dateValue.startDate,
            end_at: dateValue.endDate,
        }
        if(!yachtNo) {
            alert('요트가 선택되지 않았습니다.');
            return;
        }
        ApiListPort(body);
    };
    const ApiListYacht = (body) => {
        const url = "setting/setting_cruises_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                const yachtCount = response.data.info.count;
                const yachtList = response.data.list;
                // console.log("yachtList",yachtList);
                setYachtList(yachtList);
            }
        );
    };

    const openWindow = () => {
        const sa = dateValue.startDate;
        const ea = dateValue.endDate;
        const url = `/manage/boarding/print/port/${yachtNo}?start_at=${sa}&end_at=${ea}`;
        window.open(url, "_blank", "width=620, height=700, noopener, noreferrer");
    }

    useEffect(() => {
        ApiListYacht();
    }, []);


    return (
        <>
            <main className="content">
                <div className="paper">
                    <div className="sub-title">
                        <span className="text-gradient">출입항기록관리</span>
                    </div>
                </div>

                <div className="paper">
                    <div className="form-row">
                        <div className="md:basis-1/2">
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">요트선택</span>
                                </label>
                                <select
                                    id="yacht_no"
                                    value={yachtNo || ''}
                                    onChange={handleChangeYacht}
                                    className="select select-bordered w-full">
                                    <option value="">요트선택</option>
                                    {yachtList.map(({cruise_no, cruise_name}) =>
                                        <option key={cruise_no} value={cruise_no}>
                                            {cruise_name}
                                        </option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="md:basis-1/2">
                            <div className="form-control w-full">
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="md:basis-full">
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">조회기간</span>
                                </label>
                                <div className="form-row">
                                    <div className="md:basis-4/5">
                                        <Datepicker
                                            i18n={"ko"}
                                            primaryColor={"purple"}
                                            inputClassName="input input-bordered w-full"
                                            containerClassName="relative"
                                            toggleClassName="absolute rounded-r-lg right-0 h-full px-3"
                                            value={dateValue}
                                            displayFormat={"YYYY/MM/DD"}
                                            onChange={handleDatePickerValueChange}
                                            showShortcuts={false}
                                        />
                                    </div>
                                    <div className="md:basis-1/5 right-button">
                                        <button type="button"
                                                onClick={handleSearchBoarding}
                                                className="btn btn-outline w-full">
                                            검색
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="paper">
                    {yachtInfo.yacht_name ?
                            <div className="form-row bg-base-200 shadow-md p-6">
                                <div className="md:basis-4/5">
                                    <span className="font-bold">요트명</span>
                                    <span className="mx-1">:</span>
                                    <span className="mr-6">
                                        {G.getStyleYacht(yachtInfo.yacht_no,yachtInfo.yacht_name,yachtList)}
                                        {/*{yachtInfo.yacht_name}*/}
                                    </span>
                                    <span className="font-bold">장소</span>
                                    <span className="mx-1">:</span>
                                    <span className="mr-6">{yachtInfo.location_address}</span>
                                </div>
                                <div className="hidden md:block md:basis-1/5 text-right">
                                    <button type="button" className="btn btn-info btn-sm w-full"
                                            onClick={openWindow}>
                                        <PrinterIcon className="w-5 h-5" /> 출력
                                    </button>
                                </div>
                            </div>
                        : null
                    }
                    <div className="table-wrapper my-4">
                        <div className="div-table w-full">
                            <div className="div-table-row div-table-header">
                                <div className="div-table-cell">항해사</div>
                                <div className="div-table-cell">승선자수</div>
                                <div className="div-table-cell">출항일시</div>
                                <div className="div-table-cell">입항일시</div>
                            </div>
                            {boardingList.map(({
                                                   mate_name,boarding_user_count,mate_count,boarding_user_all_count,
                                                   sailing_start_at,sailing_finish_at,yacht_name,created_at
                                               }, k) =>
                                <div key={k} className="div-table-row">
                                    <div className="div-table-cell">
                                        <GrUserPolice className="inline-block h-4 w-4 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                        {mate_name} <span className="mobile-show ml-2">항해사</span><
                                        /div>
                                    <div className="div-table-cell">
                                        <LiaUserFriendsSolid className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                        <span className="mobile-show">승선자</span> {boarding_user_all_count}
                                    </div>
                                    <div className="div-table-cell">
                                        <LiaClock className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                        {sailing_start_at} <span className="mobile-show">출항</span>
                                    </div>
                                    <div className="div-table-cell">
                                        <LiaClock className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                        {sailing_finish_at} <span className="mobile-show">입항</span>
                                    </div>
                                </div>
                            )}
                        </div>
                            {boardingList.length < 1 ?
                                <div className="div-no-record w-full">
                                    검색된 정보가 없습니다.<br />
                                </div>
                                : null
                            }
                    </div>
                </div>

            </main>
        </>
    );
}
