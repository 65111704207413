import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        name: "",
        id: "",
        idx: 0,
        token: "",
        exp: null,
        isLogin: false,
    },
    reducers: {
        loginUser: (state, action) => {
            state.name = action.payload.manage_name;
            state.id = action.payload.manage_id;
            state.idx = action.payload.manage_no;
            state.token = action.payload.token;
            state.exp = action.payload.expiredTime;
            state.isLogin = true;

            localStorage.setItem('accessToken', action.payload.token);
            localStorage.setItem('refreshToken', action.payload.token);
            localStorage.setItem('expiredTime', action.payload.expiredTime);

            return state;
        },
        setUserName: (state, action) => {
            return {
                ...state,
                name: action.payload.mb_name
            };
        },
        setToken: (state, action) => {
            localStorage.setItem('accessToken', action.payload.token);
            localStorage.setItem('refreshToken', action.payload.token);
            localStorage.setItem('expiredTime', action.payload.expiredTime);
            return {
                ...state,
                token: action.payload.token,
                exp: action.payload.exp
            };
        },
        clearUser: (state) => {
            state.name = "";
            state.id = "";
            state.idx = 0;
            state.token = "";
            state.exp = null;
            state.isLogin = false;

            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('expiredTime');

            return state;
        },
    },
});

export const { loginUser, clearUser, setUserName, setToken } = userSlice.actions;
export default userSlice.reducer;