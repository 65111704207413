import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";

import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";

const defaultValues = {
    yacht_name: '',
    location: '',
    yacht_size: '',
    embark_max_count: '',
    max_crew_members: '',
    yacht_weight: '',
    mates: [],
    open_yn: 1,
    yacht_id: '',
    login_id: '',
    password: '',
    created_at: '',
    updated_at: '',
}

export default function YachtForm({yacht_no, onFinish}) {
    const user = Auth.AuthCheck();
    const [yachtNo, setYachtNo] = useState();
    const [actionText, setActionText] = useState();
    const [yachtLocationList, setYachtLocationList] = useState([]);
    const [mateList, setMateList] = useState([]);
    const [yachtMateList, setYachtMateList] = useState([]);

    const onSubmit = async (data) => {
        await G.sleep(1000);
        ApiModifyYacht(data);
        await G.sleep(1000);
    };

    const {
        register,
        reset,
        watch,
        setError,
        handleSubmit,
        formState: { isSubmitting, errors }
    } = useForm({defaultValues});

    const validatePassword = (val) => {
        if(yachtNo === 'create') {
            if (val.length <= 0)
                return "요트비밀번호는 필수 입력입니다."
            if (val.length > 10)
                return "요트비밀번호는 최대 10글자 입니다."
        } else {
            if (val.length > 0) {
                if (val.length > 10)
                    return "요트비밀번호는 최대 10글자 입니다."
            }
        }
    };
    const validateId = (val) => {
        if(yachtNo === 'create') {
            if (val.length < 2)
                return "요트ID는 특수문자 제외, 최소 2글자 입니다."
            if (val.length > 6)
                return "요트ID는 특수문자 제외, 최대 6글자 입니다.."

            let regExp = /^[0-9a-zA-Z]{2,6}$/i
            if (regExp.test(val) === false) {
                return "요트ID 형식에 맞지 않습니다.(특수문자 제외, 최대 6자)";
            }
        }
    };

    const watchId = watch("login_id");
    const watchPass = watch("password");
    useEffect(() => {
        const err = validateId(watchId);
        // console.log('watchId',watchId);
        if(err) {
            setError('login_id', { type: "custom", message: err });
        } else {
            setError('login_id', { type: "custom", message: "" });
        }
    }, [watchId]);
    useEffect(() => {
        const err = validatePassword(watchPass);
        // console.log('watchPass',watchPass);
        if(err) {
            setError('password', { type: "custom", message: err });
        } else {
            setError('password', { type: "custom", message: "" });
        }
    }, [watchPass]);

    // useEffect(() => {
    //     const subscription = watch((value, { name, type }) =>
    //         // if(name === '')
    //         console.log(value, name, type)
    //         //setError('login_id', { type, message };
    //     )
    //     return () => subscription.unsubscribe()
    // }, [watch]);

    useEffect(() => {
        setYachtNo(yacht_no);
        // console.log(yacht_no);
    }, [yacht_no]);

    useEffect(() => {
        // console.log(yachtMateList);
    }, [yachtMateList]);

    useEffect(() => {
        if(yachtNo === 'create') {
            setActionText('신규등록');
            reset(defaultValues);
        } else if(yachtNo) {
            setActionText('정보수정');
            ApiReadYacht(yachtNo);
        }
    }, [yachtNo]);

    useEffect(() => {
        ApiCodeInfo();
        ApiMateList();
    }, []);

    const ApiCodeInfo = () => {
        let body = {
            p_code : 'yacht_location'
        }
        const url = "code/code_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                setYachtLocationList(response.data);
            }
        );
    };

    const ApiMateList = () => {
        const url = "setting/setting_mate_list";
        Api('', url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                setMateList(response.data.list);
            }
        );
    };

    const ApiReadYacht = (yacht_no) => {
        let body = {
            yacht_no : yacht_no
        }
        const url = "setting/setting_yachts_info";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                const yachtInfo = response.data.info;
                const {embark_max_count, created_at, updated_at, location,open_yn,yacht_name,yacht_no,yacht_id,yacht_size,login_id, mates,max_crew_members,yacht_weight} = yachtInfo;
                setYachtMateList(mates.map((x)=> {
                    return {id: x.id, mate_no: x.mate_no, isDelete: false};
                }));
                const mateLists = mates.map((x)=> {
                    return x.mate_no;
                });
                reset({
                    yacht_name: yacht_name,
                    location: location,
                    yacht_size: yacht_size,
                    embark_max_count: embark_max_count,
                    max_crew_members: max_crew_members,
                    yacht_weight: yacht_weight,
                    mates: mateLists,
                    open_yn: open_yn,
                    yacht_id: yacht_id,
                    login_id: login_id,
                    password: '',
                    created_at: created_at,
                    updated_at: updated_at,
                });
            }
        );
    };

    const ApiModifyYacht = (body) => {
        let url = "";
        if(yachtNo === 'create')
            url = "setting/setting_yachts_save";
        else
            url = "setting/setting_yachts_update";

        body = {...body, mates: yachtMateList}
        if(yachtNo)
            body = {...body, yacht_no: yachtNo}
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                console.log("정보업데이트성공");
                onFinish();
            }
        );
    };

    const handleYachtMates = (e, no) => {
        let cloneList = [...yachtMateList];
        const selectedItem = mateList[no];
        const findIndex = yachtMateList.findIndex(data => data.mate_no === selectedItem.mate_no);
        if(findIndex >= 0) { // 리스트 있다
            cloneList[findIndex] = {...cloneList[findIndex], isDelete: !(e.target.checked)};
        } else { // 리스트 없다
            cloneList = [...cloneList, {
                id: '0',
                mate_no: selectedItem.mate_no,
                isDelete: !(e.target.checked)
            }];
        }
        setYachtMateList(cloneList);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="paper">
                <h4 className="text-lg">
                    요트정보
                    <div className="divider my-1"></div>
                </h4>

                <div className="form-row">
                    <div className="w-full">
                        <div className="form-control w-full mobile-row">
                            <label className="label">
                                <span className="label-text">요트명</span>
                            </label>
                            <input type="text"
                                   placeholder="요트명"
                                   name="yacht_name"
                                   {...register("yacht_name", {
                                       required: "요트명은 필수 입력입니다.",
                                       minLength: {
                                           value: 2,
                                           message: "2자리 이상 요트명을 사용하세요."
                                       }
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.yacht_name)}`}
                            />
                            {G.errorDesc(errors.yacht_name)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-full">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">요트위치</span>
                            </label>
                            <div className="md:join">
                                {yachtLocationList.map(({code, code_name}) =>
                                    <label key={code} className="label inline-flex cursor-pointer justify-start md:justify-between">
                                        <input type="radio"
                                               name="location"
                                               {...register('location')}
                                               value={code}
                                               className="radio " />
                                        <span className="label-text mx-2">{code_name}</span>
                                    </label>
                                )}
                            </div>
                            {G.errorDesc(errors.location)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">톤수</span>
                            </label>
                            <div className="join">
                            <input type="number"
                                   step="0.001"
                                   placeholder="톤(ton)단위로 숫자만 입력해주세요."
                                   name="yacht_weight"
                                   {...register("yacht_weight", {
                                       required: "요트톤수는 필수 입력입니다.",
                                   })}
                                   className={`input input-bordered join-item w-full ${G.errorStyle(errors.yacht_weight)}`}
                            />
                                <button type="button" className="btn btn-ghost join-item">T</button>
                            </div>
                            {G.errorDesc(errors.yacht_weight)}
                        </div>
                    </div>
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">요트크기</span>
                            </label>
                            <div className="join">
                                <input type="number"
                                       step="0.001"
                                       placeholder="피트(ft)단위로 숫자만 입력해주세요."
                                       name="yacht_size"
                                       {...register("yacht_size", {
                                           required: "요트크기는 필수 입력입니다.",
                                       })}
                                       className={`input input-bordered join-item w-full ${G.errorStyle(errors.yacht_size)}`}
                                />
                                <button type="button" className="btn btn-ghost join-item">FT</button>
                            </div>
                            {G.errorDesc(errors.yacht_size)}
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">최대승선승무원수</span>
                            </label>
                            <input type="number"
                                   placeholder="해당 요트에 탈 수 있는 최대 승무원수(항해사 포함)를 선택해주세요."
                                   name="max_crew_members"
                                   {...register("max_crew_members", {
                                       required: "최대승선승무원수는 필수 입력입니다.",
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.max_crew_members)}`}
                            />
                            {G.errorDesc(errors.max_crew_members)}
                        </div>
                    </div>
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">최대승선인원수</span>
                            </label>
                            <input type="number"
                                   placeholder="숫자만 입력하세요.(승무원 포함)"
                                   name="embark_max_count"
                                   {...register("embark_max_count", {
                                       required: "최대승선인원수는 필수 입력입니다.",
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.embark_max_count)}`}
                            />
                            {G.errorDesc(errors.embark_max_count)}
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">요트ID</span>
                            </label>
                            <input type="text"
                                   placeholder="QR 리더기 로그인을 위한 아이디를 생성해주세요. (특수문자 제외, 최대 5자)"
                                   readOnly={yachtNo === 'create' ? false : true}
                                   name="login_id"
                                   {...register("login_id", {
                                       required: "요트ID는 필수 입력입니다.",
                                       validate: (value) => validateId(value),
                                       // minLength: {
                                       //     value: 2,
                                       //     message: "요트ID는 특수문자 제외, 최소 2글자 입니다."
                                       // },
                                       // maxLength: {
                                       //     value: 6,
                                       //     message: "요트ID는 특수문자 제외, 최대 6글자 입니다."
                                       // },
                                       // pattern: {
                                       //     value:
                                       //         /^[0-9a-zA-Z]{2,5}$/i,
                                       //     message: "요트ID 형식에 맞지 않습니다.(특수문자 제외, 최대 6자)",
                                       // },
                                   })}
                                   className={`input input-bordered join-item w-full ${G.errorStyle(errors.login_id)}`}
                            />
                            {G.errorDesc(errors.login_id)}
                        </div>
                    </div>
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">요트비밀번호</span>
                            </label>
                            <input type="text"
                                   placeholder="QR 리더기 로그인을 위한 비밀번호를 입력해주세요.(최대 10자)"
                                   name="password"
                                   {...register("password", {
                                       validate: (value) => validatePassword(value),
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.password)}`}
                            />
                            {G.errorDesc(errors.password)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-full">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">담당항해사</span>
                            </label>
                            <div className="md:join">
                                {mateList.map(({mate_no, mate_name},k) =>
                                    <label key={k} className="label inline-flex cursor-pointer justify-start md:justify-between">
                                        <input type="checkbox"
                                               name="mates"
                                               {...register('mates')}
                                               value={mate_no}
                                               onChange={(e) => handleYachtMates(e,k)}
                                               className="checkbox " />
                                        <span className="label-text mx-2">{mate_name}</span>
                                    </label>
                                )}
                            </div>
                            {G.errorDesc(errors.mates)}
                        </div>
                    </div>
                </div>
                <p className="my-2 text-purple-700 text-sm italic text-point">담당항해사를 선택하시면 승선 캘린더 작성 시 상위에 노출됩니다.</p>
            </div>
            <div className="paper">
                <h4 className="text-lg">
                    부가정보
                    <div className="divider my-1"></div>
                </h4>
                <div className="form-row">
                    <div className="md:basis-1/2">
                        <div className="form-control w-full mobile-row">

                            <label className="label">
                                <span className="label-text">노출여부</span>
                            </label>
                            <div className="join">
                                <label className="label inline-flex cursor-pointer justify-start md:justify-between">
                                    <input type="radio"
                                           name="open_yn"
                                           {...register('open_yn')}
                                           value="1"
                                           className="radio " />
                                    <span className="label-text mx-2"> 노출</span>
                                </label>
                                <label className="label inline-flex cursor-pointer justify-start md:justify-between">
                                    <input type="radio"
                                           name="open_yn"
                                           {...register('open_yn')}
                                           value="0"
                                           className="radio " />
                                    <span className="label-text mx-2">노출하지 않음</span>
                                </label>
                            </div>
                            {G.errorDesc(errors.open_yn)}
                        </div>
                        <p className="my-2 text-purple-700 text-sm italic text-point">승선 캘린더 작성 시 노출 여부를 선택합니다.</p>
                    </div>
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">요트CODE</span>
                            </label>
                            <input type="text"
                                   placeholder="요트ID"
                                   name="yacht_id"
                                   readOnly={true}
                                   {...register("yacht_id")}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.yacht_id)}`}
                            />
                            {G.errorDesc(errors.yacht_id)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">등록일</span>
                            </label>
                            <input type="text"
                                   placeholder="가입일"
                                   name="created_at"
                                   readOnly={true}
                                   {...register("created_at")}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.created_at)}`}
                            />
                            {G.errorDesc(errors.created_at)}
                        </div>
                    </div>
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">최종업데이트일</span>
                            </label>
                            <input type="text"
                                   placeholder="최종업데이트일"
                                   name="updated_at"
                                   readOnly={true}
                                   {...register("updated_at")}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.updated_at)}`}
                            />
                            {G.errorDesc(errors.updated_at)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-6 text-center px-6 md:px-1">
                <button type="submit" disabled={isSubmitting}
                        className="btn btn-primary w-full sm:max-w-sm">
                    {actionText}
                </button>
            </div>
        </form>
    )
}
