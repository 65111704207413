import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";

import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import {toast} from "store/toastSlice";
import {ArrowLongLeftIcon} from "@heroicons/react/24/outline";

const defaultValues = {
    sailing_no: '',
    targets: '',
    send_type: '',
    sms_message: ''
}

export default function SmsForm({show, users=null, SMSCODE=null, onFinish}) {

    const user = Auth.AuthCheck();
    const dispatch = useDispatch();
    const sailInfo = useSelector(state => state.Sail)
    const reserveInfo = useSelector(state => state.Reserve)

    const [targetList, setTargetList] = useState([]);

    const [sailNo, setSailNo] = useState();
    const [reserveNo, setReserveNo] = useState();

    const [actionText, setActionText] = useState('');
    const [smsCodeList, setSmsCodeList] = useState([]);

    const onSubmit = async (data) => {
        await G.sleep(1000);

        let targets = data.targets;
        let targetArray = [];
        let body = null;
        let url = null;
        if(!targets) {
            alert('선택된 대상자가 없습니다.');
            return;
        }
        if(data.send_type === 'Z099') {
            dispatch(toast({message : "문자 발송이 취소 되었습니다", kind : "error"}));
            onFinish();
            return;
        }
        // console.log('data.targets',Array.isArray(data.targets));
        if (Array.isArray(data.targets)) {
            // url = 'send/send_all_sms';
            targetArray = targets.map(function(t) {
                return {phone: targetList[t].boarding_phone, name: targetList[t].boarding_name
                    , qr_id: targetList[t].qr_id, uuid: targetList[t].uuid};
            });
        } else {
            targets = data.targets;
            targetArray = [{phone: targetList[targets].boarding_phone, name: targetList[targets].boarding_name
                    , qr_id: targetList[targets].qr_id, uuid: targetList[targets].uuid}];
        }
            body = {
                ref_no: sailNo,
                send_type: data.send_type,
                message: data.sms_message,
                targets: targetArray
            }
        console.log(body);
        // url = 'send/send_all_sms';
        // ApiSendSms(url, body);
        // await G.sleep(1000);
    };

    const {
        register,
        setValue,
        reset,
        handleSubmit,
        formState: { isSubmitting, errors }
    } = useForm({defaultValues});

    const setSmsMessage = (no) => {
        console.log(sailInfo);
        let message = G.ReplaceTemplate(smsCodeList[no].code_desc, sailInfo, reserveInfo);
        //console.log('users',users);
        setValue('sms_message', message);
    }

    const ApiSendSms = (url, body) => {
        // let url = "send/send_sms";
        // console.log('url',url);
        // console.log('body',body);
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                console.log("문자발송성공");
                dispatch(toast({message : "문자가 발송 되었습니다", kind : "success"}));
                onFinish();
            }
        );
    };

    const setSmsCode = () => {
        if(SMSCODE) {
            G.ApiSmsCodeInfo(SMSCODE)
                .then((codes) => {
                    setSmsCodeList(codes);
                });
        }
    }
    const setUsers = () => {
        if(users.length > 0) {
            setTargetList(users);
        }
    }

    useEffect(() => {
        console.log('Smsshow',show);
        if(show === true) {
            console.log('users',users);
            if(users.length <= 0) {
                onFinish();
                alert('송신 대상자가 없습니다.');
                return;
            }
            if(sailInfo) {
                setSailNo(sailInfo.sailing_no);
            }
            if(reserveInfo) {
                setReserveNo(reserveInfo.reserve_no);
            }
            setSmsCode();
            setUsers();
        } else {
            setTargetList([]);
            reset(defaultValues);
        }
    }, [show]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-3">

            <div className="app-bar-head">
                <div className="bar-left">
                    <button type="button" className="btn btn-ghost btn-xs px-0 mx-auto mobile-show"
                            onClick={() => onFinish()}>
                        <ArrowLongLeftIcon className="w-5 h-5 mx-1" />
                    </button>
                </div>
                <h4 className="bar-center text-lg">
                    {actionText} 메세지보내기
                </h4>
                <div className="bar-right"></div>
            </div>

            <div className="divider my-1"></div>

            <div className="form-row">
                <div className="md:basis-full">
                    <div className="form-control w-full">
                        <label className="label">
                            <span className="label-text">발송 메세지 종류</span>
                        </label>

                        {smsCodeList.map(({code, code_name},k) =>
                            <label key={k} className="label cursor-pointer justify-start">
                                <input type="radio"
                                       name="send_type"
                                       {...register('send_type', {
                                           required: "문자발송 여부는 필수 입력입니다.",
                                       })}
                                       value={code}
                                       onClick={()=>setSmsMessage(k)}
                                       className="radio " />
                                <span className="label-text mx-2">{code_name}</span>
                            </label>
                        )}
                        {G.errorDesc(errors.send_type)}
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div className="md:basis-full">
                    <div className="form-control w-full">
                        <label className="label">
                            <span className="label-text">대상자 목록 ({targetList.length }명)</span>
                        </label>

                        <table className="table">
                            <thead>
                            <tr>
                                <th></th>
                                <th>이름</th>
                                <th>연락처</th>
                            </tr>
                            </thead>
                            <tbody>
                            {targetList.map(({boarding_name, boarding_phone}, k) =>
                                <tr key={k}>
                                    <td>
                                    <input type="checkbox"
                                           name="targets"
                                           {...register('targets', {
                                               required: "대상자가 선택되지 않았습니다.",
                                           })}
                                           value={k}
                                           defaultChecked={true}
                                           className="checkbox " />
                                    </td>
                                    <td>{boarding_name}</td>
                                    <td>{boarding_phone}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        {G.errorDesc(errors.targets)}
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div className="md:basis-full">
                    <div className="form-control w-full">
                        <label className="label">
                            <span className="label-text">발송 메세지</span>
                        </label>
                        <textarea type="text"
                                  placeholder="발송 메세지"
                                  name="sms_message"
                                  {...register("sms_message")}
                                  className={`textarea textarea-bordered w-full`}
                        ></textarea>
                        {G.errorDesc(errors.sms_message)}
                    </div>
                </div>
            </div>

            <div className="mb-6 text-center px-6 md:px-1">
                <button type="submit" disabled={isSubmitting}
                        className="btn btn-primary w-full sm:max-w-sm">
                    {actionText} 발송
                </button>
            </div>
        </form>
    )
}
