import axios from "utils/axios";

const Api = async (body, apiUrl, token='') => {
    axios.defaults.headers.common['Authorization'] = token;
    // axios.defaults.headers['x-access-token'] = token;
    const response = await axios.post(apiUrl, body)
        .then((res) => res.data)
        .catch((err) => {
            console.log('ERROR', err);
            return err;
        });
    return  response;
};

export default Api;

// export const setAccessTokenInAxiosHeader: (accessToken: string) => void = (
//     accessToken: string,
// ) => {
//     axios.defaults.headers['x-access-token'] = accessToken;
// };
// try {
//     const { data, status } = await axiosInstance({
//         url: urlEndpoint,
//         method: 'POST',
//         data: inputData,
//         headers: { 'Content-Type': 'application/json' },
//     });
//     if (status === 400) {
//         const title = 'message' in data ? data.message : 'Unauthorized';
//         alert({ title, status: 'warning' });
//         return;
//     }
//     if ('myInfo' in data && 'accessToken' in data) {
//         alert('로그인 됐습니다.');
//         setAccessTokenInAxiosHeader(data.accessToken);
//     }
// } catch (errorResponse) {
//     console.error(errorResponse);
// }