import { createSlice } from "@reduxjs/toolkit";

export const toastSlice = createSlice({
    name: "Toast",
    initialState: {
        message: "",
        kind: "",
    },
    reducers: {
        toast: (state, action) => {
            state.message = action.payload.message;
            state.kind = action.payload.kind;
            // state.id = action.payload.id;
            return state;
        },
        clearToast: (state) => {
            state.message = "";
            state.kind = "";
            // state.id = null;
            return state;
        },
    },
});

export const { toast, clearToast } = toastSlice.actions;
export default toastSlice.reducer;