import React from 'react';
import * as Auth from 'components/Auth'
import {
    UserIcon,
    ArrowRightOnRectangleIcon,
    Bars3Icon
} from "@heroicons/react/24/outline"
import ToastMessage from "components/Toaster";

export default function AdminHeader() {
    const user = Auth.AuthCheck();

    return (
        <>
            <div className="navbar justify-between z-10 bg-white border-b border-gray-200">
                <div className="flex-1">
                    <label htmlFor="left-sidebar-drawer"
                            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg
                            xl:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
                        <span className="sr-only">Open Menu</span>
                        <Bars3Icon className="h-7 w-7" strokeWidth="2"/>
                    </label>
                </div>
                {user.isLogin ?
                    <div className="flex-none">
                        <a href="/user/account" className="btn btn-xs md:btn-sm btn-ghost font-normal">
                            <UserIcon className="h-5 w-5" strokeWidth="1"/>
                            {user.name}님
                        </a>
                        <a href="/user/logout" className="btn btn-xs md:btn-sm btn-ghost font-normal">
                            <ArrowRightOnRectangleIcon className="h-5 w-5" strokeWidth="1"/>
                            로그아웃
                        </a>
                    </div>
                    : null
                }
            </div>
            <ToastMessage />
        </>
    )
}
