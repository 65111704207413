import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import Datepicker from "react-tailwindcss-datepicker";
import dayjs from "dayjs";
import {
    ChatBubbleBottomCenterTextIcon,
    CalendarDaysIcon,
    EllipsisVerticalIcon,
} from "@heroicons/react/24/outline"

import { TbShip } from "react-icons/tb";
import { LiaClock } from "react-icons/lia";
import { LiaCalendar } from "react-icons/lia";
import { GrUserPolice } from "react-icons/gr";
import { LiaMinusSquareSolid } from "react-icons/lia";
import { LiaUserFriendsSolid } from "react-icons/lia";
import { LiaComment } from "react-icons/lia";

import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import {toast} from "store/toastSlice";
import SailForm from "components/SailForm";
import ReserveForm from "components/ReserveForm";
import SmsForm from "components/SmsForm";
import ModalBox from "components/ModalBox";
import {clearSail, setSail} from "store/sailSlice";
import {clearReserve} from "store/reserveSlice";
import chroma from "chroma-js";
import {saveCruiseList} from "store/cruiseSlice";

export default function Sailing() {
    const user = Auth.AuthCheck();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sailInfo = useSelector(state => state.Sail);
    const reserveInfo = useSelector(state => state.Reserve);

    const [selectedSailingNo, setSelectedSailingNo] = useState();
    const [sailFormMode, setSailFormMode] = useState();
    const [reserveFormMode, setReserveFormMode] = useState();

    const today = dayjs();
    const [dateValue, setDateValue] = useState({
        startDate: today.add(-6, "M").format('YYYY/MM/DD'),
        endDate: today.add(30, "d").format('YYYY/MM/DD')
    });
    const handleDatePickerValueChange = (newValue) => {
        setDateValue(newValue);
    }

    const [drawerSail, setDrawerSail] = useState(false);
    const [drawerReserve, setDrawerReserve] = useState(false);
    const [drawerSms, setDrawerSms] = useState(false);

    const [cruiseList, setYachtList] = useState([]);
    const [cruiseNo, setYachtNo] = useState('');
    const handleChangeYacht = (e) => {
        setYachtNo(e.target.value);
    };

    const [cruiseOptionList, setYachtOptionList] = useState([]);
    const handleSelectYacht = (val) => {
        setYachtNo(val);
    };

    const [mateList, setMateList] = useState([]);
    const [mateNo, setMateNo] = useState('');
    const handleChangeMate = (e) => {
        setMateNo(e.target.value);
    };

    const [sailingList, setSailingList] = useState([]);
    const ApiListSailing = (body) => {
        const url = "boarding/boarding_cruise_list";
        Api(body, url, user.token).then(
          (response) => {
              const result = response.result || response;
              if (result.code !== 0 ) {
                  if(result.code == 120)
                      navigate("/user/login");

                  alert(result.message);
                  return;
              }
              const dataCount = response.data.info.count;
              const dataList = response.data.list;
              setSailingList(dataList);
          }
        );
    };

    const handleSearchSailing = () => {
        const body = {
            cruise_no: cruiseNo,
            mate_no: mateNo,
            start_date: dateValue.startDate,
            end_date: dateValue.endDate,
        }
        ApiListSailing(body);
    }

    const ApiListYacht = (body) => {
        const url = "setting/setting_cruises_list";
        Api(body, url, user.token).then(
          (response) => {
              const result = response.result || response;
              if (result.code !== 0 ) {
                  if(result.code == 120)
                      navigate("/user/login");
                  alert(result.message);
                  return;
              }
              console.log(response.data)
              const cruiseCount = response.data.info.count;
              const cruiseList = response.data.list;
              setYachtList(cruiseList);
              setYachtOptionList(cruiseList.map(({cruise_no, cruise_name},k) => {
                  return {value: cruise_no, label: cruise_name, color: G.colorYacht[k]};
              } ));
              const cruiseListSliceItems = cruiseList.map((item,k) => {
                  return {...item, value: item.cruise_no, label: item.cruise_name, color: G.colorYacht[k]};
              } );
              dispatch(saveCruiseList({cruise_list: cruiseListSliceItems}));
          }
        );
    };
    const ApiListMate = (body) => {
        const url = "setting/setting_mate_list";
        Api(body, url, user.token).then(
          (response) => {
              const result = response.result || response;
              if (result.code !== 0 ) {
                  if(result.code == 120)
                      navigate("/user/login");
                  alert(result.message);
                  return;
              }
              let mateList = response.data.list;
              setMateList(mateList);
          }
        );
    };

    const [smsUserList, setSmsUserList] = useState([]);
    const ApiListReserveUserSmsUser = (body) => {
        const url = "boarding/boarding_reserve_user_list";
        Api(body, url, user.token).then(
          (response) => {
              const result = response.result || response;
              if (result.code !== 0 ) {
                  if(result.code == 120)
                      navigate("/user/login");
                  alert(result.message);
                  return;
              }
              // const dataCount = response.data.info.count;
              const dataList = response.data.list;
              if(dataList.length <= 0) {
                  // alert('송신 대상자가 없습니다.');
                  dispatch(toast({message : "송신 대상자가 없습니다.", kind : "error"}));
                  return;
              }
              setSmsUserList(dataList);
              setDrawerSms(true);
          }
        );
    };

    const handleSailFinish = () => {
        handleSearchSailing();
        handleSailClose();
    }
    const handleReserveFinish = () => {
        handleSearchSailing();
        handleReserveClose();
    }
    const handleSmsFinish = () => {
        handleSmsClose();
    }
    const handleSailClose = () => {
        setDrawerSail(false);
    }
    const handleReserveClose = () => {
        setDrawerReserve(false);
    }
    const handleSmsClose = () => {
        setDrawerSms(false);
    }
    const handleChangeSail = (e) => {
        console.log('e.target.checked',e.target.checked);
    }
    const handleChangeReserve = (e) => {
        console.log('e.target.checked',e.target.checked);
    }
    const handleChangeSms = (e) => {
        console.log('e.target.checked',e.target.checked);
    }

    const handleSendSmsSailAll = (no) => {
        const selected =  getSailInfo(no);
        dispatch(setSail(selected));
        ApiListReserveUserSmsUser({sailing_no: selected.sailing_no, reserve_no: 0});
    }
    const handleCreateSail = () => {
        const elem = document.activeElement;
        if(elem){
            elem?.blur();
        }
        setSailFormMode('create');
        dispatch(clearSail(sailInfo));
        setDrawerSail(true);
    }
    const handleCreateReserve = (no) => {
        const elem = document.activeElement;
        if(elem){
            elem?.blur();
        }
        const selected =  getSailInfo(no);
        setReserveFormMode('create');
        dispatch(setSail(selected));
        dispatch(clearReserve(reserveInfo));
        setDrawerReserve(true);
    }
    const handleModifySail = (no) => {
        const elem = document.activeElement;
        if(elem){
            elem?.blur();
        }
        setSailFormMode('modify');
        const selected = getSailInfo(no);
        dispatch(setSail(selected));
        setDrawerSail(true);
    }
    const getSailingInfo = (no) => {
        const elem = document.activeElement;
        if(elem){
            elem?.blur();
        }
        const selected = getSailInfo(no);
        dispatch(setSail(selected));

        navigate(`/manage/boarding/reserve/${selected.sailing_no}`,{state: {sail: selected}});
    }
    const handleDeleteSail = (no) => {
        const elem = document.activeElement;
        if(elem){
            elem?.blur();
        }
        const selected = getSailInfo(no);
        setSelectedSailingNo(selected.sailing_no);
        window.sail_delete_modal.showModal();
    }
    const handleDeleteSailModal = (object) => {
        handleSailClose();
        const selected = object;
        setSelectedSailingNo(selected.sailing_no);
        window.sail_delete_modal.showModal();
    }
    const ApiDeleteSail = () => {
        const body = {sailing_no: selectedSailingNo};
        const url = "boarding/boarding_sailing_delete";
        Api(body, url, user.token).then(
          (response) => {
              const result = response.result || response;
              if (result.code !== 0 ) {
                  if(result.code == 120)
                      navigate("/user/login");

                  alert(result.message);
                  return;
              }
              dispatch(toast({message : "삭제되었습니다", kind : "success"}));
              handleSearchSailing();
          }
        );
    }

    const getSailStatus = (k) => {
        return G.getSailStatusStyleText(sailingList[k]);
    }

    const getMenu = (k) => {
        // 출항전 : 모든 기능 사용 가능 : 단, 최대승선인원수(선원수포함) 초과 시 예약 등록 기능 사용 불가
        // 항해중/항해완료/미출항 : 예약 등록 기능 / 항해정보수정 기능 / 삭제 기능 사용 불가
        let menuGroup1 = true;
        // if(sailingList[k].status_cd === 'SL03')
        //     menuGroup1 = false;
        // if(sailingList[k].status_cd === 'SL02')
        //     menuGroup1 = false;

        if(G.getSailStatusText(sailingList[k]) === '출항전')
            menuGroup1 = true;
        else
            menuGroup1 = false;

        return (
          <div className="join">
              <div className="tooltip" data-tip="단체메시지보내기">
                  {
                      true === false ?
                        <button type="button" className="btn btn-sm btn-circle bg-transparent opacity-30 p-0">
                            <ChatBubbleBottomCenterTextIcon className="w-5 h-5 mx-1" />
                        </button>
                        :
                        <button type="button" className="btn btn-sm btn-circle btn-ghost p-0"
                                onClick={() => handleSendSmsSailAll(k)}>
                            <ChatBubbleBottomCenterTextIcon className="w-5 h-5 mx-1" />
                        </button>
                  }

              </div>
              <div className="tooltip" data-tip="예약등록">
                  {
                      menuGroup1 === false ?
                        <button type="button" className="btn btn-sm btn-circle bg-transparent opacity-30 p-0">
                            <CalendarDaysIcon className="w-5 h-5 mx-1" />
                        </button>
                        :
                        <button type="button" className="btn btn-sm btn-circle btn-ghost p-0"
                                onClick={() => handleCreateReserve(k)}>
                            <CalendarDaysIcon className="w-5 h-5 mx-1" />
                        </button>
                  }

              </div>
              <div className="dropdown dropdown-left  z-10">
                  <label tabIndex="0" className="btn btn-sm btn-circle btn-ghost p-0">
                      <EllipsisVerticalIcon className="w-5 h-5 mx-1" />
                  </label>
                  <ul tabIndex={0} className="dropdown-content p-2 shadow menu z-20 bg-base-100 rounded-box w-52">
                      {
                          menuGroup1 === false ?
                            <li className="disabled"><p>항해정보수정</p></li>
                            :
                            <li><p onClick={() => handleModifySail(k)}>항해정보수정</p></li>
                      }
                      <div className="divider my-0"></div>
                      <li><p onClick={() => getSailingInfo(k)}>예약&승선자 정보 확인</p></li>
                      {
                          menuGroup1 === false ?
                            <li className="disabled"><p>삭제</p></li>
                            :
                            <li><p className="text-red-600" onClick={() => handleDeleteSail(k)}>삭제</p></li>
                      }
                  </ul>
              </div>
          </div>
        );
    }
    const getMenuMobile = (k) => {
        let menuGroup1 = true;

        if(G.getSailStatusText(sailingList[k]) === '출항전')
            menuGroup1 = true;
        else
            menuGroup1 = false;

        return (
          <>
              <div className="w-full flex gap-2 items-center mt-2 mb-4">
                  {
                      true === false ?
                        <button type="button" className="btn btn-default btn-xs w-full flex-1 mx-auto p-2 h-10" disabled="disabled">
                            단체메시지보내기
                        </button>
                        :
                        <button type="button" className="btn btn-default btn-xs w-full flex-1 mx-auto p-2 h-10"
                                onClick={() => handleSendSmsSailAll(k)}>
                            단체메시지보내기
                        </button>
                  }

                  {
                      menuGroup1 === false ?
                        <button type="button" className="btn btn-neutral btn-xs w-full flex-1 mx-auto p-2 h-10" disabled="disabled">
                            예약등록
                        </button>
                        :
                        <button type="button" className="btn btn-neutral btn-xs w-full flex-1 mx-auto p-2 h-10"
                                onClick={() => handleCreateReserve(k)}>
                            예약등록
                        </button>
                  }
              </div>
              <div className="w-full flex gap-2 items-center">
                  <button type="button" className="btn btn-primary btn-xs w-full flex-1 mx-auto p-2 h-16 leading-5"
                          onClick={() => getSailingInfo(k)}>
                      예약&승선자<br />정보 확인
                  </button>
                  {
                      menuGroup1 === false ?
                        <button type="button" className="btn btn-default btn-xs w-full flex-1 mx-auto p-2 h-16" disabled="disabled">
                            항해정보수정
                        </button>
                        :
                        <button type="button" className="btn btn-default btn-xs w-full flex-1 mx-auto p-2 h-16"
                                onClick={() => handleModifySail(k)}>
                            항해정보수정
                        </button>
                  }

                  {
                      menuGroup1 === false ?
                        <button type="button" className="btn btn-error btn-xs w-full flex-1 mx-auto p-2 h-16" disabled="disabled">
                            삭제
                        </button>
                        :
                        <button type="button" className="btn btn-error btn-xs w-full flex-1 mx-auto p-2 h-16"
                                onClick={() => handleDeleteSail(k)}>
                            삭제
                        </button>
                  }
              </div>
          </>
        );
    }
    function getStyleYacht(no, name, cruiseList) {
        const index = cruiseList.findIndex(cruise => cruise.cruise_no === no);
        console.log('index',index);
        const color = chroma(G.colorYacht[index]);
        return <span className='cruise-name' style={{backgroundColor: color.alpha(0.2).css(), color: color.alpha(1.0).css()}}>{name}</span>;
    }

    const getSailInfo = (no) => {
        const selected = sailingList[no];
        console.log('selected', selected);
        return selected;
    }

    useEffect(() => {
        ApiListMate();
        ApiListYacht();
        handleSearchSailing();
    }, []);

    return (
      <>
          <main className="content">
              <div className="paper">
                  <div className="sub-title">
                      <span className="text-gradient">승선캘린더관리</span>
                  </div>
              </div>
              <div className="paper">
                  <div className="form-row">
                      <div className="md:basis-1/2">
                          <div className="form-control w-full">
                              <label className="label">
                                  <span className="label-text">크루즈 선택</span>
                              </label>
                              {/*<SelectYacht*/}
                              {/*    defaultValue={cruiseNo || ''}*/}
                              {/*    onChange={handleSelectYacht}*/}
                              {/*    cruiseoption={cruiseOptionList}*/}
                              {/*    placeholder="전체"*/}
                              {/*/>*/}
                              <select
                                id="cruise_no"
                                value={cruiseNo || ''}
                                onChange={handleChangeYacht}
                                className="select select-bordered w-full">
                                  <option value="">전체</option>
                                  {cruiseList.map(({cruise_no, cruise_name}) =>
                                    <option key={cruise_no} value={cruise_no}>
                                        {cruise_name}
                                    </option>
                                  )}
                              </select>
                          </div>
                      </div>
                      <div className="md:basis-1/2">
                          <div className="form-control w-full">
                              <label className="label">
                                  <span className="label-text">검색기준</span>
                              </label>
                              <select
                                id="mate_no"
                                value={mateNo || ''}
                                onChange={handleChangeMate}
                                className="select select-bordered w-full">
                                  <option value="">전체</option>
                                  {mateList.map(({mate_no, mate_name}) =>
                                    <option key={mate_no} value={mate_no}>
                                        {mate_name}
                                    </option>
                                  )}
                              </select>
                          </div>
                      </div>
                  </div>
                  <div className="form-row">
                      <div className="md:basis-full">
                          <div className="form-control w-full">
                              <label className="label">
                                  <span className="label-text">조회기간</span>
                              </label>
                              <div className="form-row">
                                  <div className="md:basis-4/5">
                                      <Datepicker
                                        i18n={"ko"}
                                        primaryColor={"purple"}
                                        inputClassName="input input-bordered w-full"
                                        containerClassName="relative"
                                        toggleClassName="absolute rounded-r-lg right-0 h-full px-3"
                                        value={dateValue}
                                        displayFormat={"YYYY/MM/DD"}
                                        onChange={handleDatePickerValueChange}
                                        showShortcuts={false}
                                      />
                                  </div>
                                  <div className="md:basis-1/5 right-button">
                                      <button type="button"
                                              onClick={handleSearchSailing}
                                              className="btn btn-outline w-full">
                                          검색
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="paper">
                  <div className="form-row">
                      <div className="mb-6 md:mb-0 md:basis-full text-right">
                          <label
                            onClick={handleCreateSail}
                            className="drawer-button btn btn-primary w-full md:w-1/5">
                              항해 회차 생성
                          </label>
                      </div>
                  </div>
                  <div className="table-wrapper">
                      <div className="div-table w-full">
                          <div className="div-table-row div-table-header">
                              <div className="div-table-cell">항해회차ID</div>
                              <div className="div-table-cell">크루즈명</div>
                              <div className="div-table-cell">항해예정일시</div>
                              {/*<div className="div-table-cell">항해사</div>*/}
                              <div className="div-table-cell">예약건수</div>
                              <div className="div-table-cell">승선작성/예약자수</div>
                              <div className="div-table-cell">항해완료여부</div>
                              <div className="div-table-cell">기능</div>
                          </div>
                          {sailingList.map(({created_at,mate_name,mate_no,reserve_count,reserve_total_user_count,boarding_user_count,
                                                boarding_writer_count,sailing_no,sailing_id,start_at,status_cd,status_name,cruise_name,cruise_no}, k) =>
                            <div key={k} className="div-table-row cursor-pointer">
                                <div className="div-table-cell" onClick={() => getSailingInfo(k)}>
                                    <LiaMinusSquareSolid className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                    {sailing_id}
                                </div>
                                {/*<div className="div-table-cell" onClick={() => getSailingInfo(k)}><span className={G.getStyleYacht(cruise_no,cruiseList)}>{cruise_name}</span></div>*/}
                                <div className="div-table-cell" onClick={() => getSailingInfo(k)}>
                                    <TbShip className="inline-block h-5 w-5 mobile-show" strokeWidth="1" />
                                    {G.getStyleYacht(cruise_no,cruise_name,cruiseList)}
                                </div>
                                {/*<div className="div-table-cell" onClick={() => getSailingInfo(k)}>{getStyleYacht(cruise_no,cruise_name,cruiseList)}</div>*/}
                                <div className="div-table-cell" onClick={() => getSailingInfo(k)}>
                                    <LiaClock className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                    {start_at} <span className="mobile-show">출항</span>
                                </div>
                                {/*<div className="div-table-cell" onClick={() => getSailingInfo(k)}>*/}
                                {/*    <GrUserPolice className="inline-block h-4 w-4 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />*/}
                                {/*    {mate_name} <span className="mobile-show ml-2">항해사</span>*/}
                                {/*</div>*/}
                                <div className="div-table-cell" onClick={() => getSailingInfo(k)}>
                                    <LiaCalendar className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                    {reserve_count}
                                    <span className="mobile-show ml-2">건 예약</span>
                                </div>
                                <div className="div-table-cell" onClick={() => getSailingInfo(k)}>
                                    <LiaUserFriendsSolid className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                    <span className="mobile-show">승선작성</span> {boarding_user_count}<span className="mobile-show">명</span>
                                    / <span className="mobile-show">예약지수</span> {reserve_total_user_count}<span className="mobile-show">명</span>
                                </div>
                                <div className="div-table-cell" onClick={() => getSailingInfo(k)}>
                                    <LiaComment className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                    {getSailStatus(k)}
                                </div>
                                <div className="div-table-cell cell-mobile-hide">
                                    {getMenu(k)}
                                </div>
                                <div className="div-table-cell cell-mobile-show">
                                    {getMenuMobile(k)}
                                </div>
                            </div>
                          )}
                      </div>
                      {sailingList.length < 1 ?
                        <div className="div-no-record w-full">
                            검색된 정보가 없습니다.
                        </div>
                        : null
                      }
                  </div>
              </div>

              <dialog id="sail_delete_modal" className="modal">
                  <ModalBox title="삭제하시겠습니까?"
                            action="삭제"
                            callBack={ApiDeleteSail}>
                      회차 삭제 시 회차내 예약정보 복구가 불가합니다.<br />
                      그래도 삭제하시려면 삭제 버튼을 클릭해주세요.
                  </ModalBox>
              </dialog>
              <div className="drawer drawer-end">
                  <input id="drawer-sail" type="checkbox" onChange={handleChangeSail} checked={drawerSail} className="drawer-toggle" />
                  <div className="drawer-side z-20">
                      <label onClick={handleSailClose} className="drawer-overlay"></label>
                      <div className="w-full md:w-1/3 min-h-full py-0 px-1 rounded-lg shadow-md bg-base-100">
                          <div className="paper-modal overscroll-y-auto">
                              <SailForm cruise_list={cruiseList} mate_list={mateList} mode={sailFormMode} show={drawerSail}
                                        onFinish={handleSailFinish} onDelete={handleDeleteSailModal} />
                          </div>
                      </div>
                  </div>
              </div>
              <div className="drawer drawer-end">
                  <input id="drawer-reserve" type="checkbox" onChange={handleChangeReserve} checked={drawerReserve} className="drawer-toggle" />
                  <div className="drawer-side z-20">
                      <label onClick={handleReserveClose} className="drawer-overlay"></label>
                      <div className="w-full md:w-1/3 min-h-full py-0 px-1 rounded-lg shadow-md bg-base-100">
                          <div className="paper-modal overscroll-y-auto">
                              <ReserveForm mode={reserveFormMode} show={drawerReserve}
                                           SMSCODE={G.RESERVE_CREATE} onFinish={handleReserveFinish} />
                          </div>
                      </div>
                  </div>
              </div>
              <div className="drawer drawer-end">
                  <input id="drawer-sms" type="checkbox" onChange={handleChangeSms} checked={drawerSms} className="drawer-toggle" />
                  <div className="drawer-side z-20">
                      <label onClick={handleSmsClose} className="drawer-overlay"></label>
                      <div className="w-full md:w-1/3 min-h-full py-0 px-1 rounded-lg shadow-md bg-base-100">
                          <div className="paper-modal overscroll-y-auto">
                              <SmsForm users={smsUserList} SMSCODE={G.SAIL_GROUP} show={drawerSms}
                                       onFinish={handleSmsFinish} />
                          </div>
                      </div>
                  </div>
              </div>
          </main>
      </>
    )
}
