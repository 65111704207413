import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux'

import Api from "utils/Api";
import * as G from "utils/utils";
import * as Auth from "components/Auth";
import ModalBox from "components/ModalBox";
import MateForm from "components/MateForm";
import {toast} from "store/toastSlice";

export default function MateInfo() {
    const user = Auth.AuthCheck();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [mateList, setMateList] = useState([]);
    const [mateNo, setMateNo] = useState('');

    const handleChangeMate = (e) => {
        setMateNo(e.target.value);
    };
    const handleCreateMate = (e) => {
        setMateNo('create');
    }
    const handleFinishMateForm = () => {
        ApiListMate();
        setMateNo('');
        dispatch(toast({message : "저장되었습니다", kind : "success"}));
    }
    const handleDeleteMate = () => {
        if(mateNo)
            window.mate_delete_modal.showModal();
    }
    const ApiDeleteMate = () => {
        if(mateNo) {
            const body = {mate_no: mateNo};
            const url = "setting/setting_mate_delete";
            Api(body, url, user.token).then(
                (response) => {
                    const result = response.result || response;
                    if (result.code !== 0) {
                        if (result.code == 120)
                            navigate("/user/login");
                        alert(result.message);
                        return;
                    }
                    dispatch(toast({message : "삭제되었습니다", kind : "success"}));
                    ApiListMate();
                    setMateNo('');
                }
            );
        }
    }

    const ApiListMate = (body) => {
        const url = "setting/setting_mate_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                let mateList = response.data.list;
                // console.log("MateList",mateList);
                setMateList(mateList);
            }
        );
    };

    useEffect(() => {
        ApiListMate();
    }, []);

    return (
        <>
            <main className="content">

                <div className="paper">
                    <div className="sub-title">
                        <span className="text-gradient">항해사정보</span>
                    </div>
                </div>

                <div className="paper">
                    <h4 className="text-lg">
                        항해사선택
                        <div className="divider my-1"></div>
                    </h4>

                    <div className="form-row">
                        <div className="md:basis-1/2">
                            <div className="form-control w-full">
                                <select
                                    id="mate_no"
                                    value={mateNo || ''}
                                    onChange={handleChangeMate}
                                    className="select select-bordered w-full sm:max-w-sm">
                                    <option value="">항해사선택</option>
                                    {mateList.map(({mate_no, mate_name}) =>
                                        <option key={mate_no} value={mate_no}>
                                            {mate_name}
                                        </option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="md:basis-1/2 right-button">
                            <button type="button"
                                    onClick={handleCreateMate}
                                    className="btn btn-primary w-full sm:max-w-sm">
                                항해사등록
                            </button>
                        </div>
                    </div>
                </div>
                {mateNo ?
                    <MateForm mate_no={mateNo}
                                onFinish={handleFinishMateForm} onDelete={handleDeleteMate}
                    />
                :
                    <div className="px-6 py-12 lg:px-8 my-4 rounded-lg shadow-md bg-base-100 space-y-6 flex justify-center items-center text-center">
                        확인하실 항해사를 선택하시거나,<br />
                        새로운 요트를 등록해주세요.
                    </div>
                }
                <dialog id="mate_delete_modal" className="modal">
                    <ModalBox title="삭제하시겠습니까?"
                              action="삭제"
                              callBack={ApiDeleteMate}>
                        회차 삭제 시 회차내 예약정보 복구가 불가합니다.<br />
                        그래도 삭제하시려면 삭제 버튼을 클릭해주세요.
                    </ModalBox>
                </dialog>
            </main>
        </>
    )
}