import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";

import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import {toast} from "store/toastSlice";

const defaultValues = {
    mate_name: '',
    birth: '',
    gender: '',
    phone: '',
    email: '',
    open_yn: '1',
    mate_id: '',
    created_at: '',
    updated_at: '',
}

export default function MateForm({mate_no, onFinish, onDelete}) {
    const user = Auth.AuthCheck();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [mateNo, setMateNo] = useState();
    const [actionText, setActionText] = useState();

    const onSubmit = async (data) => {
        await G.sleep(1000);
        ApiModifyMate(data);
        await G.sleep(1000);
    };

    const {
        register,
        reset,
        handleSubmit,
        formState: { isSubmitting, errors }
    } = useForm({defaultValues});

    const handleDelete = () => {
        onDelete(mateNo);
    }

    useEffect(() => {
        setMateNo(mate_no);
        // console.log(mate_no);
    }, [mate_no]);

    useEffect(() => {
        if(mateNo === 'create') {
            setActionText('신규등록');
            reset(defaultValues);
        } else if(mateNo) {
            setActionText('정보수정');
            ApiReadMate(mateNo);
        }
    }, [mateNo]);

    const ApiReadMate = (mate_no) => {
        let body = {
            mate_no : mate_no
        }
        const url = "setting/setting_mate_info";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                let mateInfo = response.data.info;
                // console.log("mateInfo",mateInfo);
                const {mate_name,birth,gender,phone,email,open_yn,mate_no,mate_id,created_at,updated_at} = mateInfo;
                reset({
                    mate_name: mate_name,
                    birth: birth,
                    gender: gender,
                    phone: phone,
                    email: email,
                    open_yn: open_yn,
                    mate_id: mate_id,
                    created_at: created_at,
                    updated_at: updated_at,
                });
            }
        );
    };

    const ApiModifyMate = (body) => {
        let url = "preference/company";
        if(mateNo === 'create')
            url = "setting/setting_mate_save";
        else
            url = "setting/setting_mate_update";

        if(mateNo)
            body = {...body, mate_no: mateNo}
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                console.log("정보업데이트성공");
                onFinish();
            }
        );
    };

    const ApiMateResetPassword= () => {
        if(mateNo) {
            const body = {mate_no: mateNo};
            const url = "setting/setting_mate_pass_reset";
            Api(body, url, user.token).then(
                (response) => {
                    const result = response.result || response;
                    if (result.code !== 0) {
                        if (result.code == 120)
                            navigate("/user/login");
                        alert(result.message);
                        return;
                    }
                    dispatch(toast({message : "초기화되었습니다", kind : "success"}));
                }
            );
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="paper">
                <h4 className="text-lg">
                    항해사정보
                    <div className="divider my-1"></div>
                </h4>

                <div className="form-row">
                    <div className="w-full">
                        <div className="form-control w-full mobile-row">
                            <label className="label">
                                <span className="label-text">항해사성명</span>
                            </label>
                            <input type="text"
                                   placeholder="항해사성명"
                                   name="mate_name"
                                   {...register("mate_name", {
                                       required: "항해사성명은 필수 입력입니다.",
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.mate_name)}`}
                            />
                            {G.errorDesc(errors.mate_name)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">생년월일</span>
                            </label>
                            <input type="text"
                                   placeholder="ex) 19800101"
                                   name="birth"
                                   maxLength="8"
                                   {...register("birth", {
                                       required: "생년월일은 필수 입력입니다.",
                                       pattern: {
                                           value: /[0-9]{8}/,
                                           message: "생년월일은 형식에 맞지 않습니다. ex) 19800101",
                                       },
                                       maxLength: {
                                           value: 11,
                                           message: "숫자 최대 8자 입니다."
                                       },
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.birth)}`}
                            />
                            {G.errorDesc(errors.birth)}
                        </div>
                    </div>
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">성별</span>
                            </label>
                            <div className="join">
                                <label className="label cursor-pointer">
                                    <input type="radio"
                                           name="gender"
                                           {...register('gender', {
                                               required: "성별 선택은 필수 입력입니다.",
                                           })}
                                           value="M"
                                           className="radio " />
                                    <span className="label-text mx-2"> 남</span>
                                </label>
                                <label className="label cursor-pointer">
                                    <input type="radio"
                                           name="gender"
                                           {...register('gender', {
                                               required: "성별 선택은 필수 입력입니다.",
                                           })}
                                           value="F"
                                           className="radio " />
                                    <span className="label-text mx-2"> 여</span>
                                </label>
                            </div>
                            {G.errorDesc(errors.gender)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">휴대폰번호</span>
                            </label>
                            <input type="text"
                                   placeholder="-를 제외하고 숫자만 입력해주세요. ex)01012341234"
                                   name="phone"
                                   maxLength="11"
                                   {...register("phone", {
                                       required: "휴대폰번호는 필수 입력입니다.",
                                       pattern: {
                                           value: /01[016789][0-9]{3,4}[0-9]{4}/,
                                           message: "휴대폰번호 형식에 맞지 않습니다.",
                                       },
                                       maxLength: {
                                           value: 11,
                                           message: "숫자 최대 11자 입니다."
                                       },
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.phone)}`}
                            />
                            {G.errorDesc(errors.phone)}
                        </div>
                    </div>
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">이메일주소</span>
                            </label>
                            <input type="text"
                                   placeholder="이메일주소"
                                   name="email"
                                   {...register("email", {
                                       pattern: {
                                           value:
                                               /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                                           message: "이메일 형식에 맞지 않습니다.",
                                       },
                                   })}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.email)}`}
                            />
                            {G.errorDesc(errors.email)}
                        </div>
                    </div>
                </div>
                {mateNo !== 'create' ?
                <div className="form-row">
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full mt-8 md:mt-auto">
                            <button type="button" className="btn btn-outline w-full md:max-w-xs"
                                    onClick={ApiMateResetPassword}>
                                비밀번호 초기화
                            </button>
                        </div>
                    </div>
                </div>
                    :
                    null
                }

                <p className="my-2 text-purple-700 text-sm italic text-point">
                    항해사 로그인 ID는 휴대폰 번호가되며, 최초 비밀번호는 휴대폰 번호 뒤 4자리가 됩니다.
                </p>
            </div>
            <div className="paper">
                <h4 className="text-lg">
                    부가정보
                    <div className="divider my-1"></div>
                </h4>
                <div className="form-row">
                    <div className="md:basis-1/2">
                        <div className="form-control w-full mobile-row">
                            <label className="label">
                                <span className="label-text">노출여부</span>
                            </label>
                            <div className="join">
                                <label className="label cursor-pointer">
                                    <input type="radio"
                                           name="open_yn"
                                           {...register('open_yn')}
                                           value="1"
                                           className="radio " />
                                    <span className="label-text mx-2"> 노출</span>
                                </label>
                                <label className="label cursor-pointer">
                                    <input type="radio"
                                           name="open_yn"
                                           {...register('open_yn')}
                                           value="0"
                                           className="radio " />
                                    <span className="label-text mx-2">노출하지 않음</span>
                                </label>
                            </div>
                            {G.errorDesc(errors.open_yn)}
                        </div>
                        <p className="my-2 text-purple-700 text-sm italic text-point">* 승선 캘린더 작성 시 노출 여부를 선택합니다.</p>
                    </div>
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">항해사CODE</span>
                            </label>
                            <input type="text"
                                   placeholder="항해사ID"
                                   name="mate_id"
                                   readOnly={true}
                                   {...register("mate_id" )}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.mate_id)}`}
                            />
                            {G.errorDesc(errors.mate_id)}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">등록일</span>
                            </label>
                            <input type="text"
                                   placeholder="가입일"
                                   name="created_at"
                                   readOnly={true}
                                   {...register("created_at")}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.created_at)}`}
                            />
                            {G.errorDesc(errors.created_at)}
                        </div>
                    </div>
                    <div className="mobile-row md:basis-1/2">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">최종업데이트일</span>
                            </label>
                            <input type="text"
                                   placeholder="최종업데이트일"
                                   name="updated_at"
                                   readOnly={true}
                                   {...register("updated_at")}
                                   className={`input input-bordered w-full ${G.errorStyle(errors.updated_at)}`}
                            />
                            {G.errorDesc(errors.updated_at)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-6 flex justify-center gap-4 text-center">
                <button type="submit" disabled={isSubmitting}
                        className="btn btn-primary btn-wide max-w-xs">
                    {actionText}
                </button>
                {mateNo !== 'create' ?
                    <button type="button" className="btn btn-outline"
                            onClick={handleDelete}>
                        삭제
                    </button>
                    :
                    null
                }
            </div>
        </form>
    )
}
