import React, {useEffect, useState, useRef} from "react";
import Datepicker from "react-tailwindcss-datepicker";

import Api from "utils/Api";
import * as Auth from "components/Auth";
import {useNavigate} from "react-router-dom";
import {ChatBubbleBottomCenterTextIcon, DocumentChartBarIcon, PrinterIcon} from "@heroicons/react/24/outline";
import {GrUserPolice} from "react-icons/gr";
import {LiaClock, LiaUserFriendsSolid} from "react-icons/lia";

import {useDispatch, useSelector} from "react-redux";
import RecDetail from "components/RecDetail";
import {setRecord} from "store/recordSlice";

export default function Record() {
    const user = Auth.AuthCheck();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dateValue, setDateValue] = useState({
        startDate: null,
        endDate: null
    });
    const recordInfo = useSelector(state => state.Record);
    const handleDatePickerValueChange = (newValue) => {
        setDateValue(newValue);
    }

    const [recordList, setRecordList] = useState([]);
    const ApiListRecord = (body) => {
        const url = "record/list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                const dataCount = response.data.info.count;
                const dataList = response.data.list;
                setRecordList(dataList);

            }
        );
    };
    const [drawerReport, setDrawerReport] = useState(false);

    const handleChangeReport = (e) => {
        console.log('e.target.checked',e.target.checked);
    }

    const handleSearchBoarding = () => {
        const body = {
            start_at: dateValue.startDate,
            end_at: dateValue.endDate,
        }
        ApiListRecord(body);
    };

    const getSailInfo = (no) => {
        const selected = recordList[no];
        return selected;
    }

    const handleReport = (no) => {
        const selected =  getSailInfo(no);
        dispatch(setRecord(selected));
        setDrawerReport(true);
    }

    const handleReportClose = () => {
        setDrawerReport(false);
    }

    const openWindow = () => {
        const url = `/manage/boarding/print/pass/${recordInfo.resultId}`;
        window.open(url, "_blank", "width=620, height=700, noopener, noreferrer");
    }


    useEffect(() => {

    }, []);


    return (
        <>
            <main className="content">
                <div className="paper">
                    <div className="sub-title">
                        <span className="text-gradient">녹음파일관리</span>
                    </div>
                </div>

                <div className="paper">
                    <div className="form-row">
                        <div className="md:basis-full">
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">조회기간</span>
                                </label>
                                <div className="form-row">
                                    <div className="md:basis-4/5">
                                        <Datepicker
                                            i18n={"ko"}
                                            primaryColor={"purple"}
                                            inputClassName="input input-bordered w-full"
                                            containerClassName="relative"
                                            toggleClassName="absolute rounded-r-lg right-0 h-full px-3"
                                            value={dateValue}
                                            displayFormat={"YYYY/MM/DD"}
                                            onChange={handleDatePickerValueChange}
                                            showShortcuts={false}
                                        />
                                    </div>
                                    <div className="md:basis-1/5 right-button">
                                        <button type="button"
                                                onClick={handleSearchBoarding}
                                                className="btn btn-outline w-full">
                                            검색
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="paper">
                    <div className="table-wrapper my-4">
                        <div className="div-table w-full">
                            <div className="div-table-row div-table-header">
                                <div className="div-table-cell">업로드 일자</div>
                                <div className="div-table-cell">내용</div>
                                <div className="div-table-cell">파일명</div>
                            </div>
                            {recordList.map(({
                                                 resultId, txt, regdate, createdTime, managerId,filename, result
                                               }, k) =>
                                <div key={k} className="div-table-row">
                                    <div className="div-table-cell">
                                        <GrUserPolice className="inline-block h-4 w-4 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                        {createdTime} <span className="mobile-show ml-2">항해사</span><
                                        /div>
                                    <div className="div-table-cell">
                                        <LiaUserFriendsSolid className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                        {txt.substr(0,50)}
                                    </div>
                                    <div className="div-table-cell">
                                        <LiaClock className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                        {filename}
                                    </div>
                                    <div className="join mobile-hide">
                                        <button type="button"
                                                className="btn btn-sm btn-circle btn-ghost p-0"
                                                onClick={() => handleReport(k)}
                                                >
                                            <DocumentChartBarIcon className="w-5 h-5 mx-1" />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                            {recordList.length < 1 ?
                                <div className="div-no-record w-full">
                                    검색된 정보가 없습니다.<br />
                                </div>
                                : null
                            }
                    </div>
                </div>

                <div className="drawer drawer-end">
                    <input id="drawer-report" type="checkbox" onChange={handleChangeReport} checked={drawerReport} className="drawer-toggle" />
                    <div className="drawer-side z-20">
                        <label onClick={handleReportClose} className="drawer-overlay"></label>
                        <div className="w-11/12 md:w-1/2 min-h-full py-0 px-1 rounded-lg shadow-md bg-base-100">
                            <div className="paper-modal overscroll-y-auto">
                                <RecDetail sail={recordInfo} openWindow={openWindow} />
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </>
    );
}
