import CompanyForm from "components/CompanyForm";

export default function CompanyInfo() {
    return (
        <>
            <main className="content">

                <div className="paper">
                    <div className="sub-title">
                        <span className="text-gradient">업체정보</span>
                    </div>
                </div>

                <CompanyForm />

            </main>
        </>
    )
}