import { createSlice } from "@reduxjs/toolkit";

export const reserveSlice = createSlice({
    name: "Reserve",
    initialState: {
        reserve_no: '',
        reserve_id: '',
        sailing_no: '',
        yacht_no: '',
        cruise_name: '',
        mate_no: '',
        mate_name: '',
        created_at: '',
        uuid: '',
        reserve_name: '',
        reserve_phone: '',
        reserve_user_count: '',
        reserve_status_cd: '',
        reserve_status_name: '',
        boarding_writer_count: '',
        boarding_user_count: '',
        emergency_contact: '',
        memo: '',
    },
    reducers: {
        setReserve: (state, action) => {
            state.reserve_no = action.payload.reserve_no;
            state.reserve_id = action.payload.reserve_id;
            state.sailing_no = action.payload.sailing_no;
            state.yacht_no = action.payload.yacht_no;
            state.cruise_name = action.payload.cruise_name;
            state.mate_no = action.payload.mate_no;
            state.mate_name = action.payload.mate_name;
            state.created_at = action.payload.created_at;
            state.uuid = action.payload.uuid;
            state.reserve_name = action.payload.reserve_name;
            state.reserve_phone = action.payload.reserve_phone;
            state.reserve_user_count = action.payload.reserve_user_count;
            state.reserve_status_cd = action.payload.reserve_status_cd;
            state.reserve_status_name = action.payload.reserve_status_name;
            state.boarding_writer_count = action.payload.boarding_writer_count;
            state.boarding_user_count = action.payload.boarding_user_count;
            state.emergency_contact = action.payload.emergency_contact;
            state.memo = action.payload.memo;

            return state;
        },
        clearReserve: (state) => {
            state.reserve_no = '';
            state.reserve_id = '';
            state.sailing_no = '';
            state.yacht_no = '';
            state.cruise_name = '';
            state.mate_no = '';
            state.mate_name = '';
            state.created_at = '';
            state.uuid = '';
            state.reserve_name = '';
            state.reserve_phone = '';
            state.reserve_user_count = '';
            state.reserve_status_cd = '';
            state.reserve_status_name = '';
            state.boarding_writer_count = '';
            state.boarding_user_count = '';
            state.emergency_contact = '';
            state.memo = '';

            return state;
        },
    },
});

export const { setReserve, clearReserve } = reserveSlice.actions;
export default reserveSlice.reducer;
