import { createSlice } from "@reduxjs/toolkit";

export const smsItemSlice = createSlice({
    name: "smsItem",
    initialState: {
        cruise_name: '',
        reserve_date: '',
        location_name: '',
        location_link: '',
        qr_link: '',
        uuid: '',
    },
    reducers: {
        setSmsItem: (state, action) => {
            state.cruise_name = action.payload.cruise_name;
            state.reserve_date = action.payload.reserve_date;
            state.location_name = action.payload.location_name;
            state.location_link = action.payload.location_link;
            state.qr_link = action.payload.qr_link;
            state.uuid = action.payload.uuid;

            return state;
        },
        clearSmsItem: (state) => {
            state.cruise_name = '';
            state.reserve_date = '';
            state.location_name = '';
            state.location_link = '';
            state.qr_link = '';
            state.uuid = '';

            return state;
        },
    },
});

export const { setSmsItem, clearSmsItem } = smsItemSlice.actions;
export default smsItemSlice.reducer;
