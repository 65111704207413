import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {
    ChatBubbleBottomCenterIcon,
    PencilSquareIcon,
    XMarkIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/24/outline"

import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import ReserveForm from "components/ReserveForm";
import SmsForm from "components/SmsForm";
import ModalBox from "components/ModalBox";
import {SailingListSelect} from "components/SailList";
import {clearSail, setSail} from "store/sailSlice";
import {clearReserve, setReserve} from "store/reserveSlice";
import {toast} from "store/toastSlice";
import dayjs from "dayjs";
import {TbShip} from "react-icons/tb";
import { LiaUserSolid } from "react-icons/lia";

import {GrUserPolice} from "react-icons/gr";
import {LiaClock, LiaComment} from "react-icons/lia";

export default function Reserve() {
    const user = Auth.AuthCheck();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const location = useLocation();

    const [drawerUser, setDrawerUser] = useState(false);
    const [drawerReserve, setDrawerReserve] = useState(false);
    const [drawerSms, setDrawerSms] = useState(false);
    const [smsCode, setSmsCode] = useState(null);
    const [disableMenu, setDisableMenu] = useState(false);

    // const [sailInfo, setSailInfo] = useState(G.SailInfo);
    const sailInfo = useSelector(state => state.Sail);
    const reserveInfo = useSelector(state => state.Reserve);
    const yachtInfo = useSelector(state => state.Yacht)
    const [yachtList, setYachtList] = useState([]);

    const [reserveNo, setReserveNo] = useState();
    const [reserveList, setReserveList] = useState([]);
    const ApiListReserve = (body) => {
        const url = "boarding/boarding_reserve_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                // const dataCount = response.data.info.count;
                const dataList = response.data.list;
                setReserveList(dataList);
            }
        );
    };
    const [reserveUserInfo, setReserveUserInfo] = useState();
    const [reserveUserList, setReserveUserList] = useState([]);
    const [smsUserList, setSmsUserList] = useState([]);
    const ApiListReserveUserSmsUser = (body) => {
        const url = "boarding/boarding_reserve_user_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                const dataList = response.data.list;
                if(dataList.length <= 0) {
                    dispatch(toast({message : "송신 대상자가 없습니다.", kind : "error"}));
                    return;
                }
                setSmsUserList(dataList);
                setDrawerSms(true);
            }
        );
    };
    const ApiListOnBoardUserSmsUser = (body) => {
        const url = "boarding/boarding_pass_user_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                if(response.data.user_list) {
                    const dataList = response.data.user_list.filter(user => user.gubun === 'user');
                    if (dataList.length <= 0) {
                        dispatch(toast({message: "송신 대상자가 없습니다.", kind: "error"}));
                        return;
                    }
                    setSmsUserList(dataList);
                    setDrawerSms(true);
                }
            }
        );
    };
    const ApiListReserveUser = (body) => {
        const url = "boarding/boarding_reserve_user_list";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                // const dataCount = response.data.info.count;
                const dataList = response.data.list;
                setReserveUserList(dataList);
            }
        );
    };
    const ApiViewReserve = (body) => {
        const url = "boarding/boarding_reserve_info";
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                // const dataCount = response.data.info.count;
                const datainfo = response.data.info;

                dispatch(setReserve(datainfo));
                //setReserveInfo(datainfo);
                ApiListReserveUser(body);
            }
        );
    };
    const getSailStatus = (sailInfo) => {
        return G.getSailStatusStyleText(sailInfo);
    }
    const checkSailDateStat = (sail_info) => {
        if(G.getSailStatusText(sail_info) === '출항전')
            setDisableMenu(false);
        else
            setDisableMenu(true);
    }

    const handleSetReserve = (no) => {
        setReserveNo(no);
        ApiViewReserve({sailing_no: sailInfo.sailing_no, reserve_no: no});
    }
    const ApiDeleteAll = () => {
        const url = "boarding/boarding_reserve_user_all_delete";
        let body = {sailing_no: sailInfo.sailing_no, reserve_no: reserveNo}
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                // console.log('response',response);
                refreshData('r','삭제되었습니다.');
            }
        );
    }
    const ApiDeleteUser = () => {
        const url = "boarding/boarding_reserve_user_delete";
        let body = {sailing_no: sailInfo.sailing_no, reserve_no: reserveNo, boarding_no: reserveUserInfo.boarding_no}
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                // console.log('response',response);
                refreshData('r','삭제되었습니다.');
            }
        );
    }
    const ApiReserveDelete = () => {
        const url = "boarding/boarding_reserve_delete";
        let body = {sailing_no: sailInfo.sailing_no, reserve_no: reserveNo}
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                // console.log('response',response);
                refreshData('c','취소되었습니다.');
            }
        );
        // ApiViewReserve({sailing_no: sailInfo.sailing_no, reserve_no: reserveNo});
    }

    const getReserveListMobile = (reserveInfo1, reserveUserList1) => {
        return (
            <>
                {
                    reserveInfo.reserve_name !== '' ?
                        <>
                            <div className="mb-4">
                                <div className="form-row rounded-xl border-2 bg-base-200 py-2 px-4 w-full md:gap-1">
                                    <div className="md:basis-7/12">
                                        <div className="inline-block py-2 px-2 md:p-0 text-sm leading-7">
                                            <div className="block md:inline-block">
                                                <span className="font-bold">예약자</span>
                                                <span className="mx-1">:</span>
                                                {reserveInfo.reserve_name}
                                            </div>
                                            <div className="block md:inline-block md:ml-6">
                                                <span className="font-bold">연락처</span>
                                                <span className="mx-1">:</span>
                                                {G.Hypen(reserveInfo.reserve_phone)}
                                            </div>
                                            <div className="block md:block">
                                                <span className="font-bold">작성/예약인원</span>
                                                <span className="mx-1">:</span>
                                                {reserveInfo.boarding_user_count}
                                                {'/'}
                                                {reserveInfo.reserve_user_count}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:basis-5/10">
                                        <div className="grid grid-cols-2 gap-2 py-2 md:flex md:flex-nowrap md:gap-1 md:items-end">
                                            <button type="button" disabled={disableMenu}
                                                    className="btn btn-secondary btn-sm "
                                                    onClick={() => handleReserve()}>
                                                정보수정
                                            </button>
                                            <button type="button" disabled={disableMenu || reserveUserList.length < 1}
                                                    className="btn btn-warning btn-sm"
                                                    onClick={() => handleDeleteAll()}>
                                                모두삭제
                                            </button>
                                            <button type="button" disabled={disableMenu}
                                                    className="btn btn-error btn-sm"
                                                    onClick={() => handleReserveCancel()}>
                                                예약취소
                                            </button>
                                            <button type="button" disabled={disableMenu}
                                                    className="btn btn-info btn-sm"
                                                    onClick={handleChangeSailModalOpen}>
                                                회차변경
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-4">
                                <div className="table-wrapper">
                                    <div className="div-table w-full">
                                        <div className="div-table-row div-table-header">
                                            <div className="div-table-cell">QR-NO</div>
                                            <div className="div-table-cell">성명</div>
                                            <div className="div-table-cell">생년월일</div>
                                            <div className="div-table-cell">성별</div>
                                            <div className="div-table-cell">휴대폰번호</div>
                                            <div className="div-table-cell">등록일시</div>
                                            <div className="div-table-cell">탑승여부</div>
                                            <div className="div-table-cell">기능</div>
                                        </div>
                                        {reserveUserList.map(({qr_id,boarding_name,boarding_birth,boarding_gender,boarding_status,
                                                                  boarding_phone,created_at,port_entry_at,boarding_no}, k) =>
                                            <div key={k} className="div-table-row-white">
                                                <div className="div-table-cell cell-mobile-hide">{qr_id}</div>
                                                <div className="div-table-cell">
                                                    <div className="flex my-2 rounded-box px-3 py-2 text-sm border border-slate-200 border-solid md:block whitespace-nowrap">
                                                        <div className="flex-1 md:block">
                                                            <LiaUserSolid className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                                            {boarding_name}
                                                            <div className="mobile-show">
                                                                {boarding_status === '탑승' ?
                                                                    <div className="badge badge-accent badge-sm ml-2 whitespace-nowrap">
                                                                        탑승
                                                                    </div>
                                                                    :
                                                                    <div className="badge badge-warning badge-sm ml-2 whitespace-nowrap">
                                                                        미탑승
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="mobile-show text-right">
                                                            <button type="button" className="btn btn-neutral btn-xs ml-auto mobile-show"
                                                                    onClick={() => handleSendSmsIndividual(k)}>
                                                                문자보내기
                                                            </button>
                                                            <button type="button" className="btn btn-error btn-xs ml-2 mobile-show"
                                                                    onClick={() => handleSendSmsIndividual(k)}>
                                                                삭제
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="div-table-cell cell-mobile-hide">{boarding_birth}</div>
                                                <div className="div-table-cell cell-mobile-hide">{G.GenderText(boarding_gender)}</div>
                                                <div className="div-table-cell cell-mobile-hide whitespace-nowrap">{G.Hypen(boarding_phone)}</div>
                                                <div className="div-table-cell cell-mobile-hide">{created_at}</div>
                                                <div className="div-table-cell cell-mobile-hide">
                                                    {boarding_status === '탑승' ?
                                                        <div className="badge badge-accent badge-sm whitespace-nowrap">
                                                            탑승
                                                        </div>
                                                        :
                                                        <div className="badge badge-warning badge-sm whitespace-nowrap">
                                                            미탑승
                                                        </div>
                                                    }
                                                </div>
                                                <div  className="div-table-cell cell-mobile-hide whitespace-nowrap">
                                                    <div className="tooltip" data-tip="메시지보내기">
                                                        <button type="button" className="btn btn-circle btn-xs btn-ghost p-0"
                                                                onClick={() => handleSendSmsIndividual(k)}>
                                                            <ChatBubbleBottomCenterIcon className="inline-block w-4 h-4" />
                                                        </button>
                                                    </div>
                                                    <div className="tooltip" data-tip="삭제">
                                                        <button type="button" className="btn btn-circle btn-xs btn-ghost p-0 "
                                                                onClick={() => handleDeleteUser(k)}>
                                                            <XMarkIcon className="inline-block w-4 h-4" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {reserveUserList.length < 1 ?
                                        <div className="div-no-record w-full">
                                            작성된 정보가 없습니다.
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className="my-2 help-text">
                                    <p className="mb-1 text-purple-700 text-xs italic text-point">
                                        운항이 완료된 회차는 정보 수정이 불가합니다.
                                    </p>
                                    <p className="text-purple-700 text-xs italic text-point">
                                        승선신고서는 승선신고서 메뉴를 이용해주세요.
                                    </p>
                                </div>
                                <div className="mt-4 md:my-2 text-center">
                                    {reserveUserList.length < 1 ?
                                        <button type="button" className="btn btn-neutral btn-sm btn-disabled"
                                        >
                                            {reserveInfo.reserve_name} 예약 승선자 대상 메세지 보내기
                                        </button>
                                        :
                                        <button type="button" className="btn btn-neutral btn-sm"
                                                onClick={() => handleSendSmsBoarding()}>
                                            {reserveInfo.reserve_name} 예약 승선자 대상 메세지 보내기
                                        </button>
                                    }
                                </div>
                            </div>
                        </>
                        :
                        <div className="mobile-hide-block">
                            <div className="paper my-no">
                                <div className="text-center md:py-24">
                                        <span className="align-middle">
                                            선택된 예약항목이 없습니다.
                                        </span>
                                </div>
                            </div>
                        </div>
                }
            </>
        );
    }
    const getReserveListPC = (reserveInfo1, reserveUserList1) => {
        return (
            <>
                {
                    reserveInfo.reserve_name !== '' ?
                        <>
                            <div className="paper my-no">
                                <div className="form-row rounded-xl border-2 bg-base-200 py-2 px-4 w-full md:gap-1">
                                    <div className="md:basis-7/12">
                                        <div className="inline-block py-2 px-2 md:p-0 text-sm leading-7">
                                            <div className="block md:inline-block">
                                                <span className="font-bold">예약자</span>
                                                <span className="mx-1">:</span>
                                                {reserveInfo.reserve_name}
                                            </div>
                                            <div className="block md:inline-block md:ml-6">
                                                <span className="font-bold">연락처</span>
                                                <span className="mx-1">:</span>
                                                {G.Hypen(reserveInfo.reserve_phone)}
                                            </div>
                                            <div className="block md:block">
                                                <span className="font-bold">작성/예약인원</span>
                                                <span className="mx-1">:</span>
                                                {reserveInfo.boarding_user_count}
                                                {'/'}
                                                {reserveInfo.reserve_user_count}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:basis-5/10">
                                        <div className="grid grid-cols-2 gap-2 py-2 md:flex md:flex-nowrap md:gap-1 md:items-end">
                                            <button type="button"
                                                    className="btn btn-info btn-sm"
                                                    onClick={handleChangeRR}>
                                                승선처리
                                            </button>
                                            <button type="button" disabled={disableMenu}
                                                    className="btn btn-secondary btn-sm "
                                                    onClick={() => handleReserve()}>
                                                정보수정
                                            </button>
                                            <button type="button" disabled={disableMenu || reserveUserList.length < 1}
                                                    className="btn btn-warning btn-sm"
                                                    onClick={() => handleDeleteAll()}>
                                                모두삭제
                                            </button>
                                            <button type="button" disabled={disableMenu}
                                                    className="btn btn-error btn-sm"
                                                    onClick={() => handleReserveCancel()}>
                                                예약취소
                                            </button>
                                            {/*<button type="button" disabled={disableMenu}*/}
                                            {/*        className="btn btn-info btn-sm"*/}
                                            {/*        onClick={handleChangeSailModalOpen}>*/}
                                            {/*    회차변경*/}
                                            {/*</button>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="paper">
                                <div className="table-wrapper">
                                    <div className="div-table w-full">
                                        <div className="div-table-row div-table-header">
                                            <div className="div-table-cell">QR-NO</div>
                                            <div className="div-table-cell">성명</div>
                                            <div className="div-table-cell">생년월일</div>
                                            <div className="div-table-cell">성별</div>
                                            <div className="div-table-cell">휴대폰번호</div>
                                            <div className="div-table-cell">등록일시</div>
                                            <div className="div-table-cell">탑승여부</div>
                                            <div className="div-table-cell">기능</div>
                                        </div>
                                        {reserveUserList.map(({qr_id,boarding_name,boarding_birth,boarding_gender,boarding_status,
                                                                  boarding_phone,created_at,port_entry_at,boarding_no}, k) =>
                                            <div key={k} className="div-table-row">
                                                <div className="div-table-cell cell-mobile-hide">{qr_id}</div>
                                                <div className="div-table-cell">
                                                    <div className="flex md:block whitespace-nowrap">
                                                        <div className="flex-1 md:block">
                                                            <LiaUserSolid className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                                            {boarding_name}
                                                            <div className="mobile-show">
                                                                {boarding_status === '탑승' ?
                                                                    <div className="badge badge-accent badge-sm ml-2 whitespace-nowrap">
                                                                        탑승
                                                                    </div>
                                                                    :
                                                                    <div className="badge badge-warning badge-sm ml-2 whitespace-nowrap">
                                                                        미탑승
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="mobile-show text-right">
                                                            <button type="button" className="btn btn-neutral btn-xs ml-auto mobile-show"
                                                                    onClick={() => handleSendSmsIndividual(k)}>
                                                                문자보내기
                                                            </button>
                                                            <button type="button" className="btn btn-error btn-xs ml-2 mobile-show"
                                                                    onClick={() => handleSendSmsIndividual(k)}>
                                                                삭제
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="div-table-cell cell-mobile-hide">{boarding_birth}</div>
                                                <div className="div-table-cell cell-mobile-hide">{G.GenderText(boarding_gender)}</div>
                                                <div className="div-table-cell cell-mobile-hide whitespace-nowrap">{G.Hypen(boarding_phone)}</div>
                                                <div className="div-table-cell cell-mobile-hide">{created_at}</div>
                                                <div className="div-table-cell cell-mobile-hide">
                                                    {boarding_status === '탑승' ?
                                                        <div className="badge badge-accent badge-sm whitespace-nowrap">
                                                            탑승
                                                        </div>
                                                        :
                                                        <div className="badge badge-warning badge-sm whitespace-nowrap">
                                                            미탑승
                                                        </div>
                                                    }
                                                </div>
                                                <div  className="div-table-cell cell-mobile-hide whitespace-nowrap">
                                                    <div className="tooltip" data-tip="메시지보내기">
                                                        <button type="button" className="btn btn-circle btn-xs btn-ghost p-0"
                                                                onClick={() => handleSendSmsIndividual(k)}>
                                                            <ChatBubbleBottomCenterIcon className="inline-block w-4 h-4" />
                                                        </button>
                                                    </div>
                                                    {/*<div className="tooltip" data-tip="수정">*/}
                                                    {/*<button type="button" className="btn btn-circle btn-xs btn-ghost p-0 "*/}
                                                    {/*        onClick={() => handleUserEdit(k)}>*/}
                                                    {/*    <PencilSquareIcon className="inline-block w-4 h-4" />*/}
                                                    {/*</button>*/}
                                                    {/*</div>*/}
                                                    <div className="tooltip" data-tip="삭제">
                                                        <button type="button" className="btn btn-circle btn-xs btn-ghost p-0 "
                                                                onClick={() => handleDeleteUser(k)}>
                                                            <XMarkIcon className="inline-block w-4 h-4" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {reserveUserList.length < 1 ?
                                        <div className="div-no-record w-full">
                                            작성된 정보가 없습니다.
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className="my-2 help-text">
                                    <p className="mb-1 text-purple-700 text-xs italic text-point">
                                        운항이 완료된 회차는 정보 수정이 불가합니다.
                                    </p>
                                    <p className="text-purple-700 text-xs italic text-point">
                                        승선신고서는 승선신고서 메뉴를 이용해주세요.
                                    </p>
                                </div>
                                <div className="mt-4 md:my-2 text-center">
                                    {reserveUserList.length < 1 ?
                                        <button type="button" className="btn btn-primary btn-sm btn-disabled"
                                        >
                                            {reserveInfo.reserve_name} 예약 승선자 대상 메세지 보내기
                                        </button>
                                        :
                                        <button type="button" className="btn btn-primary btn-sm"
                                                onClick={() => handleSendSmsBoarding()}>
                                            {reserveInfo.reserve_name} 예약 승선자 대상 메세지 보내기
                                        </button>
                                    }
                                </div>
                            </div>
                        </>
                        :
                        <div className="mobile-hide-block">
                            <div className="paper my-no">
                                <div className="text-center md:py-24">
                                        <span className="align-middle">
                                            선택된 예약항목이 없습니다.
                                        </span>
                                </div>
                            </div>
                        </div>
                }
            </>
        );
    }

    const refreshData = (mode, msg) => {
        dispatch(toast({message : msg, kind : "success"}));
        ApiListReserve({sailing_no: sailInfo.sailing_no});
        if(mode === 'c')
            dispatch(clearReserve(reserveInfo));
        else
            ApiViewReserve({sailing_no: sailInfo.sailing_no, reserve_no: reserveNo});
    }

    const handleUserFinish = () => {
        dispatch(toast({message : "저장되었습니다", kind : "success"}));
        handleUserClose();
    }
    const handleReserveFinish = () => {
        refreshData('r','저장되었습니다.');
        handleReserveClose();
    }
    const handleSmsFinish = () => {
        setSmsUserList([]);
        handleSmsClose();
    }
    const handleUserClose = () => {
        setDrawerUser(false);
    }
    const handleReserveClose = () => {
        setDrawerReserve(false);
    }
    const handleSmsClose = () => {
        setDrawerSms(false);
    }
    const handleChangeUser = (e) => {
        console.log('e.target.checked',e.target.checked);
    }
    const handleChangeReserve = (e) => {
        console.log('e.target.checked',e.target.checked);
    }
    const handleChangeSms = (e) => {
        console.log('e.target.checked',e.target.checked);
    }
    ///// SMS
    const handleSendSmsSailOnBoard = () => {
        setSmsCode(G.SAIL_GROUP);
        ApiListOnBoardUserSmsUser({sailing_no: sailInfo.sailing_no, reserve_no: 0});
    }
    const handleSendSmsSailAll = () => {
        setSmsCode(G.SAIL_GROUP);
        ApiListReserveUserSmsUser({sailing_no: sailInfo.sailing_no, reserve_no: 0});
    }
    const handleSendSmsBoarding = () => {
        setSmsCode(G.SAIL_GROUP);
        ApiListReserveUserSmsUser({sailing_no: sailInfo.sailing_no, reserve_no: reserveInfo.reserve_no});
    }
    const handleSendSmsKeyManAll = () => {
        setSmsCode(G.SAIL_GROUP);
        const targetArray = reserveList.map(function({reserve_name,reserve_phone,uuid,qr_id}) {
            return {boarding_name: reserve_name, boarding_phone: reserve_phone, uuid: uuid, qr_id: qr_id};
        });
        setSmsUserList(targetArray);
        setDrawerSms(true);
    }
    const handleSendSmsIndividualKeyMan = (no) => {
        setSmsCode(G.RESERVE_INDIVIDUAL);
        const selected =  reserveList[no];
        setSmsUserList([{boarding_name: selected.reserve_name, boarding_phone: selected.reserve_phone, uuid: selected.uuid, qr_id: selected.qr_id}]);
        setDrawerSms(true);
    }
    const handleSendSmsIndividual = (no) => {
        setSmsCode(G.RESERVE_INDIVIDUAL);
        const selected =  getUserInfo(no);
        setReserveUserInfo(selected);
        setSmsUserList([selected]);
        setDrawerSms(true);
    }
    ///// SMS
    const handleUserEdit = (no) => {
        const selected =  getUserInfo(no);
        setReserveUserInfo(selected);
    }
    const handleDeleteUser = (no) => {
        const selected =  getUserInfo(no);
        setReserveUserInfo(selected);
        window.user_delete.showModal()
    }
    const getUserInfo = (no) => {
        const selected = reserveUserList[no];
        return selected;
    }
    const handleReserve = () => {
        setDrawerReserve(true);
    }
    const handleDeleteAll = () => {
        window.user_delete_all.showModal()
    }
    const handleReserveCancel = () => {
        window.reserve_cancel.showModal()
    }

    const handleChangeRR = () => {
        const url = "boarding/boarding_reserve_user_all_status";
        let body = {sailing_no: sailInfo.sailing_no, reserve_no: reserveNo}
        Api(body, url, user.token).then(
          (response) => {
              const result = response.result || response;
              if (result.code !== 0 ) {
                  if(result.code == 120)
                      navigate("/user/login");
                  alert(result.message);
                  return;
              }
              // console.log('response',response);
              refreshData('r','승선처리되었습니다.');
          }
        );
    }

    const handleChangeALL = () => {
        const url = "boarding/boarding_reserve_user_all_group_status";
        let body = {sailing_no: sailInfo.sailing_no }
        Api(body, url, user.token).then(
          (response) => {
              const result = response.result || response;
              if (result.code !== 0 ) {
                  if(result.code == 120)
                      navigate("/user/login");
                  alert(result.message);
                  return;
              }
              // console.log('response',response);
              refreshData('r','승선처리되었습니다.');
          }
        );
    }

    const [modalOpenSailing, setModalOpenSailing] = useState(false);
    const handleChangeModal = () => {
    }
    const handleChangeSailModalOpen = () => {
        setModalOpenSailing(true);
    }
    const handleModalCloseSailing = () => {
        setModalOpenSailing(false);
    }
    const [changedSailingNo, setChangedSailingNo] = useState();
    const handleChangeConfirmSailing = () => {
        const url = "boarding/boarding_reserve_sailing_update";
        let body = {sailing_no: sailInfo.sailing_no, update_sailing_no: changedSailingNo, reserve_no: reserveNo}
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    if(result.code == 120)
                        navigate("/user/login");
                    alert(result.message);
                    return;
                }
                // console.log('response',response);
                refreshData('c','변경되었습니다.');
            }
        );
        handleModalCloseSailing();
    }

    useEffect(() => {
        dispatch(clearReserve(reserveInfo));
        if(sailInfo.sailing_no) {
            ApiListReserve({sailing_no: sailInfo.sailing_no});
        }
        checkSailDateStat(sailInfo);
        setYachtList(yachtInfo.yacht_list);
    }, [sailInfo]);

    return (
        <>
            <main className="content">
                <div className="paper">
                    <div className="sub-title">
                        <span className="text-gradient">예약 & 승선자 정보 확인</span>
                    </div>
                </div>
                <div className="paper">
                    <div className="form-row">
                        <div className="md:basis-3/5">
                            <span className="font-bold mr-6">항해회차</span>
                            <div className="mobile-row bg-gray-100 md:bg-white md:inline-block md:border-2 shadow-md py-2 px-4 rounded-md text-sm">
                                <TbShip className="inline-block h-5 w-5 mobile-show" strokeWidth="1" />
                                {G.getStyleYacht(sailInfo.yacht_no,sailInfo.cruise_name,yachtList)}
                                <div className="divider md:divider-horizontal md:inline-flex align-middle h-0 md:h-5 my-2 md:mx-1"></div>
                                <GrUserPolice className="inline-block h-4 w-4 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                {sailInfo.mate_name} <span className="mobile-show ml-2">항해사</span>
                                <div className="divider md:divider-horizontal md:inline-flex align-middle h-0 md:h-5 my-2 md:mx-1"></div>
                                <LiaClock className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                {sailInfo.start_at}
                                <div className="divider md:divider-horizontal md:inline-flex align-middle h-0 md:h-5 my-2 md:mx-1"></div>
                                <LiaComment className="inline-block h-5 w-5 mr-1.5 mobile-show" strokeWidth="0.01" color="#202938" />
                                {getSailStatus(sailInfo)}
                            </div>
                        </div>
                        <div className="w-1/2 inline-block md:basis-1/5 text-right pr-1 md:pr-0">
                            <button type="button" className="btn btn-secondary btn-sm w-full whitespace-nowrap"
                                    onClick={() => handleSendSmsSailOnBoard()}>
                                탑승자대상메세지보내기
                            </button>
                        </div>
                        <div className="w-1/2 inline-block md:basis-1/5 text-right pl-1 md:pl-0">
                            <button type="button" className="btn btn-primary btn-sm w-full"
                                    onClick={() => handleSendSmsSailAll()}>
                                단체메세지보내기
                            </button>
                        </div>
                    </div>
                </div>
                <div className="md:flex">
                    <div className="md:basis-1/4">
                        <div className="paper my-no">
                            <span className="mobile-show font-bold">예약자 리스트</span>
                            {reserveList.map(({no,reserve_no,reserve_name,boarding_writer_count,boarding_user_count,reserve_user_count,reserve_phone,reserve_status_name}, k) =>
                                <div key={k}>
                                    <div
                                        className={`flex w-full items-center my-2 md:my-0 py-2 pr-4 md:p-0 md:pb-4 rounded-lg border md:border-0 border-gray-200 ${(reserve_no === reserveNo) && ' bg-slate-100'}`}
                                    >
                                        <button className={ reserve_no === reserveNo ? 'btn btn-ghost md:btn-primary mr-2' : 'btn btn-ghost md:btn mr-2' }
                                            onClick={() => handleSetReserve(reserve_no)}>{no}
                                        </button>
                                        <div className={ reserve_no === reserveNo ? 'text-primary cursor-pointer grow whitespace-nowrap' : 'cursor-pointer grow whitespace-nowrap' }
                                             onClick={() => handleSetReserve(reserve_no)}>

                                            {reserve_name} ({boarding_user_count}{'/'}{reserve_user_count})

                                            {
                                            reserve_status_name === '완료' ?
                                                <div className="text-xs ml-2 badge badge-info">완료</div>
                                            :
                                                <div className="text-xs ml-2 badge badge-warning">부족</div>
                                            }

                                            <div className="block md:block ml-2 md:ml-0 whitespace-nowrap text-sm text-gray-400">
                                                {G.Hypen(reserve_phone)}
                                                {(reserve_no === reserveNo) &&
                                                    <ChatBubbleBottomCenterIcon className="mobile-hide inline-block w-4 h-4 ml-2 cursor-pointer"
                                                                                onClick={() => handleSendSmsIndividualKeyMan(k)}/>
                                                }
                                            </div>
                                        </div>
                                        {(reserve_no === reserveNo) &&
                                        <div className="mobile-show text-right">
                                            <button type="button" className="btn btn-neutral btn-xs w-full mx-auto mobile-show"
                                                    onClick={() => handleSendSmsIndividualKeyMan(k)}>
                                                문자보내기
                                            </button>
                                        </div>
                                        }
                                    </div>
                                    <div
                                         className={`block md:hidden mobile-accordian ${(reserve_no === reserveNo) && ' open'}`}>
                                        { (reserve_no === reserveNo) && getReserveListMobile() }
                                    </div>
                                </div>
                            )}
                            {reserveList.length < 1 ?
                                <div className="div-no-record w-full mt-4">
                                    예약 리스트가 없습니다.
                                </div>
                                : null
                            }
                            <button type="button" className="btn btn-primary btn-sm w-full mt-4"
                                    onClick={() => handleSendSmsKeyManAll()}>
                                예약자 대상 메세지보내기
                            </button>

                            <button type="button" className="btn btn-secondary btn-sm w-full mt-4"
                                    onClick={() => handleChangeALL()}>
                                등록자 전체 탑승 처리
                            </button>

                        </div>
                    </div>
                    <div className="mobile-hide md:basis-3/4 md:ml-4 my-4 md:my-0">
                        { getReserveListPC() }
                    </div>
                </div>
                <div className="drawer drawer-end">
                    <input id="drawer-user" type="checkbox" onChange={handleChangeUser} checked={drawerUser} className="drawer-toggle" />
                    <div className="drawer-side z-20">
                        <label onClick={handleUserClose} className="drawer-overlay"></label>
                        <div className="w-full md:w-1/3 min-h-full py-0 px-1 rounded-lg shadow-md bg-base-100">
                            <div className="paper-modal overscroll-y-auto">
                                {/*<ReserveForm mode={'modify'} show={drawerUser}*/}
                                {/*             SMSCODE={G.RESERVE_UPDATE} onFinish={handleUserFinish} />*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="drawer drawer-end">
                    <input id="drawer-reserve" type="checkbox" onChange={handleChangeReserve} checked={drawerReserve} className="drawer-toggle" />
                    <div className="drawer-side z-20">
                        <label onClick={handleReserveClose} className="drawer-overlay"></label>
                        <div className="w-full md:w-1/3 min-h-full py-0 px-1 rounded-lg shadow-md bg-base-100">
                            <div className="paper-modal overscroll-y-auto">
                                <ReserveForm sail={sailInfo} show={drawerReserve}
                                             SMSCODE={G.RESERVE_UPDATE} onFinish={handleReserveFinish} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="drawer drawer-end">
                    <input id="drawer-sms" type="checkbox" onChange={handleChangeSms} checked={drawerSms} className="drawer-toggle" />
                    <div className="drawer-side z-20">
                        <label onClick={handleSmsClose} className="drawer-overlay"></label>
                        <div className="w-full md:w-1/3 min-h-full py-0 px-1 rounded-lg shadow-md bg-base-100">
                            <div className="paper-modal overscroll-y-auto">
                                <SmsForm users={smsUserList} show={drawerSms}
                                         SMSCODE={smsCode} onFinish={handleSmsFinish} />
                            </div>
                        </div>
                    </div>
                </div>

                <dialog id="reserve_cancel" className="modal">
                    <ModalBox title="예약을 취소하시겠습니까?"
                              action="삭제"
                              callBack={ApiReserveDelete}>
                        예약 취소 시 복구가 불가능합니다.(예약자에게 문자 발송)<br />
                        그래도 취소하시려면 예약 취소 버튼을 클릭해주세요.
                    </ModalBox>
                </dialog>
                <dialog id="user_delete_all" className="modal">
                    <ModalBox title="모든 승선자를 삭제하시겠습니까?"
                              action="삭제"
                              callBack={ApiDeleteAll}>
                        승선자 삭제 시 복구가 불가능합니다.<br />
                        그래도 삭제하시려면 삭제 버튼을 클릭해주세요.
                    </ModalBox>
                </dialog>
                <dialog id="user_delete" className="modal">
                    <ModalBox title="사용자를 삭제하시겠습니까?"
                              action="삭제"
                              callBack={ApiDeleteUser}>
                        {(reserveUserInfo) ? reserveUserInfo.boarding_name : ''}님을 삭제하시겠습니까?<br />
                        승선자 삭제 시 복구가 불가능합니다.<br />
                        그래도 삭제하시려면 삭제 버튼을 클릭해주세요.
                    </ModalBox>
                </dialog>
                <input type="checkbox" id="change_reserve_sail" onChange={handleChangeModal} checked={modalOpenSailing} className="modal-toggle" />
                <div className="modal">
                    <div className="modal-box w-11/12 max-w-5xl">
                        <button type="button" onClick={handleModalCloseSailing} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                        <h3 className="font-bold text-xl text-center">
                            <ExclamationCircleIcon className="text-warning inline-block w-7 h-7 mr-2" />
                            항해회차 선택
                        </h3>
                        <div className="py-4 min-h-[250px]">
                            <SailingListSelect sail_no={sailInfo.sailing_no} setChangedSailingNo={setChangedSailingNo}  />
                        </div>
                        <div className="flex justify-center items-center mt-1.5">
                            <button type="button" className="btn btn-sm btn-primary w-1/5" onClick={handleChangeConfirmSailing}>선택</button>
                            <button type="button" onClick={handleModalCloseSailing} className="btn btn-sm w-1/5 ml-2">취소</button>
                        </div>
                    </div>
                    <label className="modal-backdrop" onClick={handleModalCloseSailing}>Close</label>
                </div>

            </main>
        </>
    );
}
