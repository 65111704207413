import { Navigate } from "react-router-dom";
import {useSelector} from "react-redux";

export const RouteAuth = ({ children }) => {
    const user = useSelector((state) => state.user);
    if(user !== null && user !== 'undefined' && user !== ''){
        if(user.isLogin === true){
            return children;
        }
    }
    return <Navigate to="/user/login" />;
};