import React, {useEffect} from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux'
import {useSelector} from "react-redux";
import {clearToast} from "store/toastSlice";

export default function ToastMessage() {
    const dispatch = useDispatch();
    const Toast = useSelector((state) => state.Toast);
    const {message, kind} = Toast;

    useEffect(() => {
        if(message) {
            switch (kind) {
                case "success" :
                    toast.success(message);
                    break;
                case "error" :
                    toast.error(message);
                    break;
                case "custom" :
                    toast.custom(message);
                    break;
                case "loading" :
                    toast.loading(message);
                    break;
                default :
                    toast(message);
            }
            setTimeout(()=>dispatch(clearToast(Toast)),1000);
        }
    }, [Toast]);

    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{zIndex: '99'}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    // Default options for specific types
                    success: {
                        duration: 3000,
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    },
                }}
            />
        </>
    )
}