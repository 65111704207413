import { createSlice } from "@reduxjs/toolkit";
// userMode //
export const initSlice = createSlice({
    name: "init",
    initialState: {
        userMode: "",
        boardID: "",
    },
    reducers: {
        setUserMode: (state, action) => {
            localStorage.setItem('userMode', action.payload.userMode);
            return {
                ...state,
                userMode: action.payload.userMode
            };
        },
        setJobID: (state, action) => {
            localStorage.setItem('jobID', action.payload.boardID);
            return {
                ...state,
                boardID: action.payload.boardID
            };
        },
        clearUserMode: (state) => {
            state.userMode = "";
            state.jobID = "";
            localStorage.removeItem('userMode');
            localStorage.removeItem('boardID');
            return state;
        },
    },
});

export const { setUserMode, setJobID, clearUserMode } = initSlice.actions;
export default initSlice.reducer;